<template>
  <markduck
    :class="block ? 'markdown-block' : 'markdown-inline'"
    :markdown="markdown"
  />
</template>

<script>
import markduck from "markduckjs";

export default {
  props: {
    markdown: String,
    block: Boolean,
  },

  components: {
    markduck: markduck({
      remarkPlugins: [],
      rehypePlugins: [],
      components: {},
    }),
  },
};
</script>

<style>
.markdown-inline {
  display: inline;
}

.markdown-block {
  display: block;
}
</style>
