<template>
  <div v-if="$root.user">
    <Loading v-if="loading" />
    <div v-else>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <b-input-group class="mb-3">
            <b-input-group-prepend is-text>
              <b-icon icon="search" />
            </b-input-group-prepend>
            <b-form-input
              v-model="search"
              :placeholder="$t('myServerPlayers.searchForPlayer')"
            ></b-form-input>
          </b-input-group>
        </div>
      </div>

      <b-table
        id="player-table"
        :fields="fields"
        :items="items"
        :per-page="perPage"
        :current-page="currentPage"
        :borderless="true"
        :striped="true"
        :small="true"
        :hover="true"
        :fixed="true"
        :busy="loading"
      >
      </b-table>
      <b-pagination
        v-if="rows > perPage"
        v-model="currentPage"
        pills
        align="center"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="player-table"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading";

export default {
  components: {
    Loading,
  },

  props: ["guild"],

  computed: {
    rows() {
      return this.items.length;
    },
  },

  data() {
    return {
      leaderboard: null,
      loading: true,
      perPage: 20,
      currentPage: 1,
      items: [],
      fields: [
        {
          key: "username",
          label: this.$t("playerTable.username"),
        },
      ],
    };
  },

  async created() {
    if (!this.$root.user) {
      this.$router.push({
        path: "/login",
        query: { returnPath: this.$route.path },
      });
    }

    if(!this.guild.canManage) {
      this.$router.push("/my-servers");
    }

    try {
      var response = await this.axios.get(
        `/api/guild/${this.guild.id}/players`
      );
      this.items = response.data;
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style scoped></style>
