<template>
  <div v-if="profile && profile.achievementProgress">
    <div class="row">
      <div class="col mb-2 text-right">
        <b-form-checkbox v-model="showUnachieved" switch size="lg">
          <small>{{ $t("achievements.showLocked") }}</small>
        </b-form-checkbox>
      </div>
    </div>
    <div
      class="row"
      v-if="
        profile.achievementProgress.filter(
          (ap) => ap.achievements.filter((a) => a.isAchieved).length > 0
        ).length === 0
      "
    >
      <div class="col">
        <b-alert class="ml-4" variant="info" show>
          <i class="fa fa-info-circle fa-lg mr-2"></i
          >{{ $t("achievements.noAchievementsGainedYet") }}
        </b-alert>
      </div>
    </div>
    <div v-for="ap in profile.achievementProgress" :key="ap.id">
      <div
        class="row"
        v-if="
          (ap.achievements &&
            ap.achievements.length > 0 &&
            ap.achievements.filter((a) => a.isAchieved).length > 0) ||
            showUnachieved
        "
      >
        <div class="col mb-3">
          <h5 class="mb-0">
            {{ ap.title
            }}<b-badge pill variant="dark" class="ml-2">{{ ap.value }}</b-badge>
          </h5>
          <small>{{ ap.description }}</small>
        </div>
      </div>
      <div v-for="(achievement, index) in ap.achievements" :key="index">
        <div
          v-if="achievement.isAchieved"
          style="border-bottom: 2px solid #ddd"
          class="row mb-3 pb-3"
        >
          <div class="col-md-3 text-center">
            <img
              class="m-2"
              style="width: 160px; height: auto;"
              :src="'/images/achievements/' + achievement.image"
            />
          </div>
          <div class="col-md-9" v-if="achievement.isAchieved">
            <h5>
              <i class="fa fa-trophy mr-2" style="color: orange"></i
              >{{ achievement.title }}

              <span class="float-right">
                <b-badge variant="primary">{{ achievement.points }}</b-badge>
              </span>
            </h5>
            {{ achievement.description }}
            <div class="mt-2">
              <small class="text-muted">{{
                achievement.achievedOn | moment("LL")
              }}</small>
            </div>
          </div>
        </div>
        <div
          v-else-if="showUnachieved"
          style="border-bottom: 2px solid #ddd"
          class="row mb-3 pb-3"
        >
          <div class="col-md-3 text-center">
            <h1>?</h1>
          </div>
          <div class="col-md-9">
            <b-progress
              show-value
              :value="achievement.value"
              :max="achievement.valueEnd - achievement.valueStart"
              variant="danger"
              class="mt-2"
            ></b-progress>
            <small class="float-right mt-1"
              ><strong
                >{{ achievement.valueEnd - ap.value }}
                {{ $t("achievements.leftToUnlock") }}</strong
              ></small
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  props: ["profile"],

  computed: {
    totalPoints: function() {
      var points = 0;
      this.profile.achievementProgress.forEach((ap) => {
        ap.achievements.forEach((a) => {
          points += a.points;
        });
      });
      return points;
    },
  },

  data() {
    return {
      showUnachieved: false,
    };
  },

  async mounted() {},
};
</script>
