<template>
  <div>
    Seasonal anime...
  </div>
</template>

<script>
export default {
};
</script>
