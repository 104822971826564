var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.profile && _vm.profile.achievementProgress)?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col mb-2 text-right"},[_c('b-form-checkbox',{attrs:{"switch":"","size":"lg"},model:{value:(_vm.showUnachieved),callback:function ($$v) {_vm.showUnachieved=$$v},expression:"showUnachieved"}},[_c('small',[_vm._v(_vm._s(_vm.$t("achievements.showLocked")))])])],1)]),(
      _vm.profile.achievementProgress.filter(
        (ap) => ap.achievements.filter((a) => a.isAchieved).length > 0
      ).length === 0
    )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('b-alert',{staticClass:"ml-4",attrs:{"variant":"info","show":""}},[_c('i',{staticClass:"fa fa-info-circle fa-lg mr-2"}),_vm._v(_vm._s(_vm.$t("achievements.noAchievementsGainedYet"))+" ")])],1)]):_vm._e(),_vm._l((_vm.profile.achievementProgress),function(ap){return _c('div',{key:ap.id},[(
        (ap.achievements &&
          ap.achievements.length > 0 &&
          ap.achievements.filter((a) => a.isAchieved).length > 0) ||
          _vm.showUnachieved
      )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col mb-3"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(ap.title)),_c('b-badge',{staticClass:"ml-2",attrs:{"pill":"","variant":"dark"}},[_vm._v(_vm._s(ap.value))])],1),_c('small',[_vm._v(_vm._s(ap.description))])])]):_vm._e(),_vm._l((ap.achievements),function(achievement,index){return _c('div',{key:index},[(achievement.isAchieved)?_c('div',{staticClass:"row mb-3 pb-3",staticStyle:{"border-bottom":"2px solid #ddd"}},[_c('div',{staticClass:"col-md-3 text-center"},[_c('img',{staticClass:"m-2",staticStyle:{"width":"160px","height":"auto"},attrs:{"src":'/images/achievements/' + achievement.image}})]),(achievement.isAchieved)?_c('div',{staticClass:"col-md-9"},[_c('h5',[_c('i',{staticClass:"fa fa-trophy mr-2",staticStyle:{"color":"orange"}}),_vm._v(_vm._s(achievement.title)+" "),_c('span',{staticClass:"float-right"},[_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v(_vm._s(achievement.points))])],1)]),_vm._v(" "+_vm._s(achievement.description)+" "),_c('div',{staticClass:"mt-2"},[_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm._f("moment")(achievement.achievedOn,"LL")))])])]):_vm._e()]):(_vm.showUnachieved)?_c('div',{staticClass:"row mb-3 pb-3",staticStyle:{"border-bottom":"2px solid #ddd"}},[_vm._m(0,true),_c('div',{staticClass:"col-md-9"},[_c('b-progress',{staticClass:"mt-2",attrs:{"show-value":"","value":achievement.value,"max":achievement.valueEnd - achievement.valueStart,"variant":"danger"}}),_c('small',{staticClass:"float-right mt-1"},[_c('strong',[_vm._v(_vm._s(achievement.valueEnd - ap.value)+" "+_vm._s(_vm.$t("achievements.leftToUnlock")))])])],1)]):_vm._e()])})],2)})],2):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-3 text-center"},[_c('h1',[_vm._v("?")])])
}]

export { render, staticRenderFns }