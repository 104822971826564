<template>
  <b-badge :class="'mr-1 bg-' + nsfw.toLowerCase()" :to="to">{{
    $t(`nsfw.${nsfw}`)
  }}</b-badge>
</template>

<script>
export default {
  props: ["nsfw", "to"],
  mounted() {},
};
</script>

<style scoped>
.bg-safe {
  background-color: #1f7fce;
}
.bg-questionable {
  background-color: #6025cf;
}
.bg-not_safe {
  background-color: #d42121;
}
</style>
