var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',[_c('h4',[_vm._v(_vm._s(_vm.$t("channelAccessControlConfig.title")))]),_c('Markdown',{attrs:{"markdown":_vm.$t('channelAccessControlConfig.description'),"block":true}}),_c('p',[_c('b-button',{attrs:{"variant":"success","size":"sm"},on:{"click":function($event){return _vm.add()}}},[_c('i',{staticClass:"fa fa-plus mr-2"}),_vm._v(_vm._s(_vm.$t("channelAccessControlConfig.addRule")))])],1),_c('b-alert',{attrs:{"show":_vm.items.length >= 10,"variant":"warning"}},[_c('i',{staticClass:"fa fa-exclamation-triangle mr-2"}),_vm._v(_vm._s(_vm.$t("channelAccessControlConfig.maximumReached")))]),_c('b-table',{attrs:{"striped":"","small":"","responsive":"","fields":_vm.fields,"items":_vm.items},scopedSlots:_vm._u([{key:"cell(allowed)",fn:function(data){return [_c('b-button-group',[(data.item.allow)?_c('b-button',{attrs:{"variant":"success","size":"sm"},on:{"click":function($event){return _vm.toggle(data)}}},[_c('span',{staticStyle:{"white-space":"nowrap"}},[_c('i',{staticClass:"fa fa-check mr-1"}),_vm._v("Allow")])]):_vm._e(),(data.item.deny)?_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.toggle(data)}}},[_c('span',{staticStyle:{"white-space":"nowrap"}},[_c('i',{staticClass:"fa fa-ban mr-1"}),_vm._v("Deny")])]):_vm._e()],1)]}},{key:"cell(commands)",fn:function(data){return [_c('Select2',{attrs:{"options":_vm.commandOptions,"settings":{
            multiple: true,
            placeholder: _vm.$t('channelAccessControlConfig.allCommands'),
            width: '100%',
          }},model:{value:(data.item.commands),callback:function ($$v) {_vm.$set(data.item, "commands", $$v)},expression:"data.item.commands"}})]}},{key:"cell(roleIds)",fn:function(data){return [_c('Select2',{attrs:{"options":_vm.roleOptions,"settings":{
            multiple: true,
            placeholder: _vm.$t('channelAccessControlConfig.everyoneRole'),
            width: '100%',
          }},model:{value:(data.item.roleIds),callback:function ($$v) {_vm.$set(data.item, "roleIds", $$v)},expression:"data.item.roleIds"}})]}},{key:"cell(actionsAfter)",fn:function(data){return [_c('b-button-group',{staticClass:"mr-3"},[_c('b-button',{attrs:{"variant":"light","size":"sm"},on:{"click":function($event){return _vm.down(data)}}},[_c('i',{staticClass:"fa fa-caret-down"})]),_c('b-button',{attrs:{"variant":"light","size":"sm"},on:{"click":function($event){return _vm.up(data)}}},[_c('i',{staticClass:"fa fa-caret-up"})])],1),_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.remove(data)}}},[_c('i',{staticClass:"fa fa-trash"})])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }