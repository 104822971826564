<template>
  <b-card v-if="loaded">
    <div class="row mb-2">
      <div class="col-12">
        <h1>{{ $t("status.title") }}</h1>
        <p>{{ $t("status.description", { botName: $root.config.botName }) }}</p>
        <p>
          <span class="text-success mr-2"
            ><i class="fa fa-circle mr-2"></i
            >{{ $t("status.operational") }}</span
          >
          <span class="text-warning mr-2"
            ><i class="fa fa-circle mr-2"></i
            >{{ $t("status.partialOutage") }}</span
          >
          <span class="text-danger"
            ><i class="fa fa-circle mr-2"></i
            >{{ $t("status.majorOutage") }}</span
          >
        </p>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <h5>{{ $t("status.coreServices") }}</h5>
        <div class="mb-2">
          <b-button
            class="mr-1"
            size="lg"
            variant="success"
            v-b-tooltip.hover
            :title="$t('status.website.tooltip')"
            >{{ $t("status.website.name") }}</b-button
          >
          <b-button
            class="mr-1"
            size="lg"
            :variant="api ? 'success' : 'danger'"
            v-b-tooltip.hover
            :title="$t('status.api.tooltip')"
            >{{ $t("status.api.name") }}</b-button
          >
          <b-button
            class="mr-1"
            size="lg"
            :variant="getHealthStatusVariant(health.worker)"
            v-b-tooltip.hover
            :title="$t('status.worker.tooltip')"
            >{{ $t("status.worker.name") }}</b-button
          >
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <h5>{{ $t("status.discordShards") }}</h5>
        <div v-if="health.discordShards">
          <b-button
            class="mr-1 mb-1"
            size="lg"
            v-for="(status, index) in health.discordShards"
            :key="index"
            :variant="getHealthStatusVariant(status)"
            :class="{ 'btn-glowy': isGuildShard(index) }"
            >{{ index }}</b-button
          >
          <b-form-input
            class="mt-3 w-75"
            v-model="guildId"
            :placeholder="$t('status.putYourServerId')"
            type="text"
            @update="getShardId"
            debounce="500"
          ></b-form-input>
          <Markdown :markdown="$t('status.howToFindYourServerId')" />
        </div>
        <div v-else>{{ $t("status.couldNotGetShardStatuses") }}</div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <h5>{{ $t("status.databaseServices") }}</h5>
        <b-button
          class="mr-1"
          size="lg"
          :variant="getHealthStatusVariant(health.databases.anime)"
          v-b-tooltip.hover
          :title="$t('status.animeDatabaseApi.tooltip')"
          >{{ $t("status.animeDatabaseApi.name") }}</b-button
        >
        <b-button
          class="mr-1"
          size="lg"
          :variant="getHealthStatusVariant(health.databases.visualNovels)"
          v-b-tooltip.hover
          :title="$t('status.visualNovelDatabaseApi.tooltip')"
          >{{ $t("status.visualNovelDatabaseApi.name") }}</b-button
        >
      </div>
    </div>
  </b-card>
  <Loading v-else />
</template>

<script>
import Loading from "@/components/Loading";
import Markdown from "@/components/Markdown.vue";

export default {
  components: { Loading, Markdown },

  props: {
    id: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      loaded: false,
      guildId: null,
      shardId: null,
      health: {
        api: 2,
        worker: 0,
        databases: {
          visualNovels: 0,
          anime: 0,
        },
      },
    };
  },

  methods: {
    async refresh() {
      try {
        const response = await this.axios.get("/api/status/health");
        this.health = response?.data;
        this.api = 2;
      } catch {
        // ignore
      }

      setTimeout(async () => await this.refresh(), 10000);
    },

    async getShardId() {
      if (!this.guildId) {
        this.shardId = null;
        return;
      }
      const response = await this.axios.get(`/api/guild/${this.guildId}/shard`);
      this.shardId = response?.data;
    },

    isGuildShard(shardId) {
      return this.shardId == shardId;
    },

    getHealthStatusVariant(healthStatus) {
      if (healthStatus === 2) return "success";
      if (healthStatus === 1) return "warning";
      return "danger";
    },
  },

  async created() {
    await this.refresh();

    this.loaded = true;
  },
};
</script>

<style>
.btn-glowy {
  font-size: 2rem;
}
</style>
