var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.model)?_c('div',[_c('b-alert',{attrs:{"variant":"warning","fade":"","show":_vm.model.length === _vm.maxWinnerRoleConfigs()}},[_c('i',{staticClass:"fa fa-info-circle mr-2"}),_vm._v(_vm._s(_vm.$t( "serverConfiguration.guessTheAnime.winnerRoleConfigs.reachedMaximumAssignments", { maxWinnerRoleConfigs: _vm.maxWinnerRoleConfigs() } ))+" "),(_vm.guild.patronTier < 1)?_c('div',{staticClass:"mt-2"},[_c('i',{staticClass:"fa fa-star mr-1",staticStyle:{"color":"orange"}}),_c('Markdown',{attrs:{"markdown":_vm.$t(
            'serverConfiguration.guessTheAnime.winnerRoleConfigs.boostedServersGetMoreAssignments',
            {
              patreonLink: _vm.patreonLink,
              patronWinnerRoleConfigs: _vm.patronWinnerRoleConfigs,
            }
          )}})],1):_vm._e()]),_c('div',[_c('b-button',{staticClass:"mb-2",attrs:{"size":"sm","variant":"primary","disabled":_vm.model.length >= _vm.maxWinnerRoleConfigs()},on:{"click":function($event){return _vm.add()}}},[_c('i',{staticClass:"fa fa-plus mr-2"}),_vm._v(_vm._s(_vm.$t("serverConfiguration.guessTheAnime.winnerRoleConfigs.add")))])],1),_c('b-table',{attrs:{"striped":"","small":"","responsive":"","fields":_vm.fields,"items":_vm.model},scopedSlots:_vm._u([{key:"cell(roleId)",fn:function(data){return [_c('b-form-select',{attrs:{"options":_vm.roles,"value-field":"id","text-field":"name"},on:{"input":function($event){return _vm.$emit('input', _vm.model)}},model:{value:(data.item.roleId),callback:function ($$v) {_vm.$set(data.item, "roleId", $$v)},expression:"data.item.roleId"}})]}},{key:"cell(points)",fn:function(data){return [_c('b-form-input',{attrs:{"type":"number","min":"1","step":"1"},on:{"input":function($event){return _vm.$emit('input', _vm.model)}},model:{value:(data.item.points),callback:function ($$v) {_vm.$set(data.item, "points", $$v)},expression:"data.item.points"}})]}},{key:"cell(actionsAfter)",fn:function(data){return [_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.remove(data)}}},[_c('i',{staticClass:"fa fa-trash"})])]}}],null,false,3087516900)})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }