<template>
  <b-form inline>
    <b-button-group class="mr-2">
      <b-button
        @click="onAllTimeClicked"
        v-bind:class="{ active: !config.weekly }"
        >{{ $t("weekPicker.allTime") }}</b-button
      >
      <b-button
        @click="onWeeklyClicked"
        v-bind:class="{ active: config.weekly }"
        >{{ $t("weekPicker.weekly") }}</b-button
      >
    </b-button-group>
    <label v-if="config.weekly" for="sb-year" class="mr-2">{{ $t("weekPicker.year") }}</label>
    <b-form-spinbutton
      v-if="config.weekly"
      class="mr-3"
      id="sb-year"
      v-model="config.year"
      min="2020"
      :max="config.maxYear"
      @input="onYearChanged"
    ></b-form-spinbutton>

    <label v-if="config.weekly" for="sb-week" class="mr-2">{{ $t("weekPicker.week") }}</label>
    <b-form-spinbutton
      v-if="config.weekly"
      id="sb-week"
      v-model="config.week"
      :min="config.minWeek"
      :max="config.maxWeek"
      @input="onWeekChanged"
    ></b-form-spinbutton>
  </b-form>
</template>

<script>
import moment from "moment";

export default {
  props: ["value"],

  data() {
    return {
      config: {
        weekly: false,
        year: 0,
        week: 0,
        minWeek: 1,
        maxWeek: null,
        maxYear: moment.utc().year(),
      },
    };
  },

  methods: {
    onWeekChanged: function() {
      this.emit();
    },

    emit() {
      this.$emit("input", {
        week: this.config.week,
        year: this.config.year,
        weekly: this.config.weekly,
      });
    },

    onYearChanged: function() {
      this.setMinMaxWeeks();
      this.config.week = this.config.minWeek;
    },

    onAllTimeClicked: function() {
      if (!this.config.weekly) return;
      this.config.weekly = false;
      this.config.year = null;
      this.config.week = null;
      this.emit();
    },

    onWeeklyClicked: function() {
      if (this.config.weekly) return;
      this.config.weekly = true;
      var now = moment.utc();
      this.config.year = now.year();
      this.config.week = now.isoWeek();
      this.config.maxWeek = this.config.week;
      this.emit();
    },

    setMinMaxWeeks() {
      if (this.config.year === 2020) {
        this.config.minWeek = 11;
      } else {
        this.config.minWeek = 1;
      }
      var now = moment.utc();
      if (this.config.year == now.year()) {
        this.config.maxWeek = now.isoWeek();
      } else {
        this.config.maxWeek = moment(this.config.year, "YYYY").isoWeeksInYear();
      }
    },
  },

  mounted() {
    this.config.weekly = this.value.weekly;
    this.config.year = this.value.year;
    this.config.week = this.value.week;
    this.setMinMaxWeeks();
  },
};
</script>
