<template>
  <div>
    <b-card class="mb-3">
      <h1>{{ $t("leaderboard.title") }}</h1>
    </b-card>

    <b-card no-body>
      <b-card-header header-tag="nav">
        <b-nav card-header pills>
          <b-nav-item to="overview" active-class="active">
            <i class="fa fa-table mr-2"></i>
            {{ $t("leaderboard.overview.title") }}
          </b-nav-item>
          <b-nav-item to="players" active-class="active">
            <i class="fa fa-user mr-2"></i>
            {{ $t("leaderboard.players.title") }}
          </b-nav-item>
          <b-nav-item to="guilds" active-class="active">
            <i class="fa fa-users mr-2"></i>
            {{ $t("leaderboard.guilds.title") }}
          </b-nav-item>
          <b-nav-item to="achievements" active-class="active">
            <i class="fa fa-trophy mr-2"></i
            >{{ $t("leaderboard.achievements.title") }}
          </b-nav-item>
        </b-nav>
      </b-card-header>
      <b-card-body>
        <router-view></router-view>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>
