<template>
  <div v-if="guild">
    <b-card
      :img-src="guild.avatarUrl"
      :img-alt="guild.name"
      img-left
      class="mb-2"
    >
      <div class="d-inline">
        <h1 class="d-inline">
          {{ guild.name }}
        </h1>
      </div>
      <div class="pull-right" v-if="guild.inviteCode">
        <a
          class="btn btn-lg btn-success"
          :href="'https://discord.com/invite/' + inviteCode"
          target="_blank"
        >
          <i class="fa fa-external-link mr-2"></i>
          {{ $t("guildProfile.join") }}
        </a>
      </div>
    </b-card>

    <b-card no-body>
      <b-card-header header-tag="nav">
        <b-nav card-header pills>
          <b-nav-item
            :to="{
              name: 'guild-statistics',
              params: { id },
            }"
            active-class="active"
            ><i class="fa fa-pie-chart mr-2"></i
            >{{ $t("userProfile.statistics") }}</b-nav-item
          >
        </b-nav>
      </b-card-header>
      <b-card-body>
        <router-view :guild="guild"></router-view>
      </b-card-body>
    </b-card>
  </div>
  <Loading v-else />
</template>

<script>
import Loading from "@/components/Loading";

import gql from "graphql-tag";

const GUILD_PROFILE = gql`
  query ($id: ID!) {
    guild(id: $id) {
      name
      inviteCode
      rankings {
        rank
        week
        year
        points
        players
        rounds
      }
    }
  }
`;

export default {
  components: {
    Loading,
  },

  props: ["id"],

  computed: {
    inviteCode() {
      return this.guild.inviteCode.split(/\//).pop();
    },
  },

  apollo: {
    guild: {
      query: GUILD_PROFILE,
      variables() {
        return {
          id: this.id,
        };
      },
    },
  },
};
</script>

<style scoped>
.card-body {
  width: calc(100% - 128px);
}

.card-img-left {
  width: 128px;
  height: 128px;
}
</style>
