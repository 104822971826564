<template>
  <Loading />
</template>

<script>
import ClientOAuth2 from "client-oauth2";
import Loading from "../components/Loading.vue";

export default {
  components: {
    Loading,
  },

  created() {
    var client = new ClientOAuth2({
      clientId: this.$root.clientId,
      accessTokenUri: "https://discordapp.com/api/oauth2/token",
      authorizationUri: "https://discordapp.com/api/oauth2/authorize",
      redirectUri: `${window.location.protocol}//${window.location.host}/login-callback`,
      scopes: ["identify", "guilds", "guilds.members.read"],
    });

    const codeVerifier = sessionStorage.getItem("code_verifier");
    sessionStorage.removeItem("code_verifier");
    const state = sessionStorage.getItem("state");
    sessionStorage.removeItem("state");

    client.code
      .getToken(window.location.href, {
        state: state,
        body: {
          code_verifier: codeVerifier,
        },
      })
      .then(
        async (user) => {
          user = {
            accessToken: user.accessToken,
            refreshToken: user.refreshToken,
            expires: user.expires,
            tokenType: user.tokenType,
          };

          const response = await this.axios.get("/api/user/details", {
            headers: { Authorization: `Bearer ${user.accessToken}` },
          });

          user.details = response.data;
          sessionStorage.setItem("user", JSON.stringify(user));
          this.$root.user = user;
          window.user = user;
          this.$root.$emit("login", user);

          var returnPath = sessionStorage.getItem("returnPath");
          if (returnPath && returnPath !== "/logout") {
            this.$router.push(returnPath);
          } else {
            this.$router.push("/my-profile");
          }
        },
        () => {
          window.location.href = "/";
        }
      );
  },
};
</script>

<style></style>
