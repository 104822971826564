import Vue from "vue";
import VueRouter from "vue-router";
import {
  IconsPlugin,
  BVToastPlugin,
  CardPlugin,
  NavbarPlugin,
  ButtonPlugin,
  FormPlugin,
  FormInputPlugin,
  TabsPlugin,
  TablePlugin,
  AlertPlugin,
  FormGroupPlugin,
  FormCheckboxPlugin,
  FormSelectPlugin,
  ButtonGroupPlugin,
  FormRadioPlugin,
  LayoutPlugin,
  ProgressPlugin,
  TooltipPlugin,
  FormSpinbuttonPlugin,
  ImagePlugin,
  BadgePlugin,
  ModalPlugin,
  InputGroupPlugin,
  PaginationPlugin,
  SpinnerPlugin,
  ListGroupPlugin,
  EmbedPlugin,
  CarouselPlugin,
  FormTextareaPlugin,
  SkeletonPlugin,
} from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import VueMoment from "vue-moment";
import { routes } from "./routes";
import i18n from "./i18n";
import VueMeta from "vue-meta";
import axios from "axios";
import VueAxios from "vue-axios";
import moment from "moment";
import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { ApolloLink, concat } from "apollo-link";
import VueApollo from "vue-apollo";
import { InMemoryCache } from "apollo-boost";
import { getMainDefinition } from "apollo-utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { RetryLink } from "@apollo/client/link/retry";

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (window.savedPosition) {
      return window.savedPosition;
    } else if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash,
      };
    } else if (to.name == from.name) {
      return {
        selector: "#pageContent",
      };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

Vue.use(router);

Vue.use(AlertPlugin);
Vue.use(BadgePlugin);
Vue.use(ButtonPlugin);
Vue.use(ButtonGroupPlugin);
Vue.use(BVToastPlugin);
Vue.use(CardPlugin);
Vue.use(CarouselPlugin);
Vue.use(EmbedPlugin);
Vue.use(FormPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormRadioPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormSpinbuttonPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(IconsPlugin);
Vue.use(InputGroupPlugin);
Vue.use(IconsPlugin);
Vue.use(ImagePlugin);
Vue.use(LayoutPlugin);
Vue.use(ListGroupPlugin);
Vue.use(ModalPlugin);
Vue.use(NavbarPlugin);
Vue.use(PaginationPlugin);
Vue.use(ProgressPlugin);
Vue.use(SkeletonPlugin);
Vue.use(SpinnerPlugin);
Vue.use(TablePlugin);
Vue.use(TabsPlugin);
Vue.use(TooltipPlugin);
Vue.use(VueMoment, { moment });

Vue.use(VueAxios, axios);

const httpLink = new HttpLink({ uri: "/graphql" });
const wsLink = new GraphQLWsLink(createClient({ url: "/graphql" }));
const authMiddleware = new ApolloLink((operation, forward) => {
  const accessToken = window.user?.accessToken;
  if (accessToken) {
    operation.setContext({
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });
  }

  return forward(operation);
});

const split = new RetryLink().split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const apolloClient = new ApolloClient({
  link: concat(authMiddleware, split),
  cache: new InMemoryCache({
    addTypename: false,
  }),
});
const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

Vue.use(VueApollo);

new Vue({
  router,
  apolloProvider,

  data: {
    user: null,
    authInterceptor: null,
    languageInterceptor: null,
    config: window.config,
    clientId: window.config.clientId,
    loaded: false,
  },

  i18n,

  methods: {
    addAuthInterceptor(user) {
      if (!user) {
        return;
      }

      const authorizationHeader = `Bearer ${user.accessToken}`;
      return this.axios.interceptors.request.use(
        (config) => {
          if (user && user.accessToken) {
            config.headers.Authorization = authorizationHeader;
          }
          return config;
        },
        function (err) {
          return Promise.reject(err);
        }
      );
    },

    findLocale() {
      var locale = window.localStorage.getItem("locale");
      if (!locale) {
        locale = ((navigator.languages && navigator.languages[0]) || "").substr(
          0,
          2
        );
      }
      if (!locale) {
        locale = "en";
      }

      return locale;
    },

    addLocaleInterceptor(locale) {
      locale = locale || "en";

      if (this.languageInterceptor) {
        this.axios.interceptors.request.eject(this.languageInterceptor);
      }
      return (this.languageInterceptor = this.axios.interceptors.request.use(
        (config) => {
          config.headers["Accept-Language"] = locale;
          return config;
        },
        function (err) {
          return Promise.reject(err);
        }
      ));
    },

    setLocale(locale) {
      this.$i18n.locale = locale;
      this.$moment.locale(locale);
      moment.locale(locale);
      this.addLocaleInterceptor(locale);
    },
  },

  mounted() {
    router.afterEach((to, from) => {
      this.$emit("transitionedRoute", { to, from });
    });

    router.beforeEach((to, from, next) => {
      if (to.name == "profile-settings" && !this.$root.user) {
        this.$bvModal
          .msgBoxConfirm("You need to be logged in to perform this action.", {
            title: "Login required",
            size: "sm",
            buttonSize: "md",
            okVariant: "success",
            okTitle: "Login",
            headerClass: "p-2 border-bottom-0",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((confirmed) => {
            if (confirmed) {
              this.$router.push({
                path: "/login",
                query: { returnPath: to.path },
              });
            }
          });
      } else next();
    });

    var userJson = sessionStorage.getItem("user");
    if (userJson) {
      try {
        this.user = JSON.parse(userJson);
        if (!this.user || !this.user.details.id) {
          sessionStorage.removeItem("user");
          this.user = null;
        }
      } catch {
        this.user = null;
      }
      window.user = this.user;
    }

    if (this.user) {
      this.authInterceptor = this.addAuthInterceptor(this.user);
    }

    this.setLocale(this.findLocale());

    this.$root.loaded = true;

    this.$on("login", (user) => {
      if (this.authInterceptor) {
        this.axios.interceptors.request.eject(this.authInterceptor);
      }
      this.authInterceptor = this.addAuthInterceptor(user);
    });

    this.$on("logout", () => {
      sessionStorage.removeItem("user");
      this.user = undefined;
      window.user = this.user;
    });

    this.$on("language", (locale) => {
      window.localStorage.setItem("locale", locale);
      this.$router.go(this.$router.currentRoute);
    });
  },

  render(h) {
    document.title = this.config.botName;

    return h(require("./layouts/Main.vue").default);
  },
}).$mount("#app");
