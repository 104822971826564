<template>
  <div v-if="$root.user && loaded">
    <div v-if="guild">
      <b-card
        :img-src="guild.iconUrl"
        :img-alt="guild.name"
        img-left
        class="mb-2"
      >
        <b-button to="/my-servers" class="float-right">
          <i class="fa fa-caret-left mr-2"></i
          >{{ $t("myServerDetails.backToMyServers") }}
        </b-button>
        <div class="d-flex justify-content-left">
          <div class="d-inline">
            <h1 class="d-inline">{{ guild.name }}</h1>
            <h5 v-if="guild.patronTier > 0">
              <b-badge style="background-color: orange; color: white"
                ><i class="fa fa-star mr-2"></i
                >{{ $t("myServers.boostedServer") }}</b-badge
              >
            </h5>
          </div>
        </div>
      </b-card>
      <b-card no-body>
        <b-card-header header-tag="nav">
          <b-nav card-header pills>
            <b-nav-item
              :to="{
                name: 'my-server-leaderboard',
                params: { id: this.id },
              }"
              active-class="active"
              ><i class="fa fa-trophy mr-2"></i
              >{{ $t("myServerDetails.leaderboard") }}</b-nav-item
            >
            <b-nav-item
              :to="{
                name: 'my-server-configuration',
                params: { id: this.id },
              }"
              active-class="active"
              v-if="guild && guild.canManage"
              ><i class="fa fa-server mr-2"></i
              >{{ $t("myServerDetails.server") }}</b-nav-item
            >
            <b-nav-item
              :to="{
                name: 'my-server-channel-configuration',
                params: { id: this.id },
              }"
              active-class="active"
              v-if="guild && guild.canManage"
              ><i class="fa fa-hashtag mr-2"></i
              >{{ $t("myServerDetails.channels") }}</b-nav-item
            >
            <!-- <b-nav-item
              :to="{
                name: 'my-server-players',
                params: { id: this.id },
              }"
              active-class="active"
              v-if="guild && guild.canManage"
              ><i class="fa fa-users mr-2"></i
              >{{ $t("myServerDetails.players") }}</b-nav-item
            > -->
          </b-nav>
        </b-card-header>
        <b-card-body>
          <router-view :guild="guild"></router-view>
        </b-card-body>
      </b-card>
    </div>
    <div v-else>
      <b-alert show variant="danger"
        ><i class="mr-2 fa fa-exclamation-triangle"></i>Server not
        found or you do not have permission.</b-alert
      >
    </div>
  </div>
  <Loading v-else />
</template>

<script>
import Loading from "../components/Loading";

export default {
  components: {
    Loading,
  },

  props: ["id"],

  methods: {
    async refresh() {
      this.busy = true;
      try {
        var response = await this.axios.get(`/api/guild/${this.id}`);
        this.guild = response.data;
      } finally {
        this.busy = false;
        this.loaded = true;
      }
    },
  },

  data() {
    return {
      busy: false,
      guild: null,
      loaded: false,
    };
  },

  async created() {
    if (!this.$root.user) {      
      this.$router.push({
        path: "/login",
        query: { returnPath: this.$route.path },
      });
    }

    this.refresh();
  },
};
</script>

<style scoped>
.card-img-left {
  width: 128px;
  height: 128px;
}
</style>
