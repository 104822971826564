<template>
  <footer>
    <b-navbar class="mb-3 mt-3 bg-light">
      <b-navbar-nav class="mr-auto">
        <b-nav-item class="mr-2" to="/tos">
          <b-icon icon="file-text-fill"></b-icon>
          <span>{{ $t("common.termsOfService") }}</span>
        </b-nav-item>
        <b-nav-item class="mr-2" to="/privacy">
          <b-icon icon="shield-fill-check"></b-icon>
          <span>{{ $t("common.privacyPolicy") }}</span>
        </b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav>
        <b-nav-item class="mr-2" href="/graphql">
          <b-icon icon="file-code-fill"></b-icon>
          <span>API</span>
        </b-nav-item>
        <b-nav-item class="mr-2" href="https://discord.com/invite/xJrbcsJ">
          <b-icon icon="discord"></b-icon>
          <span>Support server</span>
        </b-nav-item>
      </b-navbar-nav>
    </b-navbar>
  </footer>
</template>

<script>
import isoCountriesLanguages from "iso-countries-languages";

export default {
  components: {},

  data() {
    return {
      currentPath: window.location.pathname,
    };
  },

  methods: {
    setLanguage(locale) {
      this.$root.$emit("language", locale);
    },
  },

  computed: {
    getLanguages: function() {
      const languages = ["en", "es", "nl", "pl"];

      return languages.map((code) => {
        return {
          code,
          text: `${isoCountriesLanguages.getLanguage(code, code)} (${code})`,
        };
      });
    },
  },

  mounted() {
    this.$root.$on("transitionedRoute", () => {
      this.currentPath = window.location.pathname;
    });
  },
};
</script>

<style scoped>
footer {
  margin-top: 2em;
}

.b-icon {
  margin-right: 0.25em;
}

i.nav-icon {
  margin-right: 0.5em;
}
</style>
