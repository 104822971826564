<template>
  <div class="jumbotron pt-3 pb-3 bg-light mb-1">
    <h1 class="display-5">
      {{ $t("needHelp.needHelp")
      }}<i class="fa fa-comment float-right text-muted"></i>
    </h1>
    <p>
      {{ $t("needHelp.supportServerIntroduction") }}
    </p>
    <p>
      <a
        target="_blank"
        class="btn btn-lg btn-primary"
        href="https://discord.com/invite/xJrbcsJ"
        >{{ $t("needHelp.joinTheSupportServer") }}</a
      >
    </p>
    <p>
      {{ $t("needHelp.encounterProblemsContact") }}
    </p>
  </div>
</template>

<script>
export default {
  name: "NeedHelp",
};
</script>
