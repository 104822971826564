var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$root.user && _vm.loaded)?_c('div',[(_vm.guild)?_c('div',[_c('b-card',{staticClass:"mb-2",attrs:{"img-src":_vm.guild.iconUrl,"img-alt":_vm.guild.name,"img-left":""}},[_c('b-button',{staticClass:"float-right",attrs:{"to":"/my-servers"}},[_c('i',{staticClass:"fa fa-caret-left mr-2"}),_vm._v(_vm._s(_vm.$t("myServerDetails.backToMyServers"))+" ")]),_c('div',{staticClass:"d-flex justify-content-left"},[_c('div',{staticClass:"d-inline"},[_c('h1',{staticClass:"d-inline"},[_vm._v(_vm._s(_vm.guild.name))]),(_vm.guild.patronTier > 0)?_c('h5',[_c('b-badge',{staticStyle:{"background-color":"orange","color":"white"}},[_c('i',{staticClass:"fa fa-star mr-2"}),_vm._v(_vm._s(_vm.$t("myServers.boostedServer")))])],1):_vm._e()])])],1),_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{attrs:{"header-tag":"nav"}},[_c('b-nav',{attrs:{"card-header":"","pills":""}},[_c('b-nav-item',{attrs:{"to":{
              name: 'my-server-leaderboard',
              params: { id: this.id },
            },"active-class":"active"}},[_c('i',{staticClass:"fa fa-trophy mr-2"}),_vm._v(_vm._s(_vm.$t("myServerDetails.leaderboard")))]),(_vm.guild && _vm.guild.canManage)?_c('b-nav-item',{attrs:{"to":{
              name: 'my-server-configuration',
              params: { id: this.id },
            },"active-class":"active"}},[_c('i',{staticClass:"fa fa-server mr-2"}),_vm._v(_vm._s(_vm.$t("myServerDetails.server")))]):_vm._e(),(_vm.guild && _vm.guild.canManage)?_c('b-nav-item',{attrs:{"to":{
              name: 'my-server-channel-configuration',
              params: { id: this.id },
            },"active-class":"active"}},[_c('i',{staticClass:"fa fa-hashtag mr-2"}),_vm._v(_vm._s(_vm.$t("myServerDetails.channels")))]):_vm._e()],1)],1),_c('b-card-body',[_c('router-view',{attrs:{"guild":_vm.guild}})],1)],1)],1):_c('div',[_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_c('i',{staticClass:"mr-2 fa fa-exclamation-triangle"}),_vm._v("Server not found or you do not have permission.")])],1)]):_c('Loading')
}
var staticRenderFns = []

export { render, staticRenderFns }