<template>
  <div v-if="$root.user">
    <Loading v-if="loading" />
    <div v-else>
      <b-table
        id="leaderboard-table"
        :fields="fields"
        :items="items"
        :per-page="perPage"
        :current-page="currentPage"
        :borderless="true"
        :striped="true"
        :small="true"
        :hover="true"
        :fixed="true"
        :busy="loading"
      >
        <template v-slot:cell(rank)="data">
          {{ data.item.rank }}
          <i
            class="ml-1 fa fa-lg fa-trophy"
            style="color: orange"
            v-if="data.item.rank === 1"
          ></i>
          <i
            class="ml-1 fa fa-trophy"
            style="color: silver"
            v-if="data.item.rank === 2"
          ></i>
          <i
            class="ml-1 fa fa-trophy"
            style="color: brown"
            v-if="data.item.rank === 3"
          ></i>
        </template>
        <template v-slot:cell(name)="data">
          <span v-if="data.item.userId">
            <router-link :to="'/profile/' + data.item.userId">{{
              data.item.name
            }}</router-link>
            <a
              href="https://www.patreon.com/rinbot?fan_landing=true"
              target="_blank"
              ><i
                v-if="data.item.isPatron"
                class="ml-2 fa fa-star"
                style="color: orange"
                v-b-tooltip.hover
                :title="$t('common.botSupporter', $root.config)"
              ></i
            ></a>
          </span>
          <span v-else>
            <a
              v-if="data.item.name && data.item.inviteCode"
              :href="'https://discord.com/invite/' + data.item.inviteCode"
              target="_blank"
            >
              {{ data.item.name }}
            </a>
            <span v-else-if="data.item.name">
              {{ data.item.name }}
            </span>
            <span v-else class="text-muted">{{
              $t("leaderboardTable.hidden")
            }}</span>
            <a
              href="https://www.patreon.com/rinbot?fan_landing=true"
              target="_blank"
              ><i
                v-if="data.item.isBoosted"
                class="ml-2 fa fa-star"
                style="color: orange"
                v-b-tooltip.hover
                :title="$t('common.boostedByBotSupporter', $root.config)"
              ></i
            ></a>
          </span>
        </template>
      </b-table>
      <b-pagination
        v-if="rows > perPage"
        v-model="currentPage"
        pills
        align="center"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="leaderboard-table"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading";

export default {
  components: {
    Loading,
  },

  props: ["guild"],

  computed: {
    rows() {
      return this.items.length;
    },
  },

  data() {
    return {
      leaderboard: null,
      loading: true,
      perPage: 20,
      currentPage: 1,
      items: [],
      fields: [
        {
          key: "rank",
          label: this.$t("leaderboardTable.rank"),
          thStyle: "width: 10%",
        },
        {
          key: "name",
          label: this.$t("leaderboardTable.name"),
          thStyle: "width: 52%",
        },
        {
          key: "points",
          label: this.$t("leaderboardTable.points"),
          thStyle: "width: 22%",
        },
        {
          key: "rounds",
          label: this.$t("leaderboardTable.rounds"),
          thStyle: "width: 16%",
        },
      ],
    };
  },

  async created() {
    if (!this.$root.user) {
      this.$router.push({
        path: "/login",
        query: { returnPath: this.$route.path },
      });
    }

    try {
      var response = await this.axios.get(
        `/api/guild/${this.guild.id}/leaderboard`
      );
      this.items = response.data.ranked;
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style scoped></style>
