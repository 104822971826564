<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: {
    roundCountPerDay: {
      type: Array,
      default: null,
    },
  },
  mounted() {
    this.renderChart(
      {
        labels: this.roundCountPerDay.map((m) => {
          const date = new Date(m.date);
          return date
            .toLocaleDateString()
            .replace(`/${date.getFullYear()}`, "");
        }),
        datasets: [
          {
            label: this.$t('statistics.roundsPerDay.winners'),
            backgroundColor: "gold",
            fill: true,
            data: this.roundCountPerDay.map((m) => m.winnerCount),
          },
        ],
      },
      {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        legend: {
          display: true,
        },
      }
    );
  },
};
</script>

<style></style>
