<template>
  <div>
    <b-spinner v-if="$apollo.loading" label="Loading"></b-spinner>
    <div v-else-if="anime">
      <b-row>
        <b-col>
          <b-button-group class="pull-right">
            <b-button
              v-if="anime.aniDbId"
              variant="light"
              :href="`https://anidb.net/anime/${anime.aniDbId}`"
              target="_blank"
              ><img
                src="/images/anidb.png"
                class="anime-source-icon mr-2"
              />AniDB</b-button
            >
            <b-button
              v-if="anime.aniListId"
              variant="light"
              :href="`https://anilist.co/anime/${anime.aniListId}`"
              target="_blank"
              ><img
                src="/images/anilist.png"
                class="anime-source-icon mr-2"
              />AniList</b-button
            >
            <b-button
              v-if="anime.myAnimeListId"
              variant="light"
              :href="`https://myanimelist.net/anime/${anime.myAnimeListId}`"
              target="_blank"
              ><img
                src="/images/myanimelist.png"
                class="anime-source-icon mr-2"
              />MyAnimeList</b-button
            >
          </b-button-group>
          <h1>{{ anime.mainTitle }}</h1>
          <h4>
            <span>
              <b-badge
                class="mr-1"
                variant="dark"
                :to="{
                  path: '/database/anime/browse',
                  query: {
                    yearStart: anime.startDate.year,
                    yearEnd: anime.startDate.year,
                  },
                }"
                >{{ anime.startDate.year }}</b-badge
              >
              <difficulty-badge
                :difficulty="anime.difficulty"
                :to="{
                  path: '/database/anime/browse',
                  query: {
                    difficulties: '[&quot;' + anime.difficulty + '&quot;]',
                  },
                }"
              ></difficulty-badge>
              <nsfw-badge
                :nsfw="anime.nsfw"
                :to="{
                  path: '/database/anime/browse',
                  query: {
                    nsfw: '[&quot;' + anime.nsfw + '&quot;]',
                  },
                }"
              ></nsfw-badge>
              <b-badge
                v-if="anime.origin"
                variant="dark"
                v-b-tooltip
                title="Origin"
                >{{ animeOrigin }}</b-badge
              >
            </span>
            <span class="pull-right">
              <capture-type-badge
                v-for="(captureType, index) in anime.captureTypes"
                :key="index"
                :captureType="captureType"
              ></capture-type-badge>

              <capture-type-badge
                v-if="
                  anime.captureTypes.indexOf('AUDIO') === -1 &&
                  anime.themes.length
                "
                captureType="AUDIO"
              ></capture-type-badge>
            </span>
          </h4>
          <h6>
            <b-badge
              :to="{
                path: '/database/anime/browse',
                query: { genres: '[&quot;' + genre.id + '&quot;]' },
              }"
              variant="primary"
              class="mr-1"
              v-for="(genre, index) in animeGenres"
              :key="index"
            >
              {{ genre.name }}
            </b-badge>
          </h6>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="6" lg="4">
          <b-card class="mb-3" no-body>
            <b-carousel indicators :interval="0" controls>
              <b-carousel-slide
                v-for="(poster, index) in animePosters"
                :key="index"
                :img-src="poster.url"
              ></b-carousel-slide>
            </b-carousel>
          </b-card>
        </b-col>
        <b-col sm="12" md="6" lg="4">
          <b-card class="mb-3">
            <template #header>
              <h6 class="mb-0">
                <i class="mr-2 fa fa-bar-chart"></i>Statistics
              </h6>
            </template>
            <b-card-text>
              <b-row v-if="anime.roundStatistics">
                <b-col cols="6">
                  <div>
                    <strong>{{ anime.roundStatistics.roundsWon }}</strong>
                    rounds won
                  </div>
                  <div class="mb-3">
                    <strong>{{ anime.roundStatistics.roundsLost }}</strong>
                    rounds lost
                  </div>
                  <div v-if="animeRoundsWonPercentage !== null">
                    <strong>{{ animeRoundsWonPercentage }}%</strong> won
                  </div>
                </b-col>
                <b-col cols="6">
                  <anime-round-statistics-pie-chart
                    :roundStatistics="anime.roundStatistics"
                  ></anime-round-statistics-pie-chart>
                </b-col>
              </b-row>
              <span class="text-muted" v-else
                >No round statistics available.</span
              >
            </b-card-text>
            <template #footer v-if="anime.roundStatistics">
              <small
                ><i class="fa fa-info-circle mr-1"></i>Updated once per
                day.</small
              >
            </template>
          </b-card>
          <b-card class="mb-3" no-body>
            <template #header>
              <h6 class="mb-0"><i class="mr-2 fa fa-trophy"></i>Leaderboard</h6>
            </template>
            <b-table
              v-if="anime.playerRankings.length > 0"
              id="leaderboard-table"
              :fields="leaderboardFields"
              :items="anime.playerRankings[0].rankedPlayers"
              :borderless="true"
              :striped="true"
              :small="true"
              :hover="true"
              :fixed="true"
            >
              <template v-slot:cell(rank)="data">
                {{ data.item.rank }}
              </template>
              <template v-slot:cell(name)="data">
                <router-link
                  :to="'/profile/' + data.item.player.id"
                  v-if="data.item.player.username"
                  >{{ data.item.player.username }}</router-link
                >
                <span v-else class="text-muted">{{
                  $t("leaderboardTable.hidden")
                }}</span>
              </template>
            </b-table>
            <b-card-body v-else class="text-muted">
              <span class="text-muted">No leaderboard available.</span>
            </b-card-body>
            <template #footer v-if="anime.playerRankings.length > 0">
              <small
                ><i class="fa fa-info-circle mr-1"></i>Updated once per
                day.</small
              >
            </template>
          </b-card>
        </b-col>
        <b-col sm="12" md="6" lg="4">
          <b-card class="mb-3" no-body>
            <template #header>
              <h6 class="mb-0"><i class="mr-2 fa fa-font"></i>Titles</h6>
            </template>
            <b-table
              size="sm"
              small
              borderless
              :fields="titleFields"
              :items="animeTitles"
            >
              <template #head(source)="">
                <i class="fa fa-sitemap" title="Source"></i>
              </template>
              <template #head(languages)="">
                <i class="fa fa-flag" title="Languages"></i>
              </template>
              <template #cell(value)="data"
                >{{ data.item.value
                }}<b-button
                  class="ml-2 pull-right"
                  variant="light"
                  size="sm"
                  v-if="$root.user?.details.isAdmin"
                  @click="excludeAnimeTitle(data.item.value)"
                  ><i class="fa fa-trash"></i></b-button
              ></template>
              <template #cell(source)="data"
                ><AnimeSourceIcon :source="data.item.source"
              /></template>
              <template #cell(languages)="data">
                <img
                  v-for="language in data.item.languages"
                  :key="language"
                  :src="getLanguageFlag(language)"
                />
              </template>
            </b-table>
            <template #footer v-if="anime.titles">
              <div class="text-right">
                <b-button size="sm" @click="toggleTitles()"
                  ><span v-if="showAllTitles"
                    ><i class="fa fa-caret-up mr-2"></i>Show less</span
                  ><span v-else
                    ><i class="fa fa-caret-down mr-2"></i>Show more</span
                  ></b-button
                >
              </div>
            </template>
          </b-card>
          <b-card class="mb-3" no-body>
            <template #header>
              <h6 class="mb-0"><i class="mr-2 fa fa-music"></i>Themes</h6>
            </template>
            <b-table
              v-if="anime.themes && anime.themes.length"
              size="sm"
              small
              borderless
              :fields="themeFields"
              :items="anime.themes"
            >
              <template #cell(song)="data">
                <a
                  :href="data.item.videoUrl"
                  @click.prevent="openAnimeTheme(data.item)"
                  >{{ data.item.song }}</a
                >
              </template>
            </b-table>
            <b-card-body v-else class="text-muted">No themes found</b-card-body>
            <template #footer v-if="anime.themes && anime.themes.length">
              <small
                ><i class="fa fa-info-circle mr-1"></i>Hosted on
                <a href="https://animethemes.moe/" target="_blank"
                  >AnimeThemes.moe</a
                >.</small
              >
            </template>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="12">
          <div class="pull-right">
            <b-dropdown
              class="mr-2"
              id="ddlSetAnimeNsfwOverride"
              text="Set NSFW override"
              v-if="$root.user?.details.isAdmin"
            >
              <b-dropdown-item
                v-for="nsfw in nsfwEnums"
                :key="nsfw"
                @click="setAnimeNsfwOverride(nsfw)"
                >{{ $t(`nsfw.` + nsfw) }}</b-dropdown-item
              >
            </b-dropdown>
            <b-button variant="danger" @click="reportAnime()"
              ><i class="mr-2 fa fa-exclamation-triangle"></i>Report a
              problem</b-button
            >
          </div>
        </b-col>
      </b-row>
    </div>

    <ReportAnime v-if="anime" ref="reportAnime" :anime="anime"></ReportAnime>

    <b-modal
      ref="animeThemeModal"
      body-class="anime-theme-modal-body"
      hide-footer
      size="xl"
      :title="animeThemeModal.title"
    >
      <b-embed
        v-if="animeThemeModal.animeTheme"
        type="iframe"
        aspect="16by9"
        :src="animeThemeModal.animeTheme.videoUrl"
        allowfullscreen
      ></b-embed>
    </b-modal>
  </div>
</template>

<script>
import gql from "graphql-tag";
import * as _ from "lodash";

const ANIME_DETAILS = gql`
  query ($id: ID!) {
    anime(id: $id) {
      id
      aniDbId
      aniListId
      myAnimeListId
      difficulty
      nsfw
      mainTitle
      genres
      startDate {
        day
        month
        year
      }
      titles {
        source
        value
        languages
        type
      }
      posters {
        source
        url
      }
      themes {
        id
        code
        song
        videoUrl
      }
      roundStatistics {
        roundsWon
        roundsLost
        roundsWonPercentage
      }
      captureTypes
      playerRankings {
        rankedPlayers {
          rank
          player {
            id
            username
          }
          points
          rounds
        }
      }
      origin
    }
  }
`;

const NSFW_ENUMS = gql`
  query {
    nsfw: __type(name: "Nsfw") {
      enumValues {
        name
      }
    }
  }
`;

import AnimeSourceIcon from "@/components/AnimeSourceIcon";
import NsfwBadge from "@/components/NsfwBadge.vue";
import ReportAnime from "@/components/ReportAnime";
import DifficultyBadge from "@/components/DifficultyBadge.vue";
import CaptureTypeBadge from "@/components/CaptureTypeBadge.vue";
import AnimeRoundStatisticsPieChart from "@/components/AnimeRoundStatisticsPieChart.vue";

export default {
  props: ["id"],

  components: {
    AnimeSourceIcon,
    ReportAnime,
    NsfwBadge,
    DifficultyBadge,
    CaptureTypeBadge,
    AnimeRoundStatisticsPieChart,
  },

  data() {
    return {
      showAllTitles: false,
      animeThemeModal: {},
      titleFields: [
        { key: "value", label: "Title", sortable: true },
        //{ key: "languages", sortable: true },
        { key: "source", sortable: true, thStyle: "width: 36px" },
      ],
      themeFields: [
        { key: "song", sortable: true },
        { key: "code", sortable: true, thStyle: "width: 100px" },
      ],
      leaderboardFields: [
        {
          key: "rank",
          label: this.$t("leaderboardTable.rank"),
          thStyle:
            "width: 52px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;",
        },
        {
          key: "name",
          label: this.$t("leaderboardTable.name"),
          thStyle: "width: 52%",
        },
        {
          key: "points",
          label: this.$t("leaderboardTable.points"),
          thStyle:
            "width: 22%; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;",
        },
        {
          key: "rounds",
          label: this.$t("leaderboardTable.rounds"),
          thStyle:
            "width: 16%; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;",
        },
      ],
    };
  },

  computed: {
    animeThemes() {
      // HACK: Song type is desc so that OP comes before ED.
      return _.orderBy(
        this.anime.themes,
        ["songType", "sequence", "id"],
        ["desc", "asc", "asc"]
      );
    },

    animePosters() {
      return _.orderBy(
        this.anime.posters.map((p) => {
          if (p.source == "ANI_LIST") p.order = 1;
          else if (p.source == "MY_ANIME_LIST") p.order = 2;
          else p.order = 3;
          return p;
        }),
        "order",
        "asc"
      );
    },

    animeGenres() {
      return _.orderBy(
        this.anime.genres.map((genre) => {
          return {
            id: genre,
            name: this.$t(`genres.${genre}`),
          };
        }),
        ["name"]
      );
    },

    animeTitles() {
      let titles;
      if (this.showAllTitles) {
        titles = this.anime.titles;
      } else {
        titles = this.anime.titles.filter((t) =>
          t.languages.some(
            (l) => l === "en" || l.startsWith("x-") || l === this.$i18n.locale
          )
        );
      }
      return _.orderBy(titles, ["type"]);
    },

    animeRoundsWonPercentage() {
      if (this.anime.roundStatistics.roundsWonPercentage === null) return null;
      return _.round(this.anime.roundStatistics.roundsWonPercentage * 100, 0);
    },

    animeOrigin() {
      if (!this.anime.origin) return undefined;
      if (this.anime.origin == "JP") return "Japan";
      else if (this.anime.origin == "CH") return "China";
      else if (this.anime.origin == "KR") return "Korea";
      else if (this.anime.origin == "TW") return "Taiwan";
      else return undefined;
    },
  },

  methods: {
    toggleTitles() {
      this.showAllTitles = !this.showAllTitles;
    },

    reportAnime() {
      if (!this.$root.user) {
        this.$bvModal
          .msgBoxConfirm("You need to be logged in to perform this action.", {
            title: "Login required",
            size: "sm",
            buttonSize: "md",
            okVariant: "success",
            okTitle: "Login",
            headerClass: "p-2 border-bottom-0",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((confirmed) => {
            if (confirmed) {
              this.$router.push({
                path: "/login",
                query: { returnPath: window.location.pathname },
              });
            }
          });
      } else {
        this.$refs.reportAnime.show();
      }
    },
    excludeAnimeTitle(title) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation ($animeTitleExcluded: SetAnimeTitleExcluded!) {
              setAnimeTitleExcluded(animeTitleExcluded: $animeTitleExcluded) {
                isSuccess
              }
            }
          `,
          errorPolicy: "none",
          variables: {
            animeTitleExcluded: {
              animeId: this.anime.id,
              title: title,
            },
          },
        })
        .then((response) => {
          if (response.data.setAnimeNsfwOverride.isSuccess) {
            this.$bvToast.toast(`Excluded anime title '${title}'`, {
              title: this.$t("common.success"),
              autoHideDelay: 3000,
              variant: "success",
            });

            setTimeout(() => this.$apollo.queries.anime.refetch(), 2000);
          } else {
            this.$bvToast.toast(`Failed to exclude anime title ${title}`, {
              title: this.$t("common.error"),
              autoHideDelay: 3000,
              variant: "danger",
            });
          }
          this.busy = false;
        })
        .catch(() => {
          this.$bvToast.toast("Could not submit", {
            title: this.$t("common.error"),
            autoHideDelay: 3000,
            variant: "danger",
          });
          this.busy = false;
        });
    },
    setAnimeNsfwOverride(nsfw) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation ($animeNsfwOverride: SetAnimeNsfwOverride!) {
              setAnimeNsfwOverride(animeNsfwOverride: $animeNsfwOverride) {
                isSuccess
              }
            }
          `,
          errorPolicy: "none",
          variables: {
            animeNsfwOverride: {
              animeId: this.anime.id,
              nsfw: nsfw,
            },
          },
        })
        .then((response) => {
          if (response.data.setAnimeNsfwOverride.isSuccess) {
            this.$bvToast.toast(`Set NSFW to ${this.$t(`nsfw.${nsfw}`)}`, {
              title: this.$t("common.success"),
              autoHideDelay: 3000,
              variant: "success",
            });

            setTimeout(() => this.$apollo.queries.anime.refetch(), 2000);
          } else {
            this.$bvToast.toast(
              `Failed to set NSFW to ${this.$t(`nsfw.${nsfw}`)}`,
              {
                title: this.$t("common.error"),
                autoHideDelay: 3000,
                variant: "danger",
              }
            );
          }
          this.busy = false;
        })
        .catch(() => {
          this.$bvToast.toast("Could not submit", {
            title: this.$t("common.error"),
            autoHideDelay: 3000,
            variant: "danger",
          });
          this.busy = false;
        });
    },

    openAnimeTheme(animeTheme) {
      this.animeThemeModal = {
        title: `${this.anime.mainTitle} ${animeTheme.code}: ${animeTheme.song}`,
        animeTheme,
      };
      this.$refs.animeThemeModal.show();
    },

    getLanguageFlag(language) {
      return `/images/flags/${language}.png`;
    },
  },

  apollo: {
    anime: {
      query: ANIME_DETAILS,
      variables() {
        return {
          id: this.id,
        };
      },
    },

    nsfwEnums: {
      query: NSFW_ENUMS,
      update: (data) =>
        data.nsfw.enumValues.map((v) => v.name).filter((d) => d != "UNKNOWN"),
    },
  },
};
</script>

<style>
.anime-theme-modal-body {
  padding: 0;
}

.anime-source-icon {
  vertical-align: middle;
}

a {
  text-decoration: none;
}
</style>
