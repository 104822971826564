var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-9 offset-lg-3 offset-sm-4"},[_c('hr',{staticClass:"mb-3"}),_c('b-button',{staticClass:"mb-2 pull-right",attrs:{"variant":"dark","size":"sm"},on:{"click":_vm.setDefaultTitlesAndLanguages}},[_vm._v(_vm._s(_vm.$t( "serverConfiguration.guessTheAnime.titlePreferences.setToDefaults" )))]),_c('markdown',{attrs:{"markdown":_vm.$t('serverConfiguration.guessTheAnime.titlePreferences.description')}})],1)]),_c('b-form-group',{attrs:{"id":"fieldset-guess-title-preferences-percentage-wrong","label-cols-sm":"4","label-align-sm":"left","label-align-md":"right","label-cols-lg":"3","label":_vm.$t(
        'serverConfiguration.guessTheAnime.titlePreferences.percentageWrong.label'
      ),"label-class":"font-weight-bold"}},[_c('div',{staticClass:"ml-3 mr-2"},[_vm._v(" "+_vm._s(_vm.$t( "serverConfiguration.guessTheAnime.titlePreferences.percentageWrong.description", { percentage: parseInt(_vm.model.percentageWrong * 100), } ))+" ")]),_c('b-form-input',{staticClass:"mt-2 col-lg-6 col-sm-8",attrs:{"id":"checkbox-group-guess-title-preferences-percentage-wrong","type":"range","min":"0.00","max":"0.25","step":"0.01"},on:{"input":function($event){return _vm.$emit('input', _vm.model)}},model:{value:(_vm.model.percentageWrong),callback:function ($$v) {_vm.$set(_vm.model, "percentageWrong", $$v)},expression:"model.percentageWrong"}})],1),_c('b-form-group',{attrs:{"id":"fieldset-disable-title-simplifications","label-cols-sm":"4","label-align-sm":"left","label-align-md":"right","label-cols-lg":"3","label":_vm.$t(
        'serverConfiguration.guessTheAnime.titlePreferences.disableTitleSimplifications.label'
      ),"label-class":"font-weight-bold","description":_vm.$t(
        'serverConfiguration.guessTheAnime.titlePreferences.disableTitleSimplifications.description'
      )}},[_c('b-form-checkbox',{staticClass:"mt-2",attrs:{"id":"checkbox-disable-title-simplifications","name":"checkbox-disable-title-simplifications","disabled":_vm.busy},model:{value:(_vm.model.disableTitleSimplifications),callback:function ($$v) {_vm.$set(_vm.model, "disableTitleSimplifications", $$v)},expression:"model.disableTitleSimplifications"}},[_vm._v(_vm._s(_vm.$t( "serverConfiguration.guessTheAnime.titlePreferences.disableTitleSimplifications.checkbox" )))])],1),(_vm.model.types)?_c('b-form-group',{attrs:{"id":"fieldset-guess-title-preferences-types","label-cols-sm":"4","label-align-sm":"left","label-align-md":"right","label-cols-lg":"3","label":_vm.$t(
        'serverConfiguration.guessTheAnime.titlePreferences.titleTypes.label'
      ),"label-class":"font-weight-bold"}},[_c('b-form-checkbox-group',{staticClass:"mt-2",attrs:{"id":"checkbox-group-guess-title-preferences-types","options":_vm.titleTypes},on:{"input":function($event){return _vm.$emit('input', _vm.model)}},model:{value:(_vm.model.types),callback:function ($$v) {_vm.$set(_vm.model, "types", $$v)},expression:"model.types"}})],1):_vm._e(),(_vm.model.languages)?_c('b-form-group',{attrs:{"id":"fieldset-guess-title-preferences-languages","label-cols-sm":"4","label-align-sm":"left","label-align-md":"right","label-cols-lg":"3","label":_vm.$t(
        'serverConfiguration.guessTheAnime.titlePreferences.titleLanguages.label'
      ),"label-class":"font-weight-bold"}},[_c('div',{staticClass:"row"},[_vm._l((_vm.titleLanguagesParts),function(part,index){return _c('div',{key:index,staticClass:"col-lg-4"},[_c('b-form-checkbox-group',{attrs:{"options":part,"stacked":""},on:{"input":function($event){return _vm.$emit('input', _vm.model)}},model:{value:(_vm.model.languages),callback:function ($$v) {_vm.$set(_vm.model, "languages", $$v)},expression:"model.languages"}})],1)}),_c('div',{staticClass:"col-12 mt-2"},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"dark"},on:{"click":_vm.selectAllTitleLanguages}},[_c('i',{staticClass:"mr-2 fa fa-check-circle"}),_vm._v(_vm._s(_vm.$t( "serverConfiguration.guessTheAnime.titlePreferences.titleLanguages.selectAll" )))]),_c('b-button',{attrs:{"size":"sm","variant":"dark"},on:{"click":_vm.selectNoTitleLanguages}},[_c('i',{staticClass:"mr-2 fa fa-circle-thin"}),_vm._v(_vm._s(_vm.$t( "serverConfiguration.guessTheAnime.titlePreferences.titleLanguages.selectNone" )))])],1)],2)]):_vm._e(),(_vm.model.mainTitlePreferences)?_c('b-form-group',{attrs:{"id":"fieldset-guess-title-preferences-main-title","label-cols-sm":"4","label-align-sm":"left","label-align-md":"left","label-align-lg":"right","label-cols-lg":"3","label":_vm.$t('serverConfiguration.guessTheAnime.titlePreferences.mainTitle.label'),"label-class":"font-weight-bold"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"mt-2 mb-2"},[_vm._v(" "+_vm._s(_vm.$t( "serverConfiguration.guessTheAnime.titlePreferences.mainTitle.description" ))+" ")]),_c('main-title-preferences',{attrs:{"titleLanguages":_vm.titleLanguages,"titleTypes":_vm.titleTypes},model:{value:(_vm.model.mainTitlePreferences),callback:function ($$v) {_vm.$set(_vm.model, "mainTitlePreferences", $$v)},expression:"model.mainTitlePreferences"}})],1)])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }