<template>
  <div class="container" v-if="$root.loaded">
    <b-alert class="mt-2" :show="isBeta" variant="warning">
      <i class="mr-2 fa fa-exclamation-triangle"></i
      ><Markdown
        :markdown="$t('main.isBetaSite', { botName, productionUrl })"
      />
    </b-alert>
    <b-alert class="mt-2" :show="isAinaBot" variant="danger">
      <i class="mr-2 fa fa-exclamation-triangle"></i
      ><Markdown
        :markdown="$t('main.isAinaBotSite', { botName, productionUrl })"
      />
    </b-alert>
    <b-alert class="mt-2" :show="maintenance" variant="danger">
      <i class="mr-2 fa fa-exclamation-triangle"></i>Maintenance mode is
      currently enabled. The Discord bot will temporarily not be available and
      parts of the website will not work. Please check back later.
    </b-alert>
    <Navbar />
    <router-view />
    <Footer />
  </div>
  <Loading v-else />
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Loading from "../components/Loading.vue";
import Markdown from "../components/Markdown.vue";
import Footer from "../components/Footer.vue";

export default {
  components: {
    Navbar,
    Loading,
    Markdown,
    Footer,
  },

  data() {
    return {
      isBeta: false,
      productionUrl: "https://rinbot.moe",
      botName: this.$root.config.botName,
    };
  },

  created() {
    this.isBeta =
      this.$root.config.environment === "beta" ||
      this.$root.config.environment === "dev";
    this.isAinaBot = this.$root.config.botName === "AinaBot";
    this.maintenance = this.$root.config.maintenanceMode;
  },
};
</script>

<style>
body {
  background: #eee;
  font-weight: 500;
}
</style>
