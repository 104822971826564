<template>
  <div>
    <div class="mb-3">
      {{ $t("leaderboard.achievements.description") }}
    </div>
    <LeaderboardTable leaderboardType="achievements" />
  </div>
</template>

<script>
import LeaderboardTable from "../components/LeaderboardTable.vue";

export default {
  components: {
    LeaderboardTable,
  },
};
</script>
