<template>
  <Loading />
</template>

<script>
import Loading from "../components/Loading";

export default {
  components: { Loading },

  async created() {
    if (!this.$root.user) {
      this.$router.push({
        path: "/login",
        query: { returnPath: this.$route.path },
      });
    }

    this.$router.push(`/profile/${this.$root.user.details.id}`);
  },
};
</script>
