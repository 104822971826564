<template>
  <div>
    <Loading v-if="$apollo.loading" />
    <b-form v-else @submit.stop.prevent="onSubmit">
      <b-row>
        <b-col>
          <b-form-group
            id="fieldset-hideNameGlobally"
            :description="$t('mySettings.privacy.hideNameGlobally.description')"
          >
            <b-form-checkbox
              :id="'checkbox-hideNameGlobally'"
              :name="'checkbox-hideNameGlobally'"
              v-model="$v.user.privacy.hideNameGlobally.$model"
            >
              {{ $t("mySettings.privacy.hideNameGlobally.checkbox") }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button :disabled="busy" type="submit" variant="success"
            ><b-spinner small class="mr-2" v-if="busy"></b-spinner
            ><i v-else class="fa fa-fw fa-check mr-2"></i>Save changes</b-button
          ></b-col
        >
      </b-row>
    </b-form>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import gql from "graphql-tag";
import { validationMixin } from "vuelidate";

const USER = gql`
  query GetUserSettings {
    user {
      id
      privacy {
        hideNameGlobally
      }
    }
  }
`;

export default {
  mixins: [validationMixin],

  components: {
    Loading,
  },

  apollo: {
    user: {
      query: USER,
      update: (data) => {
        return data.user;
      },
    },
  },

  validations: {
    user: {
      privacy: {
        hideNameGlobally: {},
      },
    },
  },

  data() {
    return {
      busy: false,
      user: undefined,
    };
  },

  methods: {
    onSubmit() {
      this.busy = true;

      this.$apollo
        .mutate({
          mutation: gql`
            mutation ($userSettings: UpdateUserSettings!) {
              updateUserSettings(userSettings: $userSettings) {
                isSuccess
                user {
                  id
                  privacy {
                    hideNameGlobally
                  }
                }
              }
            }
          `,
          errorPolicy: "none",
          variables: {
            userSettings: {
              privacy: this.user.privacy,
            },
          },
        })
        .then((response) => {
          if (response.data.updateUserSettings.isSuccess) {
            this.$bvToast.toast("Updated privacy settings", {
              title: this.$t("common.success"),
              autoHideDelay: 3000,
              toaster: "b-toaster-bottom-right",
              variant: "success",
            });
          } else {
            this.$bvToast.toast("Could not update", {
              title: this.$t("common.error"),
              autoHideDelay: 3000,
              toaster: "b-toaster-bottom-right",
              variant: "danger",
            });
          }
          this.busy = false;
        })
        .catch(() => {
          this.$bvToast.toast("Could not update", {
            title: this.$t("common.error"),
            autoHideDelay: 3000,
            toaster: "b-toaster-bottom-right",
            variant: "danger",
          });
          this.busy = false;
        });
    },
  },

  async mounted() {
    if (!this.$root.user) {
      this.$router.push({
        path: "/login",
        query: { returnPath: this.$route.path },
      });
    }
  },
};
</script>

<style scoped></style>
