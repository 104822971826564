<template>
  <div class="jumbotron bg-light">
    <div class="row">
      <div class="col-lg-5">
        <img
          class="img-fluid"
          :src="$root.config.botName + '-logo.jpg'"
          alt=""
        />
      </div>
      <div class="col-lg-7">
        <h1>Thank you for inviting {{ $root.config.botName }}!</h1>
        <h4>Next steps...</h4>
        <p>To simply start a round right away you can use <code>!sg</code>.</p>
        <p>
          For a simple list of commands, you may type <code>!help</code> and for
          a complete list, please look in the
          <router-link to="/commands">Commands page</router-link>.
        </p>
        <p>
          Changing the command prefix can be done using <code>!prefix</code> and
          changing the language with <code>!lang</code>
        </p>
        <p>
          For more configuration settings, please
          <router-link to="/login">login on this website</router-link> and check
          the "My servers" link in the top right.<br />You can configure things
          like showing your server on the leaderboard, title languages, hints,
          or even starting rounds when the channel is idle.
        </p>
        <p>
          If you need more help, you can read the
          <router-link to="/faq">FAQ</router-link> or join the
          <a href="https://discord.com/invite/xJrbcsJ"
            >official RinBot support server</a
          >.
        </p>
        <p class="display-4">Have fun!</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>
