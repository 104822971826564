<template>
  <div>
    <Loading v-if="$apollo.loading" />
    <b-form v-else @submit.stop.prevent="onSubmit">
      <b-row>
        <b-col
          lg="4"
          md="6"
          sm="12"
          v-for="(animeList, index) in $v.form.animeLists.$model"
          :key="index"
        >
          <b-card class="mb-4">
            <template #header>
              <img
                class="mr-2"
                :title="$t(`animeListTypes.${animeList.animeListType}`)"
                :src="`/images/${$t(
                  `animeListTypes.${animeList.animeListType}`
                ).toLowerCase()}.png`" />{{ animeListTitle(animeList) }}
              <i
                class="fa fa-fw fa-check text-success mr-2"
                v-b-tooltip
                title="Succeeded"
                v-if="syncState(animeList.id) === 'SUCCEEDED'"
              ></i>
              <i
                class="fa fa-fw fa-exclamation-triangle text-danger ml-1"
                v-b-tooltip
                title="Failed"
                v-else-if="
                  syncState(animeList.id) === 'FAILED' ||
                  syncState(animeList.id) === 'CANCELLED'
                "
              ></i>
              <i
                class="fa fa-fw fa-refresh text-warning ml-1"
                v-b-tooltip
                title="Processing"
                v-else-if="
                  syncState(animeList.id) === 'PROCESSING' ||
                  syncState(animeList.id) === 'SCHEDULED' ||
                  syncState(animeList.id) === 'ENQUEUED'
                "
              ></i>
              <a class="pull-right" href="javascript://" @click="remove(index)"
                ><i class="fa mr-2 fa-times"></i></a
            ></template>
            <b-form-group>
              <b-form-select
                v-model="animeList.animeListType"
                :options="animeListTypeOptions"
              ></b-form-select>
            </b-form-group>
            <b-form-group>
              <b-form-input
                v-model="animeList.user"
                placeholder="Enter your username"
              ></b-form-input>
            </b-form-group>

            <b-form-group>
              <b-form-checkbox
                :disabled="!isPatron"
                v-b-tooltip
                :title="
                  isPatron
                    ? ''
                    : 'Only patrons may use automatic synchronization'
                "
                v-model="animeList.autoSync"
                >Automatically synchronize</b-form-checkbox
              >
              <small
                v-if="isPatron && animeList.autoSync && syncedAt(animeList.id)"
                >Last synced at {{ syncedAt(animeList.id) }}</small
              >
            </b-form-group>
          </b-card>
        </b-col>
        <b-col lg="4" md="6" sm="12">
          <b-card class="text-center">
            <b-button
              variant="success"
              @click="add"
              :disabled="hasMaxAnimeLists"
              ><i class="fa fa-plus mr-2"></i>Add anime list</b-button
            >
            <b-alert class="mt-3" variant="warning" :show="hasMaxAnimeLists"
              >You have reached the maximum number of anime lists.<span
                v-if="!isPatron"
              >
                To increase this amount, become a patron!</span
              ></b-alert
            >
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button :disabled="busy" type="submit" variant="success"
            ><b-spinner small class="mr-2" v-if="busy"></b-spinner
            ><i v-else class="fa fa-fw fa-check mr-2"></i>Save changes</b-button
          ></b-col
        >
      </b-row>
    </b-form>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import gql from "graphql-tag";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import moment from "moment";

const USER = gql`
  query GetUserSettings {
    user {
      id
      animeLists {
        id
        animeListType
        autoSync
        user
        size
        syncState
        syncedAt
      }
    }
  }
`;

const ANIME_LIST_TYPE_ENUMS = gql`
  query GetAnimeListTypes {
    animeListTypes: __type(name: "AnimeListType") {
      enumValues {
        name
      }
    }
  }
`;

export default {
  mixins: [validationMixin],
  components: {
    Loading,
  },

  props: [],

  apollo: {
    user: {
      query: USER,
      pollInterval: 5000,
      update: (data) => {
        return data.user;
      },
      result({ data }) {
        if (this.form) return;
        this.form = {
          animeLists: data.user?.animeLists?.map((a) => {
            return {
              id: a.id,
              autoSync: a.autoSync,
              animeListType: a.animeListType,
              user: a.user,
            };
          }),
        };
      },
    },
    animeListTypes: {
      query: ANIME_LIST_TYPE_ENUMS,
      update: (data) =>
        data.animeListTypes.enumValues
          .map((v) => v.name)
          .filter((d) => d != "UNKNOWN"),
    },
  },

  validations: {
    form: {
      animeLists: {
        $each: {
          animeListType: {
            required,
          },
        },
      },
    },
  },

  computed: {
    isPatron() {
      return this.$root.user.details.patronTier > 0;
    },
    hasMaxAnimeLists() {
      return (
        this.form.animeLists &&
        this.form.animeLists.length >= (this.isPatron ? 5 : 1)
      );
    },
    animeListTypeOptions() {
      return this.animeListTypes
        ? this.animeListTypes.map((type) => {
            return { text: this.$t(`animeListTypes.${type}`), value: type };
          })
        : [];
    },
  },

  data() {
    return {
      busy: false,
      form: undefined,
    };
  },

  methods: {
    animeListTitle(animeList) {
      if (!animeList.animeListType) return "";
      return `${animeList.user || ""} (${this.size(animeList.id)})`;
    },
    syncedAt(id) {
      const animeList = this.user?.animeLists?.filter((a) => a.id === id)[0];
      if (!animeList) return undefined;
      var syncedAt = moment.utc(animeList.syncedAt).format("LLLL");
      if (syncedAt == "Invalid date") return undefined;
      return `${syncedAt} UTC`;
    },
    syncState(id) {
      return this.user?.animeLists?.filter((a) => a.id === id)[0]?.syncState;
    },
    size(id) {
      return this.user?.animeLists?.filter((a) => a.id === id)[0]?.size ?? 0;
    },
    add() {
      if (!this.form.animeLists) this.form.animeLists = [];
      this.form.animeLists.push({
        id: "00000000-0000-0000-0000-000000000000",
        animeListType: "ANI_LIST",
        user: null,
        autoSync: false,
      });
    },
    remove(index) {
      this.form.animeLists.splice(index, 1);
    },
    onSubmit() {
      this.busy = true;

      let userSettings = {
        animeLists: this.form.animeLists.map((a) => {
          return {
            id: a.id,
            autoSync: this.isPatron && a.autoSync,
            animeListType: a.animeListType,
            user: a.user,
          };
        }),
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation ($userSettings: UpdateUserSettings!) {
              updateUserSettings(userSettings: $userSettings) {
                isSuccess
                user {
                  id
                  animeLists {
                    id
                    animeListType
                    user
                    size
                    syncState
                    syncedAt
                  }
                }
              }
            }
          `,
          errorPolicy: "none",
          variables: {
            userSettings: userSettings,
          },
        })
        .then((response) => {
          if (response.data.updateUserSettings.isSuccess) {
            this.$bvToast.toast("Updated user settings", {
              title: this.$t("common.success"),
              autoHideDelay: 3000,
              toaster: "b-toaster-bottom-right",
              variant: "success",
            });
          } else {
            this.$bvToast.toast("Could not update", {
              title: this.$t("common.error"),
              autoHideDelay: 3000,
              toaster: "b-toaster-bottom-right",
              variant: "danger",
            });
          }
          this.busy = false;
        })
        .catch(() => {
          this.$bvToast.toast("Could not update", {
            title: this.$t("common.error"),
            autoHideDelay: 3000,
            toaster: "b-toaster-bottom-right",
            variant: "danger",
          });
          this.busy = false;
        });
    },
  },

  async mounted() {
    if (!this.$root.user) {
      this.$router.push({
        path: "/login",
        query: { returnPath: this.$route.path },
      });
    }
  },
};
</script>

<style scoped></style>
