<template>
  <div>
    <Introduction />
    <SupportUs />
    <NeedHelp />
  </div>
</template>

<script>
import Introduction from "../components/Introduction.vue";
import SupportUs from "../components/SupportUs.vue";
import NeedHelp from "../components/NeedHelp.vue";

export default {
  components: {
    Introduction,
    SupportUs,
    NeedHelp,
  },

  metaInfo() {
    return {
      title: this.$root.config.botName,
      meta: [
        {
          property: "og:title",
          content: `${this.$root.config.botName}'s homepage`,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:description",
          content: `This is the home of the Discord bot ${this.$root.config.botName}`,
        },
        {
          property: "og:image",
          content: `https://rinbot.ymir.net/${this.$root.config.botName}-logo.jpg`,
        },
        {
          property: "og:url",
          content: window.location.href,
        },
      ],
    };
  },
};
</script>
