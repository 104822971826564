<template>
  <div>
    <!-- <b-navbar toggleable="lg" class="mb-3 mt-3 bg-light">
      <b-navbar-nav class="mr-auto">
        <b-nav-item to="/database/anime" active-class="active">
          <i class="fa fa-film mr-2"></i>
          {{ $t("database.anime.title") }}
        </b-nav-item>
        <b-nav-item to="/database/characters" active-class="active">
          <i class="fa fa-user mr-2"></i>
          {{ $t("database.characters.title") }}
        </b-nav-item>
        <b-nav-item to="/database/visualNovels" active-class="active">
          <i class="fa fa-book mr-2"></i>
          {{ $t("database.visualNovels.title") }}
        </b-nav-item>
      </b-navbar-nav>
    </b-navbar> -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style scoped></style>
