<template>
  <b-form v-if="loaded" @submit.stop.prevent="onSubmit">
    <div class="mb-3">
      {{ $t("channelConfiguration.description") }}
    </div>
    <hr />
    <b-form-group
      label-cols-sm="3"
      label-align-sm="left"
      label-align-md="right"
      label-for="chkAccessControlEnabled"
      label-class="font-weight-bold"
      :label="$t('channelConfiguration.channelAccessControlEnabled.label')"
      :description="
        $t('channelConfiguration.channelAccessControlEnabled.description', {
          botName,
        })
      "
    >
      <b-form-checkbox
        id="chkAccessControlEnabled"
        name="chkAccessControlEnabled"
        v-model="$v.form.channelAccessControlEnabled.$model"
      >
        {{ $t("channelConfiguration.channelAccessControlEnabled.checkbox") }}
      </b-form-checkbox>
    </b-form-group>
    <hr />
    <b-form-group>
      <b-button
        variant="success"
        @click="addChannelConfig"
        :disabled="busy || $v.form.channelConfigs.$model.length === 10"
      >
        <i class="fa fa-plus mr-2"></i
        >{{ $t("channelConfiguration.add") }}</b-button
      >
    </b-form-group>
    <div v-if="form.channelConfigs.length === 0">
      <b-alert class="ml-4" variant="info" :show="true">
        {{ $t("channelConfiguration.noChannelsConfigured") }}
      </b-alert>
    </div>
    <div v-else>
      <b-alert
        variant="warning"
        fade
        :show="$v.form.channelConfigs.$model.length === maxChannelConfigs()"
      >
        {{
          $t("channelConfiguration.maximumReached", {
            maxChannelConfigs: maxChannelConfigs(),
          })
        }}
        <span v-if="guild.patronTier < 1">
          <i class="fa fa-star mr-1" style="color: orange"></i
          ><Markdown
            :markdown="
              $t('channelConfiguration.boostedServersGetMore', {
                patreonLink,
                patronMaxConfigs,
              })
            "
          />
        </span>
      </b-alert>
      <b-row
        class="channel-row pt-3"
        v-for="(channelConfig, index) in $v.form.channelConfigs.$model"
        :key="channelConfig.channelId"
      >
        <b-col sm="3">
          <b-form-group>
            <div class="d-flex">
              <b-button
                class="mr-1"
                size="sm"
                variant="danger"
                @click="deleteChannelConfig(index)"
                :disabled="busy"
              >
                <i class="fa fa-times"></i>
              </b-button>
              <b-form-select
                v-if="channelOptions"
                v-model="channelConfig.channelId"
                :options="channelOptions"
                @input="onChannelSelected"
                size="sm"
              ></b-form-select>
            </div> </b-form-group
        ></b-col>
        <b-col v-if="channelConfig.isVoice" sm="9">
          <b-form-group>
            <b-form-checkbox
              :id="'chkUseForVoiceRounds-' + channelConfig.channelId"
              :name="'chkUseForVoiceRounds-' + channelConfig.channelId"
              v-model="channelConfig.useForVoiceRounds"
              @input="onUseForVoiceRoundsChecked(channelConfig)"
            >
              {{ $t("channelConfiguration.useForVoiceRounds.checkbox") }}
            </b-form-checkbox>
          </b-form-group>

          <div v-if="channelConfig.isStage">
            <b-form-group
              :label="$t('channelConfiguration.stage.topic.label')"
              label-cols-sm="3"
            >
              <b-form-input
                v-model="channelConfig.stage.topic"
                type="text"
                placeholder="Guess the anisong"
              ></b-form-input>

              <b-form-invalid-feedback
                v-if="channelConfig.stage.topic"
                :state="
                  $v.form.channelConfigs.$each[index].stage.topic.maxLength
                "
              >
                {{
                  $t("validation.maxLength", {
                    length: channelConfig.stage.topic.length,
                    max: $v.form.channelConfigs.$each[index].stage.topic.$params
                      .maxLength.max,
                  })
                }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </b-col>
        <b-col v-else sm="9">
          <div v-if="channelConfig.channelId">
            <b-form-group>
              <b-form-checkbox
                :id="'chkStartGameOnIdleEnabled-' + channelConfig.channelId"
                :name="'chkStartGameOnIdleEnabled-' + channelConfig.channelId"
                v-model="channelConfig.startGameOnIdleEnabled"
                @input="refreshStartGameOnIdle"
              >
                {{ $t("channelConfiguration.startGameOnIdle.checkbox") }}
              </b-form-checkbox>
            </b-form-group>
            <b-row v-if="channelConfig.startGameOnIdleEnabled">
              <b-col lg="6">
                <b-form-group
                  :label="
                    $t('channelConfiguration.startGameOnIdle.minutesIdle')
                  "
                  label-cols-sm="4"
                >
                  <b-form-input
                    v-model="channelConfig.startGameOnIdle.minutesIdle"
                    :min="guild.patronTier > 0 ? 1 : 60"
                    max="1440"
                    type="number"
                  ></b-form-input>
                  <small v-if="guild.patronTier < 1"
                    ><i class="fa fa-star mr-1" style="color: orange"></i>
                    <Markdown
                      :markdown="
                        $t(
                          'channelConfiguration.startGameOnIdle.boostedServersCanHaveLess',
                          { patreonLink }
                        )
                      "
                  /></small>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label="Media type" label-cols-sm="4">
                  <b-form-select
                    v-model="channelConfig.startGameOnIdle.mediaType"
                    :options="mediaTypes"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label="Capture type" label-cols-sm="4">
                  <b-form-select
                    v-model="channelConfig.startGameOnIdle.captureType"
                    :options="
                      getCaptureTypes(channelConfig.startGameOnIdle.mediaType)
                    "
                    size="sm"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label="Difficulty" label-cols-sm="4">
                  <b-form-select
                    v-model="channelConfig.startGameOnIdle.difficulty"
                    :options="difficulties"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <ChannelAccessControlConfig
              class="mt-4"
              v-if="$v.form.channelAccessControlEnabled.$model"
              :accessControls="channelConfig.accessControls"
              :roles="guildRoles"
              :commands="commands"
            />
          </div>
        </b-col>
      </b-row>
    </div>

    <hr class="my-4" />
    <div class="row">
      <div class="col-lg-4 offset-3">
        <b-button class="mb-4" type="submit" variant="success" :disabled="busy">
          <i class="fa fa-check mr-2"></i
          >{{ $t("channelConfiguration.saveChanges") }}
        </b-button>
        <b-button
          class="mb-4 ml-2"
          variant="danger"
          :disabled="busy"
          @click="refresh"
        >
          <i class="fa fa-undo mr-2"></i>{{ $t("channelConfiguration.undo") }}
        </b-button>
      </div>
    </div>
  </b-form>
  <Loading v-else />
</template>

<script>
import ChannelAccessControlConfig from "@/components/ChannelAccessControlConfig";
import Loading from "@/components/Loading";
import Markdown from "@/components/Markdown";
import { validationMixin } from "vuelidate";
import {
  maxLength,
  required,
  between,
  numeric,
} from "vuelidate/lib/validators";

export default {
  components: {
    ChannelAccessControlConfig,
    Loading,
    Markdown,
  },

  mixins: [validationMixin],

  validations: {
    form: {
      id: {
        required,
      },
      channelAccessControlEnabled: true,
      channelConfigs: {
        maxLength: maxLength(10),
        $each: {
          channelId: {
            required,
            numeric: numeric(),
          },
          isVoice: true,
          isStage: true,
          startGameOnIdleEnabled: true,
          startGameOnIdle: {
            minutesIdle: {
              between: between(1, 1440),
            },
            difficulty: {
              between: between(0, 5),
            },
            genre: {},
          },
          useForVoiceRounds: true,
          accessControls: {
            $each: {
              commands: true,
              roleIds: true,
              allow: true,
              deny: true,
            },
          },
          stage: {
            topic: {
              maxLength: maxLength(120),
            },
          },
        },
      },
    },
  },

  props: ["guild"],

  methods: {
    addChannelConfig() {
      if (this.$v.form.channelConfigs.$model.length < this.maxChannelConfigs())
        this.$v.form.channelConfigs.$model.push({});
    },

    deleteChannelConfig(index) {
      this.$v.form.channelConfigs.$model.splice(index, 1);
      this.refreshChannelOptions();
    },

    onUseForVoiceRoundsChecked(channelConfig) {
      if (channelConfig.useForVoiceRounds) {
        this.$v.form.channelConfigs.$model
          .filter(
            (c) =>
              c.channelId !== channelConfig.channelId && c.useForVoiceRounds
          )
          .forEach((cc) => {
            cc.useForVoiceRounds = false;
          });
      }
    },

    onChannelSelected(channelId) {
      const channelConfig = this.$v.form.channelConfigs.$model.filter(
        (c) => c.channelId == channelId
      )[0];

      const channel = this.channelData.filter((c) => c.id == channelId)[0];
      channelConfig.isVoice =
        channel.type == "Voice" || channel.type == "Stage";
      channelConfig.isStage = channel.type == "Stage";

      if (channelConfig.isVoice) {
        delete channelConfig.startGameOnIdle;
        channelConfig.startGameOnIdleEnabled = false;
        delete channelConfig.accessControls;

        if (channelConfig.isStage) {
          if (!channelConfig.stage) {
            channelConfig.stage = {
              topic: "",
            };
          }
        } else {
          delete channelConfig.stage;
        }
      } else {
        channelConfig.isVoice = false;
        channelConfig.isStage = false;
        channelConfig.useForVoiceRounds = false;
        channelConfig.accessControls = [];
        delete channelConfig.stage;
      }

      this.refreshChannelOptions();
    },

    async refreshGuildRoles() {
      var guildRolesResponse = await this.axios.get(
        `/api/guild/${this.guild.id}/roles`
      );
      this.guildRoles = guildRolesResponse.data.sort(
        (a, b) => a.position < b.position
      );
    },

    async refreshCommands() {
      var response = await this.axios.get(`/api/guild/channel-config/commands`);
      this.commands = response.data.commands.sort((a, b) =>
        ("" + a).localeCompare(b)
      );
    },

    async refresh() {
      this.busy = true;

      await Promise.all([
        this.refreshChannelConfigs(),
        this.refreshChannelData(),
        this.refreshGuildRoles(),
        this.refreshCommands(),
      ]);

      this.refreshChannelOptions();

      this.busy = false;
      this.loaded = true;
    },

    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.error = false;
      this.busy = true;
      try {
        var response = await this.axios.put(
          `/api/guild/${this.guild.id}/channel-configs`,
          this.form
        );
        if (response.status === 200) {
          this.$bvToast.toast(
            this.$t("channelConfiguration.savedChangesSuccessfully"),
            {
              title: "Success",
              autoHideDelay: 3000,
              variant: "success",
            }
          );
          this.$v.form.$reset();
        } else {
          this.$bvToast.toast(
            this.$t("channelConfiguration.couldNotSaveChanges"),
            {
              title: "Error",
              autoHideDelay: 3000,
              variant: "danger",
            }
          );
        }
      } catch (error) {
        this.$bvToast.toast(error.response.data.errors, {
          title: "Error",
          autoHideDelay: 3000,
          variant: "danger",
        });
        this.error = true;
      }
      this.busy = false;
    },

    async refreshChannelConfigs() {
      try {
        var response = await this.axios.get(
          `/api/guild/${this.guild.id}/channel-configs/`
        );

        this.form = response.data;
      } catch (e) {
        console.error(e);
      }
    },

    isChannelPickedAlready(channelId) {
      return (
        this.form.channelConfigs.length &&
        this.form.channelConfigs.find((c) => c.channelId == channelId) !==
          undefined
      );
    },

    getChannelName(channel) {
      if (channel.type == "Voice") return `🔊 ${channel.name}`;
      if (channel.type == "Stage") return `🕺 ${channel.name}`;
      return `#️⃣ ${channel.name}`;
    },

    refreshChannelOptions() {
      this.channelOptions = this.channelData
        .filter(
          (c) =>
            c.categoryId === null &&
            (c.type == "Text" || c.type == "Voice" || c.type == "Stage")
        )
        .sort((a, b) => a.position > b.position)
        .map((c) => {
          return {
            text: this.getChannelName(c),
            value: c.id,
            disabled: this.isChannelPickedAlready(c.id),
          };
        })
        .concat(
          this.channelData
            .filter((c) => c.type == "Category")
            .sort((a, b) => a.position > b.position)
            .map((c) => {
              return {
                label: c.name,
                options: this.channelData
                  .filter(
                    (nc) =>
                      (nc.type == "Text" ||
                        nc.type == "Voice" ||
                        nc.type == "Stage") &&
                      c.id == nc.categoryId
                  )
                  .sort((a, b) => a.position > b.position)
                  .map((nc) => {
                    return {
                      text: this.getChannelName(nc),
                      value: nc.id,
                      disabled: this.isChannelPickedAlready(nc.id),
                    };
                  }),
              };
            })
        );
    },

    async refreshChannelData() {
      try {
        var response = await this.axios.get(
          `/api/guild/${this.guild.id}/channels`
        );
        response.data.forEach((c) => {
          if (c.type == "Voice") c.position += 2000;
          else if (c.type == "Stage") c.position += 1000;
        });
        this.channelData = response.data;
      } catch (e) {
        console.error(e);
      }
    },

    maxChannelConfigs() {
      return this.guild.patronTier < 1 ? 2 : 10;
    },

    refreshStartGameOnIdle() {
      if (!this.form?.channelConfigs) return;
      this.form.channelConfigs.map((channelConfig) => {
        if (
          channelConfig.startGameOnIdleEnabled &&
          !channelConfig.startGameOnIdle
        ) {
          channelConfig.startGameOnIdle = {
            minutesIdle: 60,
            mediaType: 1,
            captureType: 1,
            difficulty: 1,
          };
        }
      });
    },

    getCaptureTypes(mediaType) {
      return this.captureTypes.filter(
        (ct) => ct.mediaTypes.indexOf(mediaType) > -1
      );
    },
  },

  data() {
    return {
      loaded: false,
      busy: true,
      botName: this.$root.config.botName,
      difficulties: [
        { text: "(everything)", value: 0 },
        { text: "Easy", value: 1 },
        { text: "Normal", value: 2 },
        { text: "Hard", value: 3 },
        { text: "Extreme", value: 4 },
        { text: "Nightmare", value: 5 },
      ],
      mediaTypes: [
        { text: "Anime", value: 1 },
        { text: "Visual Novels", value: 2 },
        { text: "Anime characters", value: 3 },
        { text: "Games", value: 4 },
      ],
      captureTypes: [
        { text: "Images", value: 1, mediaTypes: [1, 2, 3, 4] },
        { text: "Video clips", value: 2, mediaTypes: [1] },
        { text: "Audio", value: 3, mediaTypes: [1] },
      ],
      channelOptions: null,
      form: {
        channelConfigs: [],
      },
      success: false,
      error: false,
      errors: [],
      patreonLink: this.$root.config.patreonUrl,
      patronMaxConfigs: 10,
      guildRoles: null,
    };
  },

  async mounted() {
    if (!this.$root.user) {
      this.$router.push({
        path: "/login",
        query: { returnPath: this.$route.path },
      });
    }

    if (!this.guild.canManage) this.$router.push({ path: "/my-servers" });

    this.refresh();
  },
};
</script>

<style scoped>
.channel-row:nth-child(2n + 1) {
  background-color: #eee;
}
optgroup {
  font-style: normal !important;
}
</style>
