<template>
  <img v-if="getIcon(source)" :src="getIcon(source)" />
</template>

<script>
export default {
  props: ["source"],
  methods: {
    getIcon(source) {
      if(source == "MY_ANIME_LIST") return "/images/myanimelist.png";
      if(source == "ANI_DB") return "/images/anidb.png";
      if(source == "ANI_LIST") return "/images/anilist.png";
      return null;
    },
  },
}
</script>