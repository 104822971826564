<template>
  <div>
    <div class="row">
      <div class="col-lg-9 offset-lg-3">
        <Markdown :markdown="$t(`commands.${category}.title`)" />
      </div>
    </div>
    <dl class="row">
      <template v-for="(item, index) in commands">
        <dt class="col-lg-3 command" :key="`dt-${category}-${index}`">
          <code v-if="item.command">{{ prefix }}{{ item.command }} </code
          ><code :style="item.command ? 'color:orange' : ''">{{
            item.params
          }}</code>
        </dt>
        <dd class="col-lg-9" :key="`dd-${category}-${index}`">
          <i
            v-if="item.onlyForPatrons"
            class="mr-1 fa fa-fw fa-star"
            style="color: orange"
            v-b-tooltip.hover
            :title="$t('common.onlyForPatrons')"
          ></i>
          <i
            v-if="item.experimental"
            class="mr-1 text-danger fa fa-fw fa-flask"
            v-b-tooltip.hover
            :title="$t('common.experimentalFeature')"
          ></i>
          <i
            v-if="item.ephemeral"
            class="mr-1 fa fa-fw fa-eye"
            style="color: purple"
            v-b-tooltip.hover
            :title="$t('slashCommands.ephemeral.tooltip')"
          ></i>
          <Markdown
            :markdown="
              $t(
                `commands.${category}.${item.name || item.command}`,
                translationValues
              )
            "
          />
        </dd>
      </template>
    </dl>
  </div>
</template>

<script>
import Markdown from "./Markdown";

export default {
  props: {
    prefix: String,
    category: String,
    commands: Array,
  },

  components: {
    Markdown,
  },
  data() {
    return {
      translationValues: {},
    };
  },

  mounted() {
    this.translationValues.botName = this.$root.config.botName;
  },
};
</script>

<style scoped>
.command {
  text-align: right;
}

@media (max-width: 992px) {
  .command {
    text-align: left;
  }
}

p {
  margin-bottom: 0px;
}
</style>
