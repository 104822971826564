<template>
  <b-badge :class="'mr-1 bg-' + difficulty.toLowerCase()" :to="to">{{
    $t(`difficulties.${difficulty}`)
  }}</b-badge>
</template>

<script>
export default {
  props: ["difficulty", "to"],
  mounted() {},
};
</script>

<style scoped>
.bg-easy {
  background-color: #28a745;
}
.bg-normal {
  background-color: rgb(223, 182, 0);
}
.bg-hard {
  background-color: #d3601d;
}
.bg-extreme {
  background-color: #d3291d;
}
.bg-nightmare {
  background-color: #b81379;
}
</style>
