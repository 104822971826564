<template>
  <div>
    <b-card no-body class="mb-3">
      <b-tabs card pills>
        <b-tab>
          <template #title>
            <i class="fa fa-fw fa-code mr-2"></i>Slash commands
          </template>
          <SlashCommands />
        </b-tab>
        <b-tab>
          <template #title>
            <i class="fa fa-fw fa-exclamation mr-2"></i>Legacy commands
          </template>
          <LegacyCommands />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import LegacyCommands from "../components/LegacyCommands";
import SlashCommands from "../components/SlashCommands";

export default {
  components: {
    LegacyCommands,
    SlashCommands,
  },
};
</script>
