<template>
  <Loading />
</template>

<script>
import ClientOAuth2 from "client-oauth2";
import pkceChallenge from "pkce-challenge";
import Loading from "../components/Loading.vue";

export default {
  components: {
    Loading,
  },

  props: ["returnPath"],

  created() {
    if (this.returnPath)
      window.sessionStorage.setItem("returnPath", this.returnPath);

    var client = new ClientOAuth2({
      clientId: this.$root.clientId,
      accessTokenUri: "https://discordapp.com/api/oauth2/token",
      authorizationUri: "https://discordapp.com/api/oauth2/authorize",
      redirectUri: `${window.location.protocol}//${window.location.host}/login-callback`,
      scopes: ["identify", "guilds", "guilds.members.read"],
      prompt: "none",
    });

    const challenge = pkceChallenge(128);
    window.sessionStorage.setItem("code_verifier", challenge.code_verifier);
    const state = Math.random().toString(36).substr(2, 5);
    window.sessionStorage.setItem("state", state);

    var uri = client.code.getUri({
      state: state,
      query: {
        code_challenge: challenge.code_challenge,
        code_challenge_method: "S256",
      },
    });
    window.location.href = `${uri}&prompt=none`;
  },
};
</script>

<style></style>
