<script>
import { Pie } from 'vue-chartjs'

export default {
  extends: Pie,
  props: ['roundStatistics'],
  mounted () {
    this.renderChart({
        labels: ["Won", "Lost"],
        datasets: [
          {
            data: [this.roundStatistics.roundsWon, this.roundStatistics.roundsLost],
            backgroundColor: ["rgb(73, 182, 73)", "rgb(218, 92, 92)"]
          }
        ],
      }, {
        
        legend: {
          display: false,
        },
      })
  }
}
</script>

<style>
</style>
