import { render, staticRenderFns } from "./ChannelAccessControlConfig.vue?vue&type=template&id=d4f68f30&scoped=true&"
import script from "./ChannelAccessControlConfig.vue?vue&type=script&lang=js&"
export * from "./ChannelAccessControlConfig.vue?vue&type=script&lang=js&"
import style0 from "./ChannelAccessControlConfig.vue?vue&type=style&index=0&id=d4f68f30&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d4f68f30",
  null
  
)

export default component.exports