var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.$apollo.loading)?_c('Loading'):_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-row',[_vm._l((_vm.$v.form.animeLists.$model),function(animeList,index){return _c('b-col',{key:index,attrs:{"lg":"4","md":"6","sm":"12"}},[_c('b-card',{staticClass:"mb-4",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('img',{staticClass:"mr-2",attrs:{"title":_vm.$t(`animeListTypes.${animeList.animeListType}`),"src":`/images/${_vm.$t(
                `animeListTypes.${animeList.animeListType}`
              ).toLowerCase()}.png`}}),_vm._v(_vm._s(_vm.animeListTitle(animeList))+" "),(_vm.syncState(animeList.id) === 'SUCCEEDED')?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"fa fa-fw fa-check text-success mr-2",attrs:{"title":"Succeeded"}}):(
                _vm.syncState(animeList.id) === 'FAILED' ||
                _vm.syncState(animeList.id) === 'CANCELLED'
              )?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"fa fa-fw fa-exclamation-triangle text-danger ml-1",attrs:{"title":"Failed"}}):(
                _vm.syncState(animeList.id) === 'PROCESSING' ||
                _vm.syncState(animeList.id) === 'SCHEDULED' ||
                _vm.syncState(animeList.id) === 'ENQUEUED'
              )?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"fa fa-fw fa-refresh text-warning ml-1",attrs:{"title":"Processing"}}):_vm._e(),_c('a',{staticClass:"pull-right",attrs:{"href":"javascript://"},on:{"click":function($event){return _vm.remove(index)}}},[_c('i',{staticClass:"fa mr-2 fa-times"})])]},proxy:true}],null,true)},[_c('b-form-group',[_c('b-form-select',{attrs:{"options":_vm.animeListTypeOptions},model:{value:(animeList.animeListType),callback:function ($$v) {_vm.$set(animeList, "animeListType", $$v)},expression:"animeList.animeListType"}})],1),_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":"Enter your username"},model:{value:(animeList.user),callback:function ($$v) {_vm.$set(animeList, "user", $$v)},expression:"animeList.user"}})],1),_c('b-form-group',[_c('b-form-checkbox',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],attrs:{"disabled":!_vm.isPatron,"title":_vm.isPatron
                  ? ''
                  : 'Only patrons may use automatic synchronization'},model:{value:(animeList.autoSync),callback:function ($$v) {_vm.$set(animeList, "autoSync", $$v)},expression:"animeList.autoSync"}},[_vm._v("Automatically synchronize")]),(_vm.isPatron && animeList.autoSync && _vm.syncedAt(animeList.id))?_c('small',[_vm._v("Last synced at "+_vm._s(_vm.syncedAt(animeList.id)))]):_vm._e()],1)],1)],1)}),_c('b-col',{attrs:{"lg":"4","md":"6","sm":"12"}},[_c('b-card',{staticClass:"text-center"},[_c('b-button',{attrs:{"variant":"success","disabled":_vm.hasMaxAnimeLists},on:{"click":_vm.add}},[_c('i',{staticClass:"fa fa-plus mr-2"}),_vm._v("Add anime list")]),_c('b-alert',{staticClass:"mt-3",attrs:{"variant":"warning","show":_vm.hasMaxAnimeLists}},[_vm._v("You have reached the maximum number of anime lists."),(!_vm.isPatron)?_c('span',[_vm._v(" To increase this amount, become a patron!")]):_vm._e()])],1)],1)],2),_c('b-row',[_c('b-col',[_c('b-button',{attrs:{"disabled":_vm.busy,"type":"submit","variant":"success"}},[(_vm.busy)?_c('b-spinner',{staticClass:"mr-2",attrs:{"small":""}}):_c('i',{staticClass:"fa fa-fw fa-check mr-2"}),_vm._v("Save changes")],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }