<template>
  <b-card>
    <h1>{{ $t('statistics.title') }}</h1>
    <b-tabs pills content-class="mt-3" lazy>
      <b-tab :title="$t('statistics.roundsPerDay.title')" active>
        <p>{{ $t('statistics.description') }}</p>
        <StatisticsRoundsPerDayLineChart
          v-if="roundCountPerDay"
          :roundCountPerDay="roundCountPerDay"
          :height="150"
        />
        <StatisticsWinnersPerDayChart
          v-if="roundCountPerDay"
          :roundCountPerDay="roundCountPerDay"
          :height="150"
        />
        <Loading v-else />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import Loading from "../components/Loading.vue";
import StatisticsRoundsPerDayLineChart from "../components/StatisticsRoundsPerDayLineChart.vue";
import StatisticsWinnersPerDayChart from "../components/StatisticsWinnersPerDayChart.vue";

export default {
  components: {
    Loading,
    StatisticsRoundsPerDayLineChart,
    StatisticsWinnersPerDayChart,
  },

  data() {
    return {
      errors: [],
      roundCountPerDay: null,
      loaded: false,
    };
  },

  methods: {
    async loadRoundCountPerDayAsync() {
      if (!this.roundCountPerDay) {
        var response = await this.axios.get("/api/round/count-per-day");
        this.roundCountPerDay = response.data;
      }
    },
  },

  async mounted() {
    await this.loadRoundCountPerDayAsync();
  },
};
</script>
