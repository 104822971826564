<template>
  <div>
    <div v-if="$root.user">
      <Loading />
    </div>
    <div v-else class="text-center">
      <i class="fa fa-sign-out fa-5x text-muted mt-3"></i>
      <p class="display-4">{{ $t("logout.youHaveBeenLoggedOut") }}</p>
    </div>
  </div>
</template>

<script>
import Loading from "../components/Loading.vue";

export default {
  components: {
    Loading,
  },

  async created() {
    if (!this.$root.user) return;

    try {
      const response = await this.axios.get("/api/user/logout");

      if (response) {
        this.$root.$emit("logout");
      }
    } catch (error) {
      window.location.href = "/";
    }
  },
};
</script>

<style></style>
