<template>
  <div>
    <b-card class="mb-3">
      <h1 class="display-5">Frequently Asked Questions</h1>
    </b-card>

    <div role="tablist" v-if="faqs">
      <b-card no-body class="mb-1" v-for="faq in faqs" :key="faq.id">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block @click="toggleFaq(faq)">
            {{ faq.question }}
          </b-button>
        </b-card-header>

        <b-collapse
          :id="'faq-' + faq.id"
          visible
          accordion="faq-accordion"
          role="tabpanel"
        >
          <b-card-body>
            <Markdown :markdown="faq.answer" />
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
    <Loading v-else />
  </div>
</template>

<script>
import Markdown from "@/components/Markdown";

export default {
  components: {
    Markdown,
  },

  data() {
    return {
      faqs: null,
    };
  },

  methods: {
    toggleFaq(faq) {
      this.$root.$emit("bv::toggle::collapse", "faq-" + faq.id);
    },
  },

  async created() {
    if (!this.$root.config.maintenanceMode) {
      const response = await this.axios.get("/api/faq");
      this.faqs = response.data;
    }
  },
};
</script>

<style scoped>
.command {
  text-align: right;
}

@media (max-width: 992px) {
  .command {
    text-align: left;
  }
}

li {
  margin-top: 5px;
}
</style>
