<template>
  <div v-if="loaded">
    <b-form @submit.stop.prevent="onSubmit">
      <div class="row">
        <div class="col-lg-9 col-sm-8 offset-lg-3 offset-sm-4">
          <h4>{{ $t("serverConfiguration.general.title") }}</h4>
        </div>
      </div>
      <!-- Disable legacy commands -->
      <b-form-group
        id="fieldset-disable-legacy-commands"
        label-cols-sm="4"
        label-align-sm="left"
        label-align-md="right"
        label-cols-lg="3"
        :description="
          $t('serverConfiguration.general.disableLegacyCommands.description')
        "
        :label="$t('serverConfiguration.general.disableLegacyCommands.label')"
        label-for="input-disable-legacy-commands"
        label-class="font-weight-bold"
      >
        <b-form-checkbox
          id="checkbox-disable-legacy-commands"
          v-model="$v.form.general.disableLegacyCommands.$model"
          name="checkbox-disable-legacy-commands"
          class="mt-2"
          :disabled="busy"
        >
          {{ $t("serverConfiguration.general.disableLegacyCommands.checkbox") }}
        </b-form-checkbox>
      </b-form-group>

      <!-- Prefix -->
      <b-form-group
        id="fieldset-prefix"
        label-cols-sm="4"
        label-align-sm="left"
        label-align-md="right"
        label-cols-lg="3"
        :description="$t('serverConfiguration.general.prefix.description')"
        :label="$t('serverConfiguration.general.prefix.label')"
        label-for="input-prefix"
        label-class="font-weight-bold"
      >
        <b-form-input
          class="col-lg-6 col-sm-8"
          id="input-prefix"
          name="input-prefix"
          :state="validateState('general', 'prefix')"
          v-model="$v.form.general.prefix.$model"
          aria-describedby="input-prefix-live-feedback"
          :disabled="legacyCommandsDisabled || busy"
        ></b-form-input>

        <b-form-invalid-feedback id="input-prefix-live-feedback">
          {{ $t("serverConfiguration.general.prefix.invalid") }}
        </b-form-invalid-feedback>
      </b-form-group>

      <!-- Language -->
      <b-form-group
        id="fieldset-language"
        label-cols-sm="4"
        label-align-sm="left"
        label-align-md="right"
        label-cols-lg="3"
        :label="$t('serverConfiguration.general.language.label')"
        label-class="font-weight-bold"
        :description="$t('serverConfiguration.general.language.description')"
      >
        <b-form-select
          class="col-lg-6 col-sm-8"
          id="select-language"
          v-model="$v.form.general.languageCode.$model"
          name="select-language"
          :disabled="busy"
          :options="languageCodes"
        >
        </b-form-select>
      </b-form-group>

      <!-- Show in Leaderboards -->
      <b-form-group
        id="fieldset-leaderboards"
        label-cols-sm="4"
        label-align-sm="left"
        label-align-md="right"
        label-cols-lg="3"
        :label="$t('serverConfiguration.general.showInLeaderboards.label')"
        label-class="font-weight-bold"
        :description="
          $t('serverConfiguration.general.showInLeaderboards.description')
        "
      >
        <b-form-checkbox
          id="checkbox-show-in-leaderboards"
          v-model="$v.form.general.showInLeaderboards.$model"
          name="checkbox-show-in-leaderboards"
          class="mt-2"
          :disabled="busy"
        >
          {{ $t("serverConfiguration.general.showInLeaderboards.checkbox") }}
        </b-form-checkbox>
      </b-form-group>

      <!-- Invite code -->
      <b-form-group
        id="fieldset-invite-code"
        label-cols-sm="4"
        label-align-sm="left"
        label-align-md="right"
        label-cols-lg="3"
        :description="$t('serverConfiguration.general.inviteCode.description')"
        :label="$t('serverConfiguration.general.inviteCode.label')"
        label-for="input-invite-code"
        label-class="font-weight-bold"
      >
        <b-form-input
          class="col-lg-6 col-sm-8"
          id="input-invite-code"
          name="input-invite-code"
          :state="validateState('general', 'inviteCode')"
          v-model="$v.form.general.inviteCode.$model"
          aria-describedby="input-invite-code-live-feedback"
          :disabled="busy"
        ></b-form-input>

        <b-form-invalid-feedback id="input-invite-code-live-feedback">
          {{ $t("serverConfiguration.general.inviteCode.invalid") }}
        </b-form-invalid-feedback>
      </b-form-group>

      <!-- Management roles -->
      <b-form-group
        id="fieldset-management-roles"
        label-cols-sm="4"
        label-align-sm="left"
        label-align-md="right"
        label-cols-lg="3"
        :description="
          $t('serverConfiguration.general.managementRoles.description')
        "
        :label="$t('serverConfiguration.general.managementRoles.label')"
        label-for="input-invite-code"
        label-class="font-weight-bold"
      >
        <Select2
          v-model="$v.form.general.managementRoles.$model"
          :options="roleOptions"
          :settings="{
            multiple: true,
            placeholder: $t(
              'serverConfiguration.general.managementRoles.placeholder'
            ),
            width: '100%',
          }"
        />
      </b-form-group>
      <hr class="my-4" />
      <div class="row">
        <div class="col-lg-9 offset-lg-3 offset-sm-4">
          <h4>{{ $t("serverConfiguration.guessTheAnime.title") }}</h4>
        </div>
      </div>

      <!-- Disable summary link -->
      <b-form-group
        id="fieldset-disable-summary-link"
        label-cols-sm="4"
        label-align-sm="left"
        label-align-md="right"
        label-cols-lg="3"
        :label="
          $t('serverConfiguration.guessTheAnime.disableSummaryLink.label')
        "
        label-class="font-weight-bold"
        :description="
          $t('serverConfiguration.guessTheAnime.disableSummaryLink.description')
        "
      >
        <b-form-checkbox
          id="checkbox-disable-summary-link"
          v-model="$v.form.guessTheAnime.disableSummaryLink.$model"
          name="checkbox-disable-summary-link"
          class="mt-2"
          :disabled="busy"
          >{{
            $t("serverConfiguration.guessTheAnime.disableSummaryLink.checkbox")
          }}</b-form-checkbox
        >
      </b-form-group>

      <!-- Anime summary link -->
      <b-form-group
        id="fieldset-anime-summary-link"
        label-cols-sm="4"
        label-align-sm="left"
        label-align-md="right"
        label-cols-lg="3"
        :label="$t('serverConfiguration.guessTheAnime.animeSummaryLink.label')"
        label-class="font-weight-bold"
        :description="
          $t('serverConfiguration.guessTheAnime.animeSummaryLink.description')
        "
      >
        <b-form-select
          class="col-lg-6 col-sm-8"
          id="select-anime-summary-link"
          v-model="$v.form.guessTheAnime.animeSummaryLink.$model"
          name="select-anime-summary-link"
          :disabled="busy || $v.form.guessTheAnime.disableSummaryLink.$model"
          :options="animeSources"
        >
        </b-form-select>
      </b-form-group>

      <!-- Hide alternative titles -->
      <b-form-group
        id="fieldset-hide-alternative-titles"
        label-cols-sm="4"
        label-align-sm="left"
        label-align-md="right"
        label-cols-lg="3"
        :label="
          $t('serverConfiguration.guessTheAnime.hideAlternativeTitles.label')
        "
        label-class="font-weight-bold"
        :description="
          $t(
            'serverConfiguration.guessTheAnime.hideAlternativeTitles.description'
          )
        "
      >
        <b-form-checkbox
          id="checkbox-hide-alternative-titles"
          v-model="$v.form.guessTheAnime.hideAlternativeTitles.$model"
          name="checkbox-hide-alternative-titles"
          class="mt-2"
          :disabled="busy"
          >{{
            $t(
              "serverConfiguration.guessTheAnime.hideAlternativeTitles.checkbox"
            )
          }}</b-form-checkbox
        >
      </b-form-group>

      <!-- Disable drops -->
      <b-form-group
        id="fieldset-disable-drops"
        label-cols-sm="4"
        label-align-sm="left"
        label-align-md="right"
        label-cols-lg="3"
        :label="$t('serverConfiguration.guessTheAnime.disableDrops.label')"
        label-class="font-weight-bold"
        :description="
          $t('serverConfiguration.guessTheAnime.disableDrops.description')
        "
      >
        <b-form-checkbox
          id="checkbox-disable-drops"
          v-model="$v.form.guessTheAnime.disableDrops.$model"
          name="checkbox-disable-drops"
          class="mt-2"
          :disabled="busy"
          >{{
            $t("serverConfiguration.guessTheAnime.disableDrops.checkbox")
          }}</b-form-checkbox
        >
      </b-form-group>

      <!-- Disable hints -->
      <b-form-group
        id="fieldset-disable-hints"
        label-cols-sm="4"
        label-align-sm="left"
        label-align-md="right"
        label-cols-lg="3"
        :label="$t('serverConfiguration.guessTheAnime.disableHints.label')"
        label-class="font-weight-bold"
        :description="
          $t('serverConfiguration.guessTheAnime.disableHints.description')
        "
      >
        <b-form-checkbox
          id="checkbox-disable-hints"
          v-model="$v.form.guessTheAnime.disableHints.$model"
          name="checkbox-disable-hints"
          class="mt-2"
          :disabled="busy"
          >{{
            $t("serverConfiguration.guessTheAnime.disableHints.checkbox")
          }}</b-form-checkbox
        >
      </b-form-group>

      <!-- Show missing players in leaderboards -->
      <b-form-group
        id="fieldset-show-missing-players-in-loaderboards"
        label-cols-sm="4"
        label-align-sm="left"
        label-align-md="right"
        label-cols-lg="3"
        :label="
          $t(
            'serverConfiguration.guessTheAnime.showMissingPlayersInLeaderboards.label'
          )
        "
        label-class="font-weight-bold"
        :description="
          $t(
            'serverConfiguration.guessTheAnime.showMissingPlayersInLeaderboards.description'
          )
        "
      >
        <b-form-checkbox
          id="checkbox-show-missing-players-in-loaderboards"
          v-model="
            $v.form.guessTheAnime.showMissingPlayersInLeaderboards.$model
          "
          name="checkbox-show-missing-players-in-loaderboards"
          class="mt-2"
          :disabled="busy"
          >{{
            $t(
              "serverConfiguration.guessTheAnime.showMissingPlayersInLeaderboards.checkbox"
            )
          }}</b-form-checkbox
        >
      </b-form-group>

      <!-- Button config -->
      <b-form-group
        id="fieldset-roundButtonConfig"
        label-cols-sm="4"
        label-align-sm="left"
        label-align-md="right"
        label-cols-lg="3"
        :label="$t('serverConfiguration.guessTheAnime.roundButtonConfig.label')"
        label-class="font-weight-bold"
        :description="
          $t('serverConfiguration.guessTheAnime.roundButtonConfig.description')
        "
      >
        <b-form-checkbox
          id="checkbox-roundButtonConfig-playAgain"
          v-model="$v.form.guessTheAnime.roundButtonConfig.playAgain.$model"
          name="checkbox-roundButtonConfig-playAgain"
          class="mt-2"
          :disabled="busy"
          >{{
            $t("serverConfiguration.guessTheAnime.roundButtonConfig.playAgain")
          }}</b-form-checkbox
        >
        <b-form-checkbox
          id="checkbox-roundButtonConfig-random"
          v-model="$v.form.guessTheAnime.roundButtonConfig.random.$model"
          name="checkbox-roundButtonConfig-random"
          class="mt-2"
          :disabled="busy"
          >{{
            $t("serverConfiguration.guessTheAnime.roundButtonConfig.random")
          }}</b-form-checkbox
        >
        <b-form-checkbox
          id="checkbox-roundButtonConfig-weeklyPointsLeaderboard"
          v-model="
            $v.form.guessTheAnime.roundButtonConfig.weeklyPointsLeaderboard
              .$model
          "
          name="checkbox-roundButtonConfig-weeklyPointsLeaderboard"
          class="mt-2"
          :disabled="busy"
          >{{
            $t(
              "serverConfiguration.guessTheAnime.roundButtonConfig.weeklyPointsLeaderboard"
            )
          }}</b-form-checkbox
        >
        <b-form-checkbox
          id="checkbox-roundButtonConfig-weeklyTimeLeaderboard"
          v-model="
            $v.form.guessTheAnime.roundButtonConfig.weeklyTimeLeaderboard.$model
          "
          name="checkbox-roundButtonConfig-weeklyTimeLeaderboard"
          class="mt-2"
          :disabled="busy"
          >{{
            $t(
              "serverConfiguration.guessTheAnime.roundButtonConfig.weeklyTimeLeaderboard"
            )
          }}</b-form-checkbox
        >
      </b-form-group>

      <!-- Title preferences -->
      <title-preferences
        v-if="$v.form.guessTheAnime.guessTitlePreferences.$model"
        v-model="$v.form.guessTheAnime.guessTitlePreferences.$model"
        :defaultConfig="defaultConfig"
      />

      <!-- Role configuration -->
      <hr class="my-4" />
      <div class="row">
        <div class="col-lg-9 col-sm-8 offset-lg-3 offset-sm-4">
          <h4>
            {{
              $t("serverConfiguration.guessTheAnime.winnerRoleConfigs.title")
            }}
          </h4>
          <p>
            {{
              $t(
                "serverConfiguration.guessTheAnime.winnerRoleConfigs.description"
              )
            }}
          </p>
          <b-alert variant="info" show class="mt-2">
            <i class="fa fa-info-circle mr-2"></i
            >{{
              $t(
                "serverConfiguration.guessTheAnime.winnerRoleConfigs.permissionInfo",
                { botName: $root.config.botName }
              )
            }}
          </b-alert>
        </div>
      </div>

      <b-form-group
        id="fieldset-winner-role-configs"
        label-cols-sm="4"
        label-align-sm="left"
        label-align-md="left"
        label-align-lg="right"
        label-cols-lg="3"
        :label="$t('serverConfiguration.guessTheAnime.winnerRoleConfigs.label')"
        label-class="font-weight-bold"
      >
        <div class="row">
          <div class="col-12">
            <winner-role-configs
              :key="key"
              v-if="$v.form.winnerRoleConfigs.$model"
              v-model="$v.form.winnerRoleConfigs.$model"
              :guild="guild"
              :roles="guildRoles"
            />
          </div>
        </div>
      </b-form-group>
      <b-form-group
        id="fieldset-point-role-configs"
        label-cols-sm="4"
        label-align-sm="left"
        label-align-md="left"
        label-align-lg="right"
        label-cols-lg="3"
        :label="$t('serverConfiguration.guessTheAnime.pointRoleConfigs.label')"
        label-class="font-weight-bold"
      >
        <div class="row">
          <div class="col-12">
            <point-role-configs
              :key="key"
              v-model="$v.form.pointRoleConfigs.$model"
              :guild="guild"
              :roles="guildRoles"
            />
          </div>
        </div>
      </b-form-group>
      <div class="row">
        <div class="col-lg-9 offset-lg-3 offset-sm-4">
          <b-alert variant="danger" show>
            <i class="fa fa-exclamation-triangle mr-2"></i>
            {{
              $t(
                "serverConfiguration.guessTheAnime.winnerRoleConfigs.warnManagedRole",
                { botName: $root.config.botName }
              )
            }}
          </b-alert>
        </div>
      </div>
      <!-- Save and undo -->
      <hr class="my-4" />
      <div class="row">
        <div class="col-lg-4 offset-3">
          <b-button
            class="mb-4"
            type="submit"
            variant="success"
            :disabled="busy"
          >
            <i class="fa fa-check mr-2"></i
            >{{
              $t(
                "serverConfiguration.guessTheAnime.winnerRoleConfigs.saveChanges"
              )
            }}
          </b-button>
          <b-button
            class="mb-4 ml-2"
            variant="danger"
            :disabled="busy"
            @click="refresh"
          >
            <i class="fa fa-undo mr-2"></i
            >{{
              $t("serverConfiguration.guessTheAnime.winnerRoleConfigs.undo")
            }}
          </b-button>
        </div>
      </div>
    </b-form>
  </div>
  <Loading v-else />
</template>

<script>
import Loading from "@/components/Loading";
import Select2 from "v-select2-component";
import { validationMixin } from "vuelidate";
import {
  between,
  required,
  requiredIf,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import WinnerRoleConfigs from "@/components/WinnerRoleConfigs";
import TitlePreferences from "@/components/TitlePreferences";
import PointRoleConfigs from "@/components/PointRoleConfigs";

export default {
  mixins: [validationMixin],

  components: {
    Loading,
    TitlePreferences,
    PointRoleConfigs,
    WinnerRoleConfigs,
    Select2,
  },

  props: ["guild"],

  computed: {
    legacyCommandsDisabled() {
      return this.form.general.disableLegacyCommands;
    },
  },

  validations: {
    form: {
      general: {
        prefix: {
          required: requiredIf(function () {
            return !this.form.general.disableLegacyCommands;
          }),
          minLength: minLength(1),
          maxLength: maxLength(5),
        },
        disableLegacyCommands: {},
        languageCode: {},
        inviteCode: {
          minLength: minLength(1),
          maxLength: maxLength(100),
        },
        showInLeaderboards: {},
        managementRoles: {
          maxLength: maxLength(3),
        },
      },
      guessTheAnime: {
        disableSummaryLink: {},
        animeSummaryLink: {},
        showMissingPlayersInLeaderboards: {},
        hideAlternativeTitles: {},
        disableDrops: {},
        disableHints: {},
        guessTitlePreferences: {
          types: {},
          languages: {},
          mainTitlePreferences: {},
          percentageWrong: {},
          disableTitleSimplifications: {},
        },
        roundButtonConfig: {
          playAgain: {},
          random: {},
          weeklyPointsLeaderboard: {},
          weeklyTimeLeaderboard: {},
        },
      },
      winnerRoleConfigs: {
        $each: {
          roleId: {
            required,
          },
          rank: {
            required,
            between: between(1, 100),
          },
        },
      },
      pointRoleConfigs: {
        $each: {
          roleId: {
            required,
          },
          points: {
            required,
          },
        },
      },
    },
  },

  methods: {
    async getDefaultConfig() {
      this.busy = true;
      try {
        var response = await this.axios.get("/api/guild/config/defaults");
        this.defaultConfig = JSON.stringify(response.data);
        var languagesResponse = await this.axios.get(
          "/api/guild/config/languages"
        );
        this.languageCodes = [
          {
            value: null,
            text: this.$t("serverConfiguration.general.noLanguageCodeDefined"),
          },
        ];
        this.languageCodes.push(
          ...languagesResponse.data.map((l) => {
            return {
              value: l.code,
              text: l.nativeName,
            };
          })
        );

        this.busy = false;
      } catch (e) {
        console.error(e);
      }
    },

    async refresh() {
      this.busy = true;
      try {
        this.$v.form.$reset();
        var guildConfigResponse = await this.axios.get(
          `/api/guild/${this.guild.id}/config`
        );
        this.guildConfig = guildConfigResponse.data;
        this.form = Object.assign({}, guildConfigResponse.data);
        this.guildConfig.pointRoleConfigs ??= [];
        this.guildConfig.winnerRoleConfigs ??= [];

        var guildRolesResponse = await this.axios.get(
          `/api/guild/${this.guild.id}/roles`
        );
        this.guildRoles = guildRolesResponse.data.sort(
          (a, b) => a.position < b.position
        );
        console.log(this.guildRoles);
        this.roleOptions = this.guildRoles.map((role) => {
          return { id: role.id, text: role.name };
        });
        console.log(this.roleOptions);
        this.forceUpdate();
        this.busy = false;
        this.loaded = true;
      } catch (e) {
        console.error(e);
      }
    },

    onSubmit() {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        this.$bvToast.toast("Invalid input", {
          title: "Error",
          autoHideDelay: 3000,
          variant: "danger",
        });
        return;
      }

      this.error = false;
      this.busy = true;
      this.axios
        .put(`/api/guild/${this.guild.id}/config`, this.form)
        .then((response) => {
          if (response.status === 200) {
            this.$bvToast.toast("Saved changes successfully", {
              title: "Success",
              autoHideDelay: 3000,
              variant: "success",
            });
            this.$v.form.$reset();
          } else {
            this.$bvToast.toast("Could not save changes", {
              title: "Error",
              autoHideDelay: 3000,
              variant: "danger",
            });
          }
        })
        .catch((error) => {
          try {
            this.$bvToast.toast(
              Object.keys(error.response.data.errors)
                .map(
                  (e) => `${e} (${error.response.data.errors[e].join(", ")})`
                )
                .join(", "),
              {
                title: "Error",
                autoHideDelay: 3000,
                variant: "danger",
              }
            );
          } catch {
            this.$bvToast.toast("Validation errors occured", {
              title: "Error",
              autoHideDelay: 3000,
              variant: "danger",
            });
          }
        })
        .then(() => {
          this.busy = false;
        });
    },

    validateState(name, name2) {
      const { $dirty, $error } = this.$v.form[name][name2];
      return $dirty ? !$error : null;
    },

    forceUpdate() {
      this.key++;
    },
  },

  data() {
    return {
      key: 0,
      // agreeToDestroyAllData: false,
      // destroyedError: false,
      // destroyedSucces: false,
      animeSources: [
        { value: 0, text: "RinBot" },
        { value: 1, text: "AniDB" },
        { value: 2, text: "MyAnimeList" },
        { value: 3, text: "AniList" },
        { value: 4, text: "Kitsu" },
        { value: 5, text: "Anime Notifier" },
      ],
      languageCodes: [],
      languages: null,
      busy: false,
      success: false,
      error: false,
      show: true,
      guildConfig: null,
      form: {},
      loaded: false,
      errors: [],
      guildRoles: [],
      roleOptions: [],
      patreonLink: this.$root.config.patreonUrl,
    };
  },

  async mounted() {
    if (!this.$root.user) {
      this.$router.push({
        path: "/login",
        query: { returnPath: this.$route.path },
      });
    }

    if (!this.guild.canManage) {
      this.$router.push({
        path: "/my-servers",
      });
    }

    this.getDefaultConfig();
    this.refresh();
  },
};
</script>

<style scoped>
.bordered-img {
  border: 2px solid #ddd;
}
</style>
