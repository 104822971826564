<template>
  <div>
    <b-card no-body>
      <b-card-header header-tag="nav">
        <b-nav card-header pills>
          <b-nav-item to="/my-settings/anime-lists" active-class="active">
            <i class="fa fa-fw fa-list-ul mr-2"></i>Anime lists
          </b-nav-item>
          <b-nav-item to="/my-settings/privacy" active-class="active">
            <i class="fa fa-fw fa-eye mr-2"></i>Privacy
          </b-nav-item>
        </b-nav>
      </b-card-header>
      <b-card-body>
        <router-view></router-view>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style scoped></style>
