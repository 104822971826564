<template>
  <div class="jumbotron pt-3 pb-3 bg-light">
    <h1 class="display-5">
      <a id="support">Support {{ $root.config.botName }}!</a
      ><i class="fa fa-heart float-right text-muted"></i>
    </h1>
    <h4>Patreon</h4>
    <p>
      {{ $t("supportUs.supportThroughPatreon") }}
    </p>
    <p v-if="goal !== null">
      <span>
        <strong
          >{{
            $t("supportUs.goal", {
              completedPercentage: goal.completedPercentage,
              amount: goal.amountCents / 100,
            })
          }}
        </strong>
      </span>
      <b-progress
        :max="100"
        :value="goal.completedPercentage"
        striped
        height="2rem"
        :variant="goal.completedPercentage >= 100 ? 'success' : 'warning'"
        class="w-100"
      >
      </b-progress>
    </p>
    <p>
      <a target="_blank" href="https://www.patreon.com/rinbot?fan_landing=true"
        ><img
          src="https://c5.patreon.com/external/logo/become_a_patron_button.png"
          alt="Become a Patron!"
      /></a>
    </p>
    <h4>Ko-fi</h4>
    <p>
      {{ $t("supportUs.supportThroughKoFi") }}
    </p>
    <p>
      <a href="https://ko-fi.com/S6S42P4YA" target="_blank"
        ><img
          target="_blank"
          height="36"
          style="border: 0px; height: 36px"
          src="https://cdn.ko-fi.com/cdn/kofi1.png?v=2"
          border="0"
          alt="Buy Me a Coffee at ko-fi.com"
      /></a>
    </p>
    <h4>{{ $t("supportUs.translations") }}</h4>
    <p>
      {{ $t("supportUs.helpWithTranslating") }}
      <Markdown
        :markdown="
          $t('supportUs.joinTheTranslationsChannel', { supportServerUrl })
        "
      />
    </p>
    <p>
      <a href="https://weblate.ymir.net/engage/rinbot/">
        <img
          src="https://weblate.ymir.net/widgets/rinbot/-/287x66-grey.png"
          alt="Translation status"
        /> </a
      ><br />
      <span
        ><small>{{ $t("supportUs.signInWithAuth0") }}</small></span
      >
    </p>
    <h4>{{ $t("supportUs.votingAndReviewing") }}</h4>
    <p>
      {{ $t("supportUs.supportThroughVoting") }}
    </p>
    <p>
      <a
        target="_blank"
        :href="'https://top.gg/bot/' + $root.config.botId + '/vote'"
        class="btn btn-lg btn-info mb-2 mr-1"
      >
        Top.gg
      </a>
      <a
        target="_blank"
        href="https://discordbotlist.com/bots/429656936435286016/upvote"
        class="btn btn-lg btn-info mb-2 mr-1"
      >
        Discord Bot List
      </a>
      <a
        target="_blank"
        :href="'https://bots.ondiscord.xyz/bots/' + $root.config.botId"
        class="btn btn-lg btn-info mb-2 mr-1"
      >
        Bots on Discord
      </a>
      <a
        target="_blank"
        :href="'https://voidbots.net/bot/' + $root.config.botId + '/vote'"
        class="btn btn-lg btn-info mb-2 mr-1"
      >
        Void Bots
      </a>
      <a
        target="_blank"
        :href="'https://bots.discordlabs.org/bot/' + $root.config.botId"
        class="btn btn-lg btn-info mb-2 mr-1"
      >
        Discord Bot Labs
      </a>
    </p>
  </div>
</template>

<script>
import Markdown from "./Markdown";

export default {
  name: "SupportUs",

  components: {
    Markdown,
  },

  data() {
    return {
      goal: null,
      supportServerUrl: this.$root.config.supportServerUrl,
    };
  },

  async created() {
    if (!this.$root.config.maintenanceMode) {
      const response = await this.axios.get("/api/patreon/goal");
      this.goal = response.data;
    }
  },
};
</script>
