<template>
  <b-navbar toggleable="lg" class="mb-3 mt-3 bg-dark" type="dark">
    <b-navbar-brand href="#">{{ $root.config.botName }}</b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="mr-auto">
        <b-nav-item class="mr-2" to="/home">
          <i class="nav-icon fa fa-lg fa-star"></i><br />
          <span>{{ $t("navbar.home") }}</span>
        </b-nav-item>
        <b-nav-item class="mr-2" to="/commands">
          <i class="nav-icon fa fa-lg fa-terminal"></i><br />
          <span>{{ $t("navbar.commands") }}</span>
        </b-nav-item>
        <b-nav-item class="mr-2" to="/faq">
          <i class="nav-icon fa fa-lg fa-info"></i><br />
          <span>{{ $t("navbar.faq") }}</span>
        </b-nav-item>
        <!-- <b-nav-item class="mr-2" to="/changelog">
          <i class="nav-icon fa fa-lg fa-code"></i><br />
          <span>{{ $t("navbar.changelog") }}</span>
        </b-nav-item> -->
        <b-nav-item class="mr-2" to="/leaderboard" v-if="!maintenance">
          <i class="nav-icon fa fa-lg fa-trophy"></i><br />
          <span>{{ $t("navbar.leaderboard") }}</span>
        </b-nav-item>
        <b-nav-item class="mr-2" to="/statistics" v-if="!maintenance">
          <i class="nav-icon fa fa-lg fa-bar-chart"></i><br />
          <span>{{ $t("navbar.statistics") }}</span>
        </b-nav-item>
        <b-nav-item class="mr-2" to="/status" v-if="!maintenance">
          <i class="nav-icon fa fa-lg fa-heartbeat"></i><br />
          <span>{{ $t("navbar.status") }}</span>
        </b-nav-item>
        <b-nav-item to="/database" v-if="!maintenance">
          <i class="nav-icon fa fa-lg fa-database"></i><br />
          <span>{{ $t("navbar.database") }}</span>
        </b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav>
        <b-nav-item-dropdown right>
          <template v-slot:button-content>
            <i class="nav-icon fa fa-globe"></i><br />
            <span>{{ $i18n.locale }}</span>
          </template>
          <b-dropdown-item
            v-for="language in getLanguages"
            @click="setLanguage(language.code)"
            :key="language.code"
            >{{ language.text }}</b-dropdown-item
          >
        </b-nav-item-dropdown>

        <b-nav-item-dropdown right v-if="$root.user && !maintenance">
          <template v-slot:button-content>
            <i class="nav-icon fa fa-lg fa-user"></i><br />
            <span class="mr-1" v-if="$root.user.details">{{
              $root.user.details.username
            }}</span>
          </template>
          <b-dropdown-item :to="'/profile/' + $root.user.details.id">
            <i class="fa fa-fw mr-1 fa-user"></i>
            {{ $t("navbar.myProfile") }}
          </b-dropdown-item>
          <b-dropdown-item to="/my-servers">
            <i class="fa fa-fw mr-1 fa-server"></i>
            {{ $t("navbar.myServers") }}
          </b-dropdown-item>
          <b-dropdown-item to="/my-boosted-servers">
            <i class="fa fa-fw mr-1 fa-star"></i>
            {{ $t("navbar.myBoostedServers") }}
          </b-dropdown-item>
          <b-dropdown-item to="/my-settings" active-class="active">
            <i class="fa fa-fw mr-1 fa-sliders"></i>
            {{ $t("navbar.mySettings") }}
          </b-dropdown-item>
          <hr class="mt-2 mb-2" />
          <b-dropdown-item to="/logout">
            <i class="fa fa-fw mr-1 fa-sign-out"></i>
            {{ $t("navbar.logout") }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item
          :to="{
            path: '/login',
            query: { returnPath: currentPath },
          }"
          right
          v-else-if="!maintenance"
        >
          <i class="fa fa-lg fa-sign-in"></i><br />
          <span>{{ $t("navbar.login") }}</span>
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import isoCountriesLanguages from "iso-countries-languages";

export default {
  components: {},

  data() {
    return {
      currentPath: window.location.pathname,
    };
  },

  methods: {
    setLanguage(locale) {
      this.$root.$emit("language", locale);
    },
  },

  computed: {
    getLanguages: function () {
      const languages = ["en", "es", "nl", "pl"];

      return languages.map((code) => {
        return {
          code,
          text: `${isoCountriesLanguages.getLanguage(code, code)} (${code})`,
        };
      });
    },
  },

  created() {
    this.maintenance = this.$root.config.maintenanceMode;
  },

  mounted() {
    this.$root.$on("transitionedRoute", () => {
      this.currentPath = window.location.pathname;
    });
  },
};
</script>

<style scoped>
.router-link-active {
  background-color: #333;
}

.router-link-active > i.nav-icon {
  color: gold;
}

.b-nav-dropdown >>> .dropdown-menu .router-link-active {
  background-color: #ddd;
}

.b-nav-dropdown >>> .dropdown-menu .router-link-active:hover {
  color: black;
}

.navbar-nav >>> .nav-item > .nav-link {
  text-align: center;
  border-radius: 255px 25px 225px 25px/25px 225px 25px 255px;
}

.navbar-nav >>> .nav-item > .nav-link:hover {
  background-color: #666;
}
</style>
