<template>
  <div>
    <b-spinner v-if="$apollo.loading" label="Loading"></b-spinner>
    <div v-else-if="character">
      <b-row>
        <b-col>
          <h1>{{ character.name.userPreferred }}</h1>
          <h4>
            <span>
              <difficulty-badge
                :difficulty="character.difficulty"
              ></difficulty-badge>
              <nsfw-badge :nsfw="character.nsfw"></nsfw-badge>
            </span>
            <span class="pull-right">
              <capture-type-badge captureType="IMAGE"></capture-type-badge>
              <capture-type-badge
                v-if="character.quoteCount"
                captureType="QUOTE"
                :count="character.quoteCount"
              ></capture-type-badge>
            </span>
          </h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="6" lg="4">
          <b-card class="mb-3" no-body>
            <b-carousel indicators :interval="0" controls>
              <b-carousel-slide
                v-for="(poster, index) in characterPosters"
                :key="index"
              >
                <template #img>
                  <b-img-lazy fluid-grow block :src="poster.url" />
                </template>
              </b-carousel-slide>
            </b-carousel>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mb-2" sm="12" md="8">
          <b-button-group>
            <b-button
              v-if="character.aniListId"
              variant="secondary"
              :href="`https://anilist.co/character/${character.aniListId}`"
              target="_blank"
              ><img
                src="/images/anilist.png"
                class="anime-source-icon mr-2"
              />AniList</b-button
            >
            <b-button
              v-if="character.myAnimeListId"
              variant="secondary"
              :href="`https://myanimelist.net/character/${character.myAnimeListId}`"
              target="_blank"
              ><img
                src="/images/myanimelist.png"
                class="anime-source-icon mr-2"
              />MyAnimeList</b-button
            >
          </b-button-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import * as _ from "lodash";

const CHARACTER_DETAILS = gql`
  query ($id: ID!) {
    character(id: $id) {
      id
      aniListId
      myAnimeListId
      bloodType
      age
      gender
      dateOfBirth {
        day
        month
        year
      }
      name {
        userPreferred
      }
      posters {
        source
        size
        url
      }
      nsfw
      difficulty
      animeAppearances {
        id
        isPrimary
        anime {
          mainTitle
          id
        }
      }
      quoteCount
    }
  }
`;

import NsfwBadge from "@/components/NsfwBadge.vue";
import DifficultyBadge from "@/components/DifficultyBadge.vue";
import CaptureTypeBadge from "@/components/CaptureTypeBadge.vue";

export default {
  props: ["id"],

  components: {
    NsfwBadge,
    DifficultyBadge,
    CaptureTypeBadge,
  },

  data() {
    return {};
  },

  computed: {
    characterPosters() {
      let aniListPosters = this.character.posters.filter(
        (p) => p.source == "ANI_LIST" && p.size == "EXTRA_LARGE"
      );

      if (!aniListPosters.length) {
        aniListPosters = this.character.posters.filter(
          (p) => p.source == "ANI_LIST" && p.size == "LARGE"
        );
      }

      const myAnimeListPosters = this.character.posters.filter(
        (p) => p.source == "MY_ANIME_LIST" && p.size == "LARGE"
      );

      const posters = aniListPosters.concat(myAnimeListPosters);

      return _.orderBy(
        posters.map((p) => {
          if (p.source == "ANI_LIST") p.order = 1;
          else if (p.source == "MY_ANIME_LIST") p.order = 2;
          else p.order = 3;
          return p;
        }),
        "order",
        "asc"
      );
    },
  },

  methods: {},

  apollo: {
    character: {
      query: CHARACTER_DETAILS,
      variables() {
        return {
          id: this.id,
        };
      },
    },
  },
};
</script>

<style>
a {
  text-decoration: none;
}
</style>
