<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <h1 class="display-5">{{ $t("navbar.commands") }}</h1>
        <Markdown :markdown="$t('commands.prefixCanBeChanged')" />
        <p>{{ $t("commands.canUseInDirectMessage") }}</p>
      </div>
    </div>
    <hr />
    <commands-card
      :prefix="prefix"
      category="rounds"
      :commands="commands.rounds"
    />
    <hr />
    <commands-card
      :prefix="prefix"
      category="voiceRounds"
      :commands="commands.voiceRounds"
    />
    <hr />
    <commands-card
      :prefix="prefix"
      category="shortcuts"
      :commands="commands.shortcuts"
    />
    <hr />
    <commands-card
      :prefix="prefix"
      category="queries"
      :commands="commands.queries"
    />
    <hr />
    <commands-card
      :prefix="prefix"
      category="points"
      :commands="commands.points"
    />
    <hr />
    <commands-card :prefix="prefix" category="ctop" :commands="commands.ctop" />
    <hr />
    <commands-card :prefix="prefix" category="misc" :commands="commands.misc" />
    <hr />
    <commands-card
      :prefix="prefix"
      category="management"
      :commands="commands.management"
    />
    <hr />
    <div class="row mb-4">
      <div class="col-lg-12">
        <i
          style="color: orange"
          class="mr-1 fa fa-fw fa-star"
          v-b-tooltip.hover
          :title="$t('commands.onlyForPatrons.tooltip')"
        ></i>
        <Markdown
          :markdown="
            $t('commands.onlyForPatrons.description', {
              patreonUrl,
            })
          "
        />
        <br />
        <i
          class="mr-1 text-danger fa fa-fw fa-flask"
          v-b-tooltip.hover
          :title="$t('commands.isExperimental.tooltip')"
        ></i>
        <Markdown
          :markdown="
            $t('commands.isExperimental.description', {
              supportServerUrl,
            })
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import CommandsCard from "../components/CommandsCard";
import Markdown from "../components/Markdown";

export default {
  components: {
    Markdown,
    CommandsCard,
  },

  data() {
    return {
      prefix: "!",
      patreonUrl: this.$root.config.patreonUrl,
      supportServerUrl: this.$root.config.supportServerUrl,
      commands: {
        rounds: [
          {
            command: "sg",
          },
          {
            command: "sgv",
            onlyForPatrons: true,
          },
          {
            command: "sga",
          },
          {
            command: "sgc",
          },
          {
            command: "sgg",
          },
          {
            command: "sgvn",
          },
          {
            command: "sb",
            experimental: true,
          },
          {
            command: "stopg",
          },
        ],
        voiceRounds: [
          {
            command: "sgs",
          },
          {
            command: "sgsa",
          },
          {
            command: "sbsa",
            experimental: true,
          },
          {
            command: "stopgs",
          },
        ],
        shortcuts: [
          {
            name: "year",
            command: "sg",
            params: "year",
          },
          {
            name: "years",
            command: "sg",
            params: "year1 year2",
          },
          {
            name: "difficulty",
            command: "sg",
            params: "difficulty",
          },
          {
            name: "genre",
            command: "sg",
            params: "genre",
          },
          {
            name: "songType",
            command: "sga",
            params: "song_type",
          },
        ],
        queries: [
          {
            name: "operations",
          },
          {
            name: "numberOfRounds",
            params: "n:",
          },
          {
            name: "imageManipulations",
            params: "im:p,i,o,g,f",
          },
          {
            name: "filters",
          },
          {
            name: "difficulty",
            params: "d:",
          },
          {
            name: "genre",
            params: "g:",
          },
          {
            name: "year",
            params: "y:",
          },
          {
            name: "songType",
            params: "t:",
          },
          {
            name: "mal",
            params: "mal:",
          },
          {
            name: "anilist",
            params: "anilist:",
          },
          {
            name: "aggr",
            params: "aggr:",
          },
        ],
        points: [
          {
            command: "myscore",
          },
          {
            command: "serverscore",
          },
          {
            command: "alltop",
          },
          {
            command: "allfastest",
          },
          {
            command: "weektop",
          },
          {
            command: "weekfastest",
          },
        ],
        ctop: [
          {
            command: "ctop",
          },
          {
            command: "startctop",
          },
          {
            command: "endctop",
          },
          {
            command: "resetctop",
          },
        ],
        misc: [
          {
            command: "genres",
          },
          {
            command: "statg",
          },
          {
            command: "sync",
            params: "mal: anilist:",
          },
          {
            command: "credits",
          },
        ],
        management: [
          {
            command: "prefix",
            params: "your_prefix",
          },
          {
            command: "lang",
            params: "language_code",
          },
        ],
      },
    };
  },
};
</script>

<style scoped></style>
