<template>
  <div class="jumbotron pb-0 bg-light">
    <div class="row">
      <div class="col-lg-7">
        <h1 class="display-4">
          {{ $t("introduction.title", $root.config)
          }}<i style="color: gold" class="fa fa-star ml-2"></i>
        </h1>
        <p class="lead">
          <Markdown
            :markdown="
              $t('introduction.shortDescription', {
                botName: $root.config.botName,
              })
            "
          />
        </p>
        <div>
          <a
            :href="
              'https://discordapp.com/oauth2/authorize?client_id=' +
              $root.config.botId +
              '&scope=bot%20applications.commands&permissions=3198016'
            "
            target="_blank"
            class="btn btn-lg btn-success"
          >
            <i class="fa fa-plus mr-2"></i>
            {{ $t("introduction.inviteButton", $root.config) }}
          </a>
        </div>
        <p class="mt-2">
          . . . {{ $t("introduction.orSeeHerInAction") }}
          <a href="https://discord.com/invite/3CTq3PH" target="_blank">{{
            $t("introduction.supportServer", $root.config)
          }}</a
          >.
        </p>
        <hr class="my-3" />
        <Markdown :markdown="$t('introduction.features')" />
        <p
          v-html="
            $t('introduction.checkTheCommandListAndConfigure', $root.config)
          "
        ></p>
      </div>
      <div class="col-lg-5">
        <img
          class="img-fluid"
          :src="$root.config.botName + '-logo.jpg'"
          alt=""
        />
        <small class="pull-right" v-if="$root.config.botName == 'RinBot'">
          Artwork by
          <a href="https://www.pixiv.net/en/artworks/36910527" target="_blank"
            >momoco</a
          >
        </small>
      </div>
    </div>
  </div>
</template>

<script>
import Markdown from "./Markdown.vue";

export default {
  name: "Introduction",
  components: {
    Markdown,
  },
};
</script>
