<template>
  <div v-if="model">
    <b-alert
      variant="warning"
      fade
      :show="model.length === maxWinnerRoleConfigs()"
    >
      <i class="fa fa-info-circle mr-2"></i
      >{{
        $t(
          "serverConfiguration.guessTheAnime.winnerRoleConfigs.reachedMaximumAssignments",
          { maxWinnerRoleConfigs: maxWinnerRoleConfigs() }
        )
      }}
      <div class="mt-2" v-if="guild.patronTier < 1">
        <i class="fa fa-star mr-1" style="color: orange" /><Markdown
          :markdown="
            $t(
              'serverConfiguration.guessTheAnime.winnerRoleConfigs.boostedServersGetMoreAssignments',
              { patreonLink, patronWinnerRoleConfigs }
            )
          "
        />
      </div>
    </b-alert>
    <div>
      <b-button
        class="mb-2"
        @click="addWinnerRoleConfig()"
        size="sm"
        variant="primary"
        :disabled="model.length >= maxWinnerRoleConfigs()"
        ><i class="fa fa-plus mr-2"></i
        >{{
          $t("serverConfiguration.guessTheAnime.winnerRoleConfigs.add")
        }}</b-button
      >
    </div>
    <b-table striped small responsive :fields="winnerRoleFields" :items="model">
      <template #cell(roleId)="data">
        <b-form-select
          v-model="data.item.roleId"
          :options="roles"
          value-field="id"
          text-field="name"
          @input="$emit('input', model)"
        ></b-form-select>
      </template>
      <template #cell(isWeekly)="data">
        <b-form-radio-group
          v-model="data.item.isWeekly"
          :options="isWeeklyOptions"
          button-variant="outline-primary"
          buttons
          @input="$emit('input', model)"
        ></b-form-radio-group>
      </template>
      <template #cell(type)="data">
        <b-form-radio-group
          v-model="data.item.type"
          :options="rankingTypeOptions"
          button-variant="outline-primary"
          buttons
          @input="$emit('input', model)"
        ></b-form-radio-group>
      </template>
      <template #cell(rank)="data">
        <b-form-input
          v-model="data.item.rank"
          type="number"
          min="1"
          max="100"
          step="1"
          @input="$emit('input', model)"
        ></b-form-input>
      </template>
      <template #cell(actionsAfter)="data">
        <b-button
          @click="removeWinnerRoleConfig(data)"
          variant="danger"
          size="sm"
          ><i class="fa fa-trash"></i
        ></b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import Markdown from "@/components/Markdown";

export default {
  components: { Markdown },

  props: ["guild", "roles", "value"],

  data() {
    return {
      model: this.value,
      patreonLink: this.$root.config.patreonUrl,
      patronWinnerRoleConfigs: 10,
      isWeeklyOptions: [
        {
          text: this.$t(
            "serverConfiguration.guessTheAnime.winnerRoleConfigs.allTime"
          ),
          value: false,
        },
        {
          text: this.$t(
            "serverConfiguration.guessTheAnime.winnerRoleConfigs.weekly"
          ),
          value: true,
        },
      ],
      rankingTypeOptions: [
        {
          text: this.$t(
            "serverConfiguration.guessTheAnime.winnerRoleConfigs.points"
          ),
          value: 0,
        },
        {
          text: this.$t(
            "serverConfiguration.guessTheAnime.winnerRoleConfigs.time"
          ),
          value: 1,
        },
      ],
      winnerRoleFields: [
        {
          key: "roleId",
          label: this.$t(
            "serverConfiguration.guessTheAnime.winnerRoleConfigs.role"
          ),
          thStyle: "min-width: 150px",
        },
        {
          key: "rank",
          label: this.$t(
            "serverConfiguration.guessTheAnime.winnerRoleConfigs.rank"
          ),
          thStyle: "width: 100px",
        },
        {
          key: "isWeekly",
          label: this.$t(
            "serverConfiguration.guessTheAnime.winnerRoleConfigs.isWeekly"
          ),
          thStyle: "width: 160px",
        },
        {
          key: "type",
          label: this.$t(
            "serverConfiguration.guessTheAnime.winnerRoleConfigs.type"
          ),
          thStyle: "width: 140px",
        },
        { key: "actionsAfter", label: "", thStyle: "width: 45px" },
      ],
    };
  },
  methods: {
    addWinnerRoleConfig() {
      if (this.model.length < this.maxWinnerRoleConfigs()) {
        this.model.push({
          roleId: null,
          type: 0,
          rank: 1,
          isWeekly: false,
        });
        this.$emit("input", this.model);
      }
    },

    removeWinnerRoleConfig(data) {
      this.model.splice(data.index, 1);
      this.$emit("input", this.model);
    },

    maxWinnerRoleConfigs() {
      return this.guild.patronTier < 1 ? 1 : 10;
    },
  },
};
</script>
