import Vue from "vue";

import Commands from "./pages/Commands";
Vue.component("Commands", Commands);
// import Changelog from "./pages/Changelog";
// Vue.component("Changelog", Changelog);

import Database from "./pages/Database";
Vue.component("Database", Database);
import DatabaseAnime from "./pages/DatabaseAnime";
Vue.component("DatabaseAnime", DatabaseAnime);
import DatabaseAnimeBrowse from "./pages/DatabaseAnimeBrowse";
Vue.component("DatabaseAnimeBrowse", DatabaseAnimeBrowse);
import DatabaseAnimeDetails from "./pages/DatabaseAnimeDetails";
Vue.component("DatabaseAnimeDetails", DatabaseAnimeDetails);
import DatabaseAnimeSeasonal from "./pages/DatabaseAnimeSeasonal";
Vue.component("DatabaseAnimeSeasonal", DatabaseAnimeSeasonal);
import DatabaseCharacter from "./pages/DatabaseCharacter";
Vue.component("DatabaseCharacter", DatabaseCharacter);
// import DatabaseCharacterBrowse from "./pages/DatabaseCharacterBrowse";
// Vue.component("DatabaseCharacterBrowse", DatabaseCharacterBrowse);
import DatabaseCharacterDetails from "./pages/DatabaseCharacterDetails";
Vue.component("DatabaseCharacterDetails", DatabaseCharacterDetails);

import Faq from "./pages/Faq";
Vue.component("Faq", Faq);

import Guild from "./pages/Guild";
Vue.component("Guild", Guild);
import GuildStatistics from "./pages/GuildStatistics";
Vue.component("GuildStatistics", GuildStatistics);

import Home from "./pages/Home";
Vue.component("Home", Home);

import Invited from "./pages/Invited";
Vue.component("Invited", Invited);

import Leaderboard from "./pages/Leaderboard";
Vue.component("Leaderboard", Leaderboard);
import LeaderboardOverview from "./pages/LeaderboardOverview";
Vue.component("LeaderboardOverview", LeaderboardOverview);
import LeaderboardPlayers from "./pages/LeaderboardPlayers";
Vue.component("LeaderboardPlayers", LeaderboardPlayers);
import LeaderboardAchievements from "./pages/LeaderboardAchievements";
Vue.component("LeaderboardAchievements", LeaderboardAchievements);
import LeaderboardGuilds from "./pages/LeaderboardGuilds";
Vue.component("LeaderboardGuilds", LeaderboardGuilds);

import Login from "./pages/Login";
Vue.component("Login", Login);
import LoginCallback from "./pages/LoginCallback";
Vue.component("LoginCallback", LoginCallback);
import Logout from "./pages/Logout";
Vue.component("Logout", Logout);

import MyProfile from "./pages/MyProfile";
Vue.component("MyProfile", MyProfile);
import MySettings from "./pages/MySettings";
Vue.component("MySettings", MySettings);
import MySettingsAnimeLists from "./pages/MySettingsAnimeLists";
Vue.component("MySettingsAnimeLists", MySettingsAnimeLists);
import MySettingsPrivacy from "./pages/MySettingsPrivacy";
Vue.component("MySettingsPrivacy", MySettingsPrivacy);
import MyServers from "./pages/MyServers";
Vue.component("MyServers", MyServers);
import MyServerChannelConfiguration from "./pages/MyServerChannelConfiguration";
Vue.component("MyServerChannelConfiguration", MyServerChannelConfiguration);
import MyServerConfiguration from "./pages/MyServerConfiguration";
Vue.component("MyServerConfiguration", MyServerConfiguration);
import MyServerDetails from "./pages/MyServerDetails";
Vue.component("MyServerDetails", MyServerDetails);
import MyServerLeaderboard from "./pages/MyServerLeaderboard";
Vue.component("MyServerLeaderboard", MyServerLeaderboard);
import MyServerPlayers from "./pages/MyServerPlayers";
Vue.component("MyServerPlayers", MyServerPlayers);
import MyBoostedServers from "./pages/MyBoostedServers";
Vue.component("MyBoostedServers", MyBoostedServers);

import PrivacyPolicy from "./pages/PrivacyPolicy";
Vue.component("PrivacyPolicy", PrivacyPolicy);
import Profile from "./pages/Profile";
Vue.component("Profile", Profile);
import ProfileAchievements from "./pages/ProfileAchievements";
Vue.component("ProfileAchievements", ProfileAchievements);
import ProfileStatistics from "./pages/ProfileStatistics";
Vue.component("ProfileStatistics", ProfileStatistics);

import Status from "./pages/Status";
Vue.component("Status", Status);
import Statistics from "./pages/Statistics";
Vue.component("Statistics", Statistics);

import TermsOfService from "./pages/TermsOfService";
Vue.component("TermsOfService", TermsOfService);

export const routes = [
  { path: "/", component: Home, name: "root" },
  { path: "/home", component: Home, name: "home" },
  { path: "/invited", component: Invited, name: "invited" },
  { path: "/commands", component: Commands, name: "commands" },
  // { path: "/changelog", component: Changelog, name: "changelog" },
  {
    path: "/database",
    component: Database,
    name: "database",
    children: [
      { path: "", redirect: "anime" },
      {
        path: "anime",
        component: DatabaseAnime,
        name: "database-anime",
        children: [
          { path: "", redirect: "browse" },
          {
            path: "seasonal",
            component: DatabaseAnimeSeasonal,
            name: "database-anime-seasonal",
          },
          {
            path: "browse",
            component: DatabaseAnimeBrowse,
            name: "database-anime-browse",
            props: (route) => {
              return {
                title: route.query.title,
                difficulties: route.query.difficulties,
                genres: route.query.genres,
                nsfw: route.query.nsfw,
                page: +route.query.page,
                pageSize: +route.query.pageSize,
                lists: route.query.lists,
                yearStart: route.query.yearStart,
                yearEnd: route.query.yearEnd,
                sortBy: route.query.sortBy,
              };
            },
          },
          {
            path: ":id",
            component: DatabaseAnimeDetails,
            name: "database-anime-details",
            props: (route) => {
              return {
                id: route.params.id,
              };
            },
          },
        ],
      },
      {
        path: "characters",
        component: DatabaseCharacter,
        name: "database-character",
        children: [
          //{ path: "", redirect: "browse" },
          // {
          //   path: "browse",
          //   component: DatabaseCharacterBrowse,
          //   name: "database-character-browse",
          //   props: (route) => {
          //     console.log(route.query);
          //     return {
          //       query: {
          //         name: route.query.name,
          //         page: route.query.page ? +route.query.page : 0,
          //         pageSize: route.query.pageSize ? +route.query.pageSize : 18,
          //       },
          //     };
          //   },
          // },
          {
            path: ":id",
            component: DatabaseCharacterDetails,
            name: "database-anime-details",
            props: (route) => {
              return {
                id: route.params.id,
              };
            },
          },
        ],
      },
    ],
  },
  { path: "/faq", component: Faq, name: "faq" },
  {
    path: "/leaderboard",
    component: Leaderboard,
    children: [
      { path: "", redirect: "overview" },
      {
        path: "overview",
        component: LeaderboardOverview,
        name: "leaderboard-overview",
      },
      {
        path: "players",
        component: LeaderboardPlayers,
        props: (route) => {
          return {
            weekly: route.query.weekly === "true",
            week: +route.query.week,
            year: +route.query.year,
          };
        },
        name: "leaderboard-players",
      },
      {
        path: "achievements",
        component: LeaderboardAchievements,
        name: "leaderboard-achievements",
      },
      {
        path: "guilds",
        component: LeaderboardGuilds,
        props: (route) => {
          return {
            weekly: route.query.weekly === "true",
            week: +route.query.week,
            year: +route.query.year,
          };
        },
        name: "leaderboard-guilds",
      },
    ],
  },
  { path: "/statistics", component: Statistics, name: "statistics" },
  {
    path: "/login",
    component: Login,
    name: "login",
    props: (route) => {
      return {
        returnPath: route.query.returnPath,
      };
    },
  },
  { path: "/login-callback", component: LoginCallback, name: "login-callback" },
  { path: "/logout", component: Logout, name: "logout" },
  {
    path: "/my-boosted-servers",
    component: MyBoostedServers,
    name: "my-boosted-servers",
  },
  {
    path: "/my-settings",
    component: MySettings,
    name: "my-settings",
    children: [
      { path: "", redirect: "anime-lists" },
      {
        path: "anime-lists",
        component: MySettingsAnimeLists,
        name: "my-settings-anime-lists",
      },
      {
        path: "privacy",
        component: MySettingsPrivacy,
        name: "my-settings-privacy",
      },
    ],
  },
  { path: "/my-servers", component: MyServers, name: "my-servers" },
  {
    path: "/my-servers/:id",
    component: MyServerDetails,
    props: true,
    name: "my-server-details",
    children: [
      { path: "", redirect: "leaderboard" },
      {
        path: "leaderboard",
        name: "my-server-leaderboard",
        component: MyServerLeaderboard,
        props: true,
      },
      {
        path: "server",
        name: "my-server-configuration",
        component: MyServerConfiguration,
        props: true,
      },
      {
        path: "channels",
        name: "my-server-channel-configuration",
        component: MyServerChannelConfiguration,
        props: true,
      },
      // { path: "players", name: "my-server-players", component: MyServerPlayers, props: true }
    ],
  },
  { path: "/my-profile", component: MyProfile, name: "my-profile" },
  {
    path: "/privacy",
    component: PrivacyPolicy,
    name: "privacy-policy",
  },
  {
    path: "/profile/:id",
    component: Profile,
    props: true,
    name: "profile-details",
    children: [
      { path: "", redirect: "statistics" },
      {
        path: "statistics",
        name: "profile-statistics",
        component: ProfileStatistics,
        props: true,
      },
      {
        path: "achievements",
        name: "profile-achievements",
        component: ProfileAchievements,
        props: true,
      },
    ],
  },
  {
    path: "/guild/:id",
    component: Guild,
    props: true,
    name: "guild-profile",
    children: [
      { path: "", redirect: "statistics" },
      {
        path: "statistics",
        name: "guild-statistics",
        component: GuildStatistics,
        props: true,
      },
    ],
  },
  {
    path: "/status",
    component: Status,
    name: "status",
  },
  {
    path: "/tos",
    component: TermsOfService,
    name: "tos",
  },
];
