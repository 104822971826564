<template>
  <div>
    <div class="row">
      <div class="col-lg-9 offset-lg-3 offset-sm-4">
        <hr class="mb-3" />
        <b-button
          class="mb-2 pull-right"
          variant="dark"
          size="sm"
          @click="setDefaultTitlesAndLanguages"
          >{{
            $t(
              "serverConfiguration.guessTheAnime.titlePreferences.setToDefaults"
            )
          }}</b-button
        >
        <markdown
          :markdown="
            $t('serverConfiguration.guessTheAnime.titlePreferences.description')
          "
        />
      </div>
    </div>

    <!-- Percentage wrong -->
    <b-form-group
      id="fieldset-guess-title-preferences-percentage-wrong"
      label-cols-sm="4"
      label-align-sm="left"
      label-align-md="right"
      label-cols-lg="3"
      :label="
        $t(
          'serverConfiguration.guessTheAnime.titlePreferences.percentageWrong.label'
        )
      "
      label-class="font-weight-bold"
    >
      <div class="ml-3 mr-2">
        {{
          $t(
            "serverConfiguration.guessTheAnime.titlePreferences.percentageWrong.description",
            {
              percentage: parseInt(model.percentageWrong * 100),
            }
          )
        }}
      </div>
      <b-form-input
        class="mt-2 col-lg-6 col-sm-8"
        id="checkbox-group-guess-title-preferences-percentage-wrong"
        v-model="model.percentageWrong"
        @input="$emit('input', model)"
        type="range"
        min="0.00"
        max="0.25"
        step="0.01"
      ></b-form-input>
    </b-form-group>

    <!-- Title simplifications -->
    <b-form-group
      id="fieldset-disable-title-simplifications"
      label-cols-sm="4"
      label-align-sm="left"
      label-align-md="right"
      label-cols-lg="3"
      :label="
        $t(
          'serverConfiguration.guessTheAnime.titlePreferences.disableTitleSimplifications.label'
        )
      "
      label-class="font-weight-bold"
      :description="
        $t(
          'serverConfiguration.guessTheAnime.titlePreferences.disableTitleSimplifications.description'
        )
      "
    >
      <b-form-checkbox
        id="checkbox-disable-title-simplifications"
        v-model="model.disableTitleSimplifications"
        name="checkbox-disable-title-simplifications"
        class="mt-2"
        :disabled="busy"
        >{{
          $t(
            "serverConfiguration.guessTheAnime.titlePreferences.disableTitleSimplifications.checkbox"
          )
        }}</b-form-checkbox
      >
    </b-form-group>

    <!-- Title types -->
    <b-form-group
      v-if="model.types"
      id="fieldset-guess-title-preferences-types"
      label-cols-sm="4"
      label-align-sm="left"
      label-align-md="right"
      label-cols-lg="3"
      :label="
        $t(
          'serverConfiguration.guessTheAnime.titlePreferences.titleTypes.label'
        )
      "
      label-class="font-weight-bold"
    >
      <b-form-checkbox-group
        id="checkbox-group-guess-title-preferences-types"
        v-model="model.types"
        @input="$emit('input', model)"
        :options="titleTypes"
        class="mt-2"
      ></b-form-checkbox-group>
    </b-form-group>

    <!-- Title languages -->
    <b-form-group
      v-if="model.languages"
      id="fieldset-guess-title-preferences-languages"
      label-cols-sm="4"
      label-align-sm="left"
      label-align-md="right"
      label-cols-lg="3"
      :label="
        $t(
          'serverConfiguration.guessTheAnime.titlePreferences.titleLanguages.label'
        )
      "
      label-class="font-weight-bold"
    >
      <div class="row">
        <div
          class="col-lg-4"
          v-for="(part, index) in titleLanguagesParts"
          :key="index"
        >
          <b-form-checkbox-group
            v-model="model.languages"
            @input="$emit('input', model)"
            :options="part"
            stacked
          ></b-form-checkbox-group>
        </div>
        <div class="col-12 mt-2">
          <b-button
            size="sm"
            variant="dark"
            class="mr-1"
            @click="selectAllTitleLanguages"
            ><i class="mr-2 fa fa-check-circle"></i
            >{{
              $t(
                "serverConfiguration.guessTheAnime.titlePreferences.titleLanguages.selectAll"
              )
            }}</b-button
          >
          <b-button size="sm" variant="dark" @click="selectNoTitleLanguages"
            ><i class="mr-2 fa fa-circle-thin"></i
            >{{
              $t(
                "serverConfiguration.guessTheAnime.titlePreferences.titleLanguages.selectNone"
              )
            }}</b-button
          >
        </div>
      </div>
    </b-form-group>

    <!-- Main title preferences -->
    <b-form-group
      v-if="model.mainTitlePreferences"
      id="fieldset-guess-title-preferences-main-title"
      label-cols-sm="4"
      label-align-sm="left"
      label-align-md="left"
      label-align-lg="right"
      label-cols-lg="3"
      :label="
        $t('serverConfiguration.guessTheAnime.titlePreferences.mainTitle.label')
      "
      label-class="font-weight-bold"
    >
      <div class="row">
        <div class="col-12">
          <div class="mt-2 mb-2">
            {{
              $t(
                "serverConfiguration.guessTheAnime.titlePreferences.mainTitle.description"
              )
            }}
          </div>
          <main-title-preferences
            v-model="model.mainTitlePreferences"
            :titleLanguages="titleLanguages"
            :titleTypes="titleTypes"
          />
        </div>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import isoCountriesLanguages from "iso-countries-languages";
import MainTitlePreferences from "./MainTitlePreferences";
import Markdown from "./Markdown";

export default {
  components: {
    MainTitlePreferences,
    Markdown,
  },

  props: ["value", "defaultConfig"],

  data() {
    return {
      model: this.value,
      titleTypes: [
        {
          value: "main",
          text: this.$t(
            "serverConfiguration.guessTheAnime.titlePreferences.titleTypes.main"
          ),
        },
        {
          value: "official",
          text: this.$t(
            "serverConfiguration.guessTheAnime.titlePreferences.titleTypes.official"
          ),
        },
        {
          value: "short",
          text: this.$t(
            "serverConfiguration.guessTheAnime.titlePreferences.titleTypes.short"
          ),
        },
        {
          value: "synonym",
          text: this.$t(
            "serverConfiguration.guessTheAnime.titlePreferences.titleTypes.synonym"
          ),
        },
        {
          value: "card",
          text: this.$t(
            "serverConfiguration.guessTheAnime.titlePreferences.titleTypes.card"
          ),
        },
        {
          value: "kana",
          text: this.$t(
            "serverConfiguration.guessTheAnime.titlePreferences.titleTypes.kana"
          ),
        },
      ],
      titleLanguageCodes: [
        { value: "af" },
        { value: "ar" },
        { value: "bg" },
        { value: "my" },
        { value: "ca" },
        { suffix: "(romanisation)", value: "x-zht", code: "zh" },
        { suffix: "(simplified)", value: "zh-Hans", code: "zh" },
        { suffix: "(traditional)", value: "zh-Hant", code: "zh" },
        { value: "zh" },
        { value: "hr" },
        { value: "cs" },
        { value: "da" },
        { value: "nl" },
        { value: "en" },
        { value: "eo" },
        { value: "et" },
        { text: "Filipino", value: "fil" },
        { value: "fi" },
        { value: "fr" },
        { value: "gl" },
        { value: "de" },
        { value: "el" },
        { value: "he" },
        { value: "hi" },
        { value: "hu" },
        { value: "is" },
        { value: "id" },
        { value: "it" },
        { suffix: "(romanisation)", value: "x-jat", code: "ja" },
        { value: "ja" },
        { suffix: "(romanisation)", value: "x-kot", code: "ko" },
        { value: "ko" },
        { value: "la" },
        { value: "lv" },
        { value: "lt" },
        { value: "mn" },
        { value: "no" },
        { text: "Other (uncategorized)", value: "x-other" },
        { value: "fa" },
        { value: "pl" },
        { value: "pt-BR", suffix: "(Brasil)" },
        { value: "pt" },
        { value: "ro" },
        { value: "ru" },
        { value: "sr" },
        { value: "sk" },
        { value: "sl" },
        { value: "es-LA", suffix: "(Los Angeles)" },
        { value: "es" },
        { value: "sv" },
        { value: "tl" },
        { value: "ta" },
        { suffix: "(romanisation)", value: "x-tht", code: "th" },
        { value: "th" },
        { value: "tr" },
        { value: "uk" },
        { value: "ur" },
        { value: "vi" },
      ],
    };
  },

  computed: {
    titleLanguages() {
      return this.titleLanguageCodes.map((lang) => {
        var text = this.getLanguageTranslation(this.$i18n.locale, lang.value);
        if (!text)
          text = this.getLanguageTranslation(this.$i18n.locale, lang.code);
        if (!text)
          text = this.getLanguageTranslation(
            this.$i18n.locale,
            lang.value.split("-")[0]
          );
        if (!text) text = this.getLanguageTranslation("en", lang.value);
        if (!text) text = this.getLanguageTranslation("en", lang.code);
        if (!text)
          text = this.getLanguageTranslation("en", lang.value.split("-")[0]);

        if (lang.suffix && text) text += " " + lang.suffix;

        return {
          value: lang.value,
          text: lang.text || text,
        };
      });
    },

    titleLanguagesParts() {
      let titleLanguages = this.titleLanguages;
      titleLanguages = titleLanguages.sort((a, b) =>
        a.text?.localeCompare(b.text)
      );

      var parts = [];
      parts.push(titleLanguages.slice(0, 20));
      parts.push(titleLanguages.slice(20, 40));
      parts.push(titleLanguages.slice(40));
      return parts;
    },
  },

  methods: {
    setDefaultTitlesAndLanguages() {
      var defaultConfig = JSON.parse(this.defaultConfig);
      this.model = defaultConfig.guessTheAnime.guessTitlePreferences;
    },

    selectAllTitleLanguages() {
      this.model.languages = this.titleLanguages.map((t) => t.value);
    },

    selectNoTitleLanguages() {
      this.model.languages = [];
    },

    withAnyItem(array) {
      array.push({ value: null, text: "(any)" });
      return array;
    },

    getLanguageTranslation(locale, lang) {
      try {
        return isoCountriesLanguages.getLanguage(locale, lang);
      } catch {
        return undefined;
      }
    },
  },

  watch: {
    value: {
      deep: true,
      handler(newValue) {
        this.model = newValue;
      },
    },
  },
};
</script>
