<template>
  <div v-if="profile">
    <b-card
      :img-src="profile.avatarUrl"
      :img-alt="profile.username"
      img-left
      class="mb-2"
    >
      <div class="d-flex justify-content-left">
        <div class="d-inline">
          <h1 class="d-inline">
            <span v-if="profile.username">
              {{ profile.username }}
            </span>
            <span v-else class="text-muted">{{
              $t("leaderboardTable.hidden")
            }}</span>
            <a
              href="https://www.patreon.com/rinbot?fan_landing=true"
              target="_blank"
            >
              <i
                v-if="profile.isPatron"
                class="fa fa-star"
                style="color: orange"
                v-b-tooltip.hover
                :title="$root.config.botName + ' supporter'"
              ></i
            ></a>
          </h1>
          <h5>
            <b-badge
              v-if="profile.isPatron"
              class="mr-2"
              style="background-color: orange"
              v-b-tooltip.hover
              :title="$t('common.botSupporter', $root.config)"
              ><i class="fa fa-star mr-2"></i
              >{{ $t("common.botSupporter", $root.config) }}</b-badge
            >
            <b-badge
              class="mr-2"
              variant="success"
              v-b-tooltip.hover
              :title="$t('userProfile.roundPoints')"
              ><i class="fa fa-star mr-2"></i>{{ profile.points }}</b-badge
            >
            <b-badge
              class="mr-2"
              variant="info"
              v-b-tooltip.hover
              :title="$t('userProfile.achievementPoints')"
              ><i class="fa fa-trophy mr-2"></i
              >{{ profile.achievementPoints }}</b-badge
            >
          </h5>
        </div>
      </div>
    </b-card>

    <b-card no-body>
      <b-card-header header-tag="nav">
        <b-nav card-header pills>
          <b-nav-item
            :to="{
              name: 'profile-statistics',
              params: { id },
            }"
            active-class="active"
            ><i class="fa fa-pie-chart mr-2"></i
            >{{ $t("userProfile.statistics") }}</b-nav-item
          >
          <b-nav-item
            :to="{
              name: 'profile-achievements',
              params: { id },
            }"
            active-class="active"
            ><i class="fa fa-trophy mr-2"></i
            >{{ $t("userProfile.achievements") }}</b-nav-item
          >
        </b-nav>
      </b-card-header>
      <b-card-body>
        <router-view :profile="profile"></router-view>
      </b-card-body>
    </b-card>
  </div>
  <Loading v-else />
</template>

<script>
import Loading from "@/components/Loading";

export default {
  components: {
    Loading,
  },

  props: ["id"],

  data() {
    return {
      profile: undefined,
    };
  },

  async created() {
    const response = await this.axios.get(`/api/profile/${this.id}`);
    this.profile = response.data;
  },
};
</script>

<style scoped>
.card-body {
  width: calc(100% - 128px);
}

.card-img-left {
  width: 128px;
  height: 128px;
}
</style>
