<template>
  <div v-if="model">
    <b-alert
      variant="warning"
      fade
      :show="model.length === maxWinnerRoleConfigs()"
    >
      <i class="fa fa-info-circle mr-2"></i
      >{{
        $t(
          "serverConfiguration.guessTheAnime.winnerRoleConfigs.reachedMaximumAssignments",
          { maxWinnerRoleConfigs: maxWinnerRoleConfigs() }
        )
      }}
      <div class="mt-2" v-if="guild.patronTier < 1">
        <i class="fa fa-star mr-1" style="color: orange" /><Markdown
          :markdown="
            $t(
              'serverConfiguration.guessTheAnime.winnerRoleConfigs.boostedServersGetMoreAssignments',
              {
                patreonLink,
                patronWinnerRoleConfigs,
              }
            )
          "
        />
      </div>
    </b-alert>
    <div>
      <b-button
        class="mb-2"
        @click="add()"
        size="sm"
        variant="primary"
        :disabled="model.length >= maxWinnerRoleConfigs()"
        ><i class="fa fa-plus mr-2"></i
        >{{
          $t("serverConfiguration.guessTheAnime.winnerRoleConfigs.add")
        }}</b-button
      >
    </div>
    <b-table striped small responsive :fields="fields" :items="model">
      <template #cell(roleId)="data">
        <b-form-select
          v-model="data.item.roleId"
          :options="roles"
          value-field="id"
          text-field="name"
          @input="$emit('input', model)"
        ></b-form-select>
      </template>
      <template #cell(points)="data">
        <b-form-input
          v-model="data.item.points"
          type="number"
          min="1"
          step="1"
          @input="$emit('input', model)"
        ></b-form-input>
      </template>
      <template #cell(actionsAfter)="data">
        <b-button @click="remove(data)" variant="danger" size="sm"
          ><i class="fa fa-trash"></i
        ></b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import Markdown from "./Markdown";

export default {
  components: { Markdown },

  props: ["guild", "roles", "value"],

  data() {
    return {
      model: this.value || [],
      patreonLink: this.$root.config.patreonUrl,
      patronWinnerRoleConfigs: 10,
      fields: [
        {
          key: "roleId",
          label: this.$t(
            "serverConfiguration.guessTheAnime.winnerRoleConfigs.role"
          ),
          thStyle: "min-width: 150px",
        },
        {
          key: "points",
          label: "Points", // TODO: $t
          thStyle: "min-width: 100px",
        },
        { key: "actionsAfter", label: "", thStyle: "width: 45px" },
      ],
    };
  },

  methods: {
    add() {
      if (this.model.length < this.maxWinnerRoleConfigs()) {
        this.model.push({
          roleId: null,
          type: 0,
          rank: 1,
          isWeekly: false,
        });
        this.$emit("input", this.model);
      }
    },

    remove(data) {
      this.model.splice(data.index, 1);
      this.$emit("input", this.model);
    },

    maxWinnerRoleConfigs() {
      return this.guild.patronTier < 1 ? 1 : 10;
    },
  },
};
</script>
