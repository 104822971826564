<template>
  <div v-if="$root.user">
    <Loading v-if="loading" />
    <div v-else>
      <div v-if="error" class="alert alert-warning">{{ error }}</div>
      <div v-if="guilds">
        <div class="jumbotron pt-3 pb-1 mb-4 bg-light">
          <h1>{{ $t("myServers.title") }}</h1>

          <div class="row mb-3">
            <div class="col-md-12">
              <Markdown
                :markdown="
                  $t('myServers.description', { botName: $root.config.botName })
                "
              />
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 col-sm-12">
              <b-input-group class="mb-3">
                <b-input-group-prepend is-text>
                  <b-icon icon="search" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="search"
                  :placeholder="$t('myServers.searchForServer')"
                ></b-form-input>
              </b-input-group>
            </div>
            <div class="col-md-6 col-sm-12 text-right">
              <b-form-checkbox v-model="showAll" switch size="lg">
                <small>{{ $t("myServers.showAll") }}</small>
              </b-form-checkbox>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="mb-3 col-lg-6"
            v-for="(guild, index) in filteredGuilds"
            :key="index"
          >
            <b-card
              :title="guild.name"
              :img-src="guild.iconUrl"
              :img-alt="guild.name"
              img-left
              class="mb-2"
            >
              <div v-if="guild.hasBot">
                <div class="pull-right">
                  <b-button :to="'/my-servers/' + guild.id" variant="dark">
                    <span v-if="guild.canManage"
                      ><i class="fa fa-cog"></i
                      ><span class="ml-2 d-none d-sm-inline">{{
                        $t("myServers.configure")
                      }}</span></span
                    >
                    <span v-else
                      ><i class="fa fa-eye"></i
                      ><span class="ml-2 d-none d-sm-inline">{{
                        $t("myServers.details")
                      }}</span></span
                    >
                  </b-button>
                </div>
                <div>
                  <div>
                    <i class="fa fa-check text-success mr-2"></i
                    >{{
                      $t("myServers.hasBot", { botName: $root.config.botName })
                    }}
                  </div>
                  <div v-if="guild.patronTier > 0">
                    <i class="fa fa-star mr-2" style="color: orange"></i
                    >{{ $t("myServers.boostedServer") }}
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="pull-right" v-if="guild.canManage">
                  <b-button
                    :href="
                      'https://discordapp.com/oauth2/authorize?client_id=' +
                      $root.clientId +
                      '&scope=bot%20applications.commands' +
                      '&permissions=3198016' +
                      '&disable_guild_select=true' +
                      '&guild_id=' +
                      guild.id.toString()
                    "
                    target="_blank"
                    variant="success"
                  >
                    <i class="fa fa-plus"></i
                    ><span class="ml-2 d-none d-sm-inline">{{
                      $t("myServers.addBot", { botName: $root.config.botName })
                    }}</span>
                  </b-button>
                </div>
                <span>
                  <i class="fa fa-warning text-danger mr-2"></i
                  >{{
                    $t("myServers.notHasBot", { botName: $root.config.botName })
                  }}
                </span>
              </div>
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../components/Loading";
import Markdown from "../components/Markdown";

export default {
  components: {
    Loading,
    Markdown,
  },

  computed: {
    filteredGuilds: function () {
      let guilds = this.guilds;

      if (!this.showAll) {
        guilds = guilds.filter((g) => g.canManage || g.hasBot);
      }

      if (this.search) {
        var self = this;
        guilds = guilds.filter(function (g) {
          return g.name.toLowerCase().indexOf(self.search.toLowerCase()) >= 0;
        });
      }

      return guilds;
    },
  },

  data() {
    return {
      guilds: null,
      error: null,
      loading: true,
      search: window.sessionStorage.getItem("myServers.search"),
      showAll: window.sessionStorage.getItem("myServers.showAll") == "true",
    };
  },

  watch: {
    search: async function () {
      window.sessionStorage.setItem("myServers.search", this.search);
    },
    showAll: async function () {
      window.sessionStorage.setItem("myServers.showAll", this.showAll);
    },
  },

  async created() {
    if (!this.$root.user) {
      this.$router.push({
        path: "/login",
        query: { returnPath: this.$route.path },
      });
    }

    try {
      if (!this.$root.guilds) {
        const guildListResponse = await this.axios.get(`/api/user/guilds`);
        this.guilds = guildListResponse.data;
        this.$root.guilds = this.guilds;
      } else {
        this.guilds = this.$root.guilds;
      }
    } catch (e) {
      this.error = this.$t("myServers.couldNotRetrieveServers");
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style scoped>
strong {
  text-decoration: underline;
}

.card-body {
  width: calc(100% - 128px);
}

.card-title {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.card-img-left {
  width: 128px;
  height: 128px;
}
</style>
