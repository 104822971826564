<template>
  <div>
    <b-row>
      <b-col md="6" lg="3">
        <b-form-group>
          <b-input-group prepend="Year" class="mb-2">
            <b-form-input
              type="number"
              v-model="yearStartComputed"
              lazy
            ></b-form-input>
            <b-form-input
              type="number"
              v-model="yearEndComputed"
              lazy
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="0" lg="3"></b-col>
      <b-col md="0" lg="3"></b-col>
      <b-col md="6" lg="3">
        <b-skeleton
          width="100%"
          height="40px"
          v-if="$apollo.queries.animeSorts.loading"
        ></b-skeleton>
        <b-form-select
          v-else
          v-model="selectedSortBy"
          :options="animeSortOptions"
        ></b-form-select>
      </b-col>
      <b-col md="6" lg="3" class="mb-3">
        <b-skeleton
          width="100%"
          height="40px"
          v-if="$apollo.queries.difficultyEnums.loading"
        ></b-skeleton>
        <b-form-group v-else>
          <multiselect
            placeholder="Difficulty"
            v-model="selectedDifficulties"
            :options="difficultyEnums || []"
            @input="onDifficultiesChanged"
            :custom-label="difficultyLabel"
            :close-on-select="false"
            :multiple="true"
            :searchable="false"
          ></multiselect>
        </b-form-group>
      </b-col>
      <b-col md="6" lg="3" class="mb-3">
        <b-skeleton
          width="100%"
          height="40px"
          v-if="$apollo.queries.nsfwEnums.loading"
        ></b-skeleton>
        <b-form-group v-else>
          <multiselect
            placeholder="NSFW"
            v-model="selectedNsfw"
            :options="nsfwEnums || []"
            @input="onNsfwChanged"
            :custom-label="nsfwLabel"
            :close-on-select="false"
            :multiple="true"
            :searchable="false"
          ></multiselect>
        </b-form-group>
      </b-col>
      <b-col md="6" lg="3" class="mb-3">
        <b-skeleton
          width="100%"
          height="40px"
          v-if="$apollo.queries.animeGenres.loading"
        ></b-skeleton>
        <b-form-group v-else>
          <multiselect
            placeholder="Genres"
            v-model="selectedGenres"
            :options="animeGenres"
            :custom-label="genreLabel"
            :close-on-select="false"
            :multiple="true"
            :searchable="true"
          ></multiselect>
        </b-form-group>
      </b-col>
      <b-col md="6" lg="3">
        <b-button
          v-if="!$root.user"
          class="pull-right"
          variant="light"
          to="/my-settings"
          ><i class="fa fa-fw fa-plus mr-2"></i>Add your anime list</b-button
        >
        <b-skeleton
          width="100%"
          height="40px"
          v-else-if="$root.user && $apollo.queries.userAnimeLists.loading"
        ></b-skeleton>
        <b-form-group v-else>
          <multiselect
            placeholder="Anime lists"
            v-model="selectedLists"
            :disabled="!$root.user"
            :options="userAnimeLists || []"
            :custom-label="userAnimeListLabel"
            :close-on-select="true"
            :multiple="true"
            selectLabel=""
            deselectLabel=""
            track-by="id"
          ></multiselect>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-pagination
          v-if="animeCollection && animeCollection.pagination.pages > 1"
          v-model="currentPage"
          :total-rows="animeCollection.pagination.total"
          :per-page="currentPageSize"
          pills
          align="center"
          last-number
          first-number
          limit="5"
        ></b-pagination>
      </b-col>
    </b-row>
    <b-row v-if="$apollo.queries.animeCollection.loading">
      <b-col class="mb-3" sm="6" md="4" lg="3" xl="3">
        <b-skeleton height="440px" width="100%"></b-skeleton>
      </b-col>
      <b-col sm="6" md="4" lg="3" xl="3">
        <b-skeleton height="440px" width="100%"></b-skeleton>
      </b-col>
      <b-col sm="6" md="4" lg="3" xl="3">
        <b-skeleton height="440px" width="100%"></b-skeleton>
      </b-col>
      <b-col sm="6" md="4" lg="3" xl="3">
        <b-skeleton height="440px" width="100%"></b-skeleton>
      </b-col>
      <b-col sm="6" md="4" lg="3" xl="3">
        <b-skeleton height="440px" width="100%"></b-skeleton>
      </b-col>
      <b-col sm="6" md="4" lg="3" xl="3">
        <b-skeleton height="440px" width="100%"></b-skeleton>
      </b-col>
      <b-col sm="6" md="4" lg="3" xl="3">
        <b-skeleton height="440px" width="100%"></b-skeleton>
      </b-col>
      <b-col sm="6" md="4" lg="3" xl="3">
        <b-skeleton height="440px" width="100%"></b-skeleton>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col v-if="animeCollection.items.length === 0" offset-md="2" md="8">
        <b-alert variant="warning" show
          ><i class="fa fa-exclamation-triangle mr-2"></i>No anime found
          matching your filters.</b-alert
        >
      </b-col>
      <b-col
        class="mb-3"
        v-for="anime in animeCollection.items"
        :key="anime.id"
        sm="6"
        md="4"
        lg="3"
        xl="3"
      >
        <b-card
          bg-variant="primary"
          text-variant="white"
          no-body
          header-class="anime-card-header"
          footer-class="anime-card-footer"
        >
          <router-link :to="`/database/anime/${anime.id}`">
            <AnimePoster :anime="anime" />
          </router-link>
          <template #header>
            <router-link
              class="anime-card-title"
              :to="`/database/anime/${anime.id}`"
            >
              <small
                style="
                  display: block;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
                >{{ anime.mainTitle }}</small
              >
            </router-link>
          </template>
          <template #footer>
            <b-badge class="mr-1" variant="dark">{{
              anime.startDate.year
            }}</b-badge>
            <difficulty-badge :difficulty="anime.difficulty"></difficulty-badge>
            <nsfw-badge :nsfw="anime.nsfw"></nsfw-badge>
          </template>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="animeCollection && animeCollection.pagination.pages > 1">
      <b-col>
        <b-pagination
          v-if="animeCollection && animeCollection.pagination.pages > 1"
          v-model="currentPage"
          :total-rows="animeCollection.pagination.total"
          :per-page="currentPageSize"
          pills
          align="center"
          last-number
          first-number
          limit="5"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import gql from "graphql-tag";
import infiniteScroll from "vue-infinite-scroll";
import Multiselect from "vue-multiselect";

import AnimePoster from "@/components/AnimePoster";
import NsfwBadge from "@/components/NsfwBadge.vue";
import DifficultyBadge from "@/components/DifficultyBadge.vue";

const ANIME_COLLECTION_SEARCH = gql`
  query (
    $title: String
    $difficulties: [Difficulty!]
    $nsfw: [Nsfw!]
    $page: Int!
    $pageSize: Int!
    $lists: [ID!]
    $genres: [AnimeGenre!]
    $captureTypes: [AnimeCaptureType!]
    $yearStart: Int
    $yearEnd: Int
    $sortBy: AnimeSort!
  ) {
    animeCollection(
      title: $title
      captureTypes: $captureTypes
      difficulties: $difficulties
      nsfw: $nsfw
      page: $page
      pageSize: $pageSize
      lists: $lists
      genres: $genres
      yearStart: $yearStart
      yearEnd: $yearEnd
      sortBy: $sortBy
    ) {
      pagination {
        page
        pageSize
        pages
        total
      }
      items {
        id
        difficulty
        nsfw
        startDate {
          year
        }
        mainTitle
        posters {
          source
          url
        }
      }
    }
  }
`;

const DIFFICULTY_ENUMS = gql`
  query {
    difficultyEnums: __type(name: "Difficulty") {
      enumValues {
        name
      }
    }
  }
`;

const NSFW_ENUMS = gql`
  query {
    nsfwEnums: __type(name: "Nsfw") {
      enumValues {
        name
      }
    }
  }
`;

const ANIME_GENRE_ENUMS = gql`
  query {
    animeGenres: __type(name: "AnimeGenre") {
      enumValues {
        name
      }
    }
  }
`;

const ANIME_SORT_ENUMS = gql`
  query {
    animeSorts: __type(name: "AnimeSort") {
      enumValues {
        name
      }
    }
  }
`;

const USER_ANIME_LISTS = gql`
  query {
    user {
      id
      animeLists {
        id
        animeListType
        user
        size
      }
    }
  }
`;

export default {
  props: [
    "title",
    "difficulties",
    "nsfw",
    "page",
    "pageSize",
    "lists",
    "genres",
    "yearStart",
    "yearEnd",
    "sortBy",
  ],
  directives: { infiniteScroll },
  components: { AnimePoster, Multiselect, NsfwBadge, DifficultyBadge },
  apollo: {
    animeCollection: {
      query: ANIME_COLLECTION_SEARCH,
      variables() {
        return {
          title: this.title,
          difficulties: this.selectedDifficulties,
          nsfw: this.selectedNsfw,
          page: this.currentPage,
          pageSize: this.currentPageSize,
          myListOnly: this.selectedMyListOnly,
          genres: this.selectedGenres,
          lists: this.selectedLists.map((l) => l.id),
          yearStart: this.yearStartComputed,
          yearEnd: this.yearEndComputed,
          captureTypes: this.selectedCaptureTypes,
          sortBy: this.selectedSortBy,
        };
      },
    },
    difficultyEnums: {
      query: DIFFICULTY_ENUMS,
      update: (data) =>
        data.difficultyEnums.enumValues
          .map((v) => v.name)
          .filter((d) => d != "UNKNOWN"),
    },
    nsfwEnums: {
      query: NSFW_ENUMS,
      update: (data) =>
        data.nsfwEnums.enumValues
          .map((v) => v.name)
          .filter((d) => d != "UNKNOWN"),
    },
    animeGenres: {
      query: ANIME_GENRE_ENUMS,
      update: (data) =>
        data.animeGenres.enumValues
          .map((v) => v.name)
          .filter((d) => d != "UNKNOWN")
          .sort(),
    },
    animeSorts: {
      query: ANIME_SORT_ENUMS,
      update: (data) =>
        data.animeSorts.enumValues
          .map((v) => v.name)
          .filter((d) => d != "UNKNOWN")
          .sort(),
    },
    userAnimeLists: {
      query: USER_ANIME_LISTS,
      update: (data) => data.user.animeLists.sort(),
      skip() {
        return !this.$root.user;
      },
    },
  },
  computed: {
    selectedLists: {
      get() {
        if (!this.lists || !this.userAnimeLists) return [];
        const lists = JSON.parse(this.lists);
        return this.userAnimeLists.filter((a) =>
          lists.some((id) => id == a.id)
        );
      },
      set(lists) {
        const query = Object.assign({}, this.$route.query);
        query.lists = JSON.stringify(lists.map((l) => l.id));
        window.savedPosition = {
          offset: { y: window.scrollY, x: window.scrollX },
        };
        this.$router.push({ query: query }).catch(() => {});
      },
    },
    selectedGenres: {
      get() {
        if (!this.genres) return [];
        if (this.genres instanceof Array) return this.genres;
        return JSON.parse(this.genres);
      },
      set(genres) {
        const query = Object.assign({}, this.$route.query);
        query.genres = JSON.stringify(genres);
        window.savedPosition = {
          offset: { y: window.scrollY, x: window.scrollX },
        };
        this.$router.push({ query: query }).catch(() => {});
      },
    },
    currentPageSize: {
      get() {
        return this.pageSize || 8;
      },
      set(pageSize) {
        const query = Object.assign({}, this.$route.query);
        query.pageSize = pageSize;
        window.savedPosition = {
          offset: { y: window.scrollY, x: window.scrollX },
        };
        this.$router.push({ query: query }).catch(() => {});
      },
    },
    currentPage: {
      get() {
        return this.page || 1;
      },
      set(page) {
        const query = Object.assign({}, this.$route.query);
        query.page = page;
        window.savedPosition = {
          offset: { y: window.scrollY, x: window.scrollX },
        };
        this.$router.push({ query: query }).catch(() => {});
      },
    },
    yearStartComputed: {
      get() {
        return this.yearStart ? +this.yearStart : undefined;
      },
      set(yearStart) {
        const query = Object.assign({}, this.$route.query);
        query.yearStart = yearStart;
        window.savedPosition = {
          offset: { y: window.scrollY, x: window.scrollX },
        };
        this.$router.push({ query: query }).catch(() => {});
      },
    },
    yearEndComputed: {
      get() {
        return this.yearEnd ? +this.yearEnd : undefined;
      },
      set(yearEnd) {
        const query = Object.assign({}, this.$route.query);
        query.yearEnd = yearEnd;
        window.savedPosition = {
          offset: { y: window.scrollY, x: window.scrollX },
        };
        this.$router.push({ query: query }).catch(() => {});
      },
    },
    animeSortOptions() {
      return this.animeSorts.map((sort) => {
        return {
          text: this.$t(`animeSorts.${sort}`),
          value: sort,
        };
      });
    },
    selectedSortBy: {
      get() {
        if (!this.sortBy) return "YEAR_DESC";
        return this.sortBy;
      },
      set(sortBy) {
        const query = Object.assign({}, this.$route.query);
        query.sortBy = sortBy;
        window.savedPosition = {
          offset: { y: window.scrollY, x: window.scrollX },
        };
        this.$router.push({ query: query }).catch(() => {});
      },
    },
  },
  data() {
    return {
      loaded: false,
      selectedDifficulties: [],
      selectedNsfw: [],
      selectedCaptureTypes: ["IMAGE", "VIDEO", "AUDIO", "VOICE"],
    };
  },
  mounted() {
    this.selectedDifficulties = this.difficulties
      ? JSON.parse(this.difficulties)
      : this.difficultyEnums;
    this.selectedNsfw = this.nsfw ? JSON.parse(this.nsfw) : this.nsfwEnums;
    this.loaded = true;
  },
  methods: {
    userAnimeListLabel({ animeListType, user, size }) {
      return `${this.$t(`animeListTypes.${animeListType}`)}: ${user} (${size})`;
    },
    difficultyLabel(difficulty) {
      return this.$t(`difficulties.${difficulty}`);
    },
    nsfwLabel(nsfw) {
      return this.$t(`nsfw.${nsfw}`);
    },
    genreLabel(genre) {
      return this.$t(`genres.${genre}`);
    },
    onDifficultiesChanged(difficulties) {
      const query = Object.assign({}, this.$route.query);
      query.difficulties = JSON.stringify(difficulties);
      window.savedPosition = {
        offset: { y: window.scrollY, x: window.scrollX },
      };
      this.$router.push({ query: query }).catch(() => {});
    },
    onNsfwChanged(nsfw) {
      const query = Object.assign({}, this.$route.query);
      query.nsfw = JSON.stringify(nsfw);
      window.savedPosition = {
        offset: { y: window.scrollY, x: window.scrollX },
      };
      this.$router.push({ query: query }).catch(() => {});
    },
  },
};
</script>

<style scoped>
.anime-card-title {
  color: silver;
  text-decoration: none;
}

.anime-card-header {
  padding: 0.5em;
  text-align: center;
}

.anime-card-footer {
  padding: 0.25em;
  text-align: center;
}
</style>

<style>
.multiselect {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 2px solid #333;
  border-radius: 255px 25px 225px 25px/25px 225px 25px 255px;
}
</style>
