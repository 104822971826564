<template>
  <b-row>
    <b-col>
      <h4>{{ $t("channelAccessControlConfig.title") }}</h4>
      <Markdown
        :markdown="$t('channelAccessControlConfig.description')"
        :block="true"
      />
      <p>
        <b-button @click="add()" variant="success" size="sm"
          ><i class="fa fa-plus mr-2"></i
          >{{ $t("channelAccessControlConfig.addRule") }}</b-button
        >
      </p>
      <b-alert :show="items.length >= 10" variant="warning"
        ><i class="fa fa-exclamation-triangle mr-2"></i
        >{{ $t("channelAccessControlConfig.maximumReached") }}</b-alert
      >
      <b-table striped small responsive :fields="fields" :items="items">
        <template #cell(allowed)="data">
          <b-button-group>
            <b-button
              v-if="data.item.allow"
              variant="success"
              @click="toggle(data)"
              size="sm"
              ><span style="white-space: nowrap"
                ><i class="fa fa-check mr-1"></i>Allow</span
              ></b-button
            >
            <b-button
              v-if="data.item.deny"
              variant="danger"
              @click="toggle(data)"
              size="sm"
              ><span style="white-space: nowrap"
                ><i class="fa fa-ban mr-1"></i>Deny</span
              ></b-button
            >
          </b-button-group>
        </template>
        <template #cell(commands)="data">
          <Select2
            v-model="data.item.commands"
            :options="commandOptions"
            :settings="{
              multiple: true,
              placeholder: $t('channelAccessControlConfig.allCommands'),
              width: '100%',
            }"
          />
        </template>
        <template #cell(roleIds)="data">
          <Select2
            v-model="data.item.roleIds"
            :options="roleOptions"
            :settings="{
              multiple: true,
              placeholder: $t('channelAccessControlConfig.everyoneRole'),
              width: '100%',
            }"
          />
        </template>
        <template #cell(actionsAfter)="data">
          <b-button-group class="mr-3">
            <b-button variant="light" size="sm" @click="down(data)"
              ><i class="fa fa-caret-down"></i
            ></b-button>
            <b-button variant="light" size="sm" @click="up(data)"
              ><i class="fa fa-caret-up"></i
            ></b-button>
          </b-button-group>
          <b-button @click="remove(data)" variant="danger" size="sm"
            ><i class="fa fa-trash"></i
          ></b-button>
        </template>
      </b-table>
    </b-col>
  </b-row>
</template>

<script>
import Markdown from "./Markdown.vue";
import Select2 from "v-select2-component";
import "array.prototype.move";

export default {
  props: ["accessControls", "roles", "commands"],

  components: {
    Markdown,
    Select2,
  },

  methods: {
    remove(data) {
      this.items.splice(data.index, 1);
    },

    add() {
      if (this.items.length >= 10) return;

      this.items.push({
        commands: [],
        roleIds: [],
        allow: true,
        deny: false,
      });
    },

    up(data) {
      if (data.index === 0) return;
      this.items.move(data.index, data.index - 1);
    },

    down(data) {
      if (data.index === this.items.length) return;
      this.items.move(data.index, data.index + 1);
    },

    toggle(data) {
      if (data.item.allow) {
        data.item.deny = true;
        data.item.allow = false;
      } else {
        data.item.deny = false;
        data.item.allow = true;
      }
    },

    isAllCommands(item) {
      return (
        item.commands === null ||
        item.commands === undefined ||
        item.commands.length === 0
      );
    },

    isEveryoneRole(item) {
      return (
        item.roleIds === null ||
        item.roleIds === undefined ||
        item.roleIds.length === 0
      );
    },

    getRoleName(roleId) {
      return this.roles.filter((r) => r.id == roleId)[0].name;
    },

    removeCommand(item, command) {
      item.commands.splice(item.commands.indexOf(command), 1);
    },

    removeRole(item, roleId) {
      item.roleIds.splice(item.roleIds.indexOf(roleId), 1);
    },
  },

  data() {
    return {
      roleOptions: [],
      commandOptions: [],
      items: [],
      fields: [
        { key: "allowed", label: "", thStyle: "width: 55px" },
        {
          key: "commands",
          label: this.$t("channelAccessControlConfig.commands"),
          thStyle: "min-width: 150px",
        },
        {
          key: "roleIds",
          label: this.$t("channelAccessControlConfig.roles"),
          thStyle: "min-width: 150px",
        },
        { key: "actionsAfter", label: "", thStyle: "width: 120px" },
      ],
    };
  },

  mounted() {
    this.items = this.accessControls;
    this.commandOptions = this.commands;
    this.roleOptions = this.roles.map((role) => {
      return { id: role.id, text: role.name };
    });
  },
};
</script>

<style scoped>
td > span {
  display: inline;
}

.badge > a {
  color: #ddd;
}
</style>
