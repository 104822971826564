<template>
  <b-card-img-lazy
    class="rounded-0"
    :src="getPosterUrl(anime)"
  ></b-card-img-lazy>
</template>

<script>
import * as _ from "lodash";

export default {
  props: ["anime"],
  methods: {
    getPosterUrl(anime) {
      const posters = _.orderBy(
        anime.posters.map((p) => {
          if (p.source == "ANI_LIST") p.order = 1;
          else if (p.source == "MY_ANIME_LIST") p.order = 2;
          else p.order = 3;
          return p;
        }),
        "order",
        "asc"
      );

      return posters && posters.length > 0 ? posters[0].url : null;
    },
  },
};
</script>
