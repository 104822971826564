var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jumbotron pb-0 bg-light"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-7"},[_c('h1',{staticClass:"display-4"},[_vm._v(" "+_vm._s(_vm.$t("introduction.title", _vm.$root.config))),_c('i',{staticClass:"fa fa-star ml-2",staticStyle:{"color":"gold"}})]),_c('p',{staticClass:"lead"},[_c('Markdown',{attrs:{"markdown":_vm.$t('introduction.shortDescription', {
              botName: _vm.$root.config.botName,
            })}})],1),_c('div',[_c('a',{staticClass:"btn btn-lg btn-success",attrs:{"href":'https://discordapp.com/oauth2/authorize?client_id=' +
            _vm.$root.config.botId +
            '&scope=bot%20applications.commands&permissions=3198016',"target":"_blank"}},[_c('i',{staticClass:"fa fa-plus mr-2"}),_vm._v(" "+_vm._s(_vm.$t("introduction.inviteButton", _vm.$root.config))+" ")])]),_c('p',{staticClass:"mt-2"},[_vm._v(" . . . "+_vm._s(_vm.$t("introduction.orSeeHerInAction"))+" "),_c('a',{attrs:{"href":"https://discord.com/invite/3CTq3PH","target":"_blank"}},[_vm._v(_vm._s(_vm.$t("introduction.supportServer", _vm.$root.config)))]),_vm._v(". ")]),_c('hr',{staticClass:"my-3"}),_c('Markdown',{attrs:{"markdown":_vm.$t('introduction.features')}}),_c('p',{domProps:{"innerHTML":_vm._s(
          _vm.$t('introduction.checkTheCommandListAndConfigure', _vm.$root.config)
        )}})],1),_c('div',{staticClass:"col-lg-5"},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.$root.config.botName + '-logo.jpg',"alt":""}}),(_vm.$root.config.botName == 'RinBot')?_c('small',{staticClass:"pull-right"},[_vm._v(" Artwork by "),_c('a',{attrs:{"href":"https://www.pixiv.net/en/artworks/36910527","target":"_blank"}},[_vm._v("momoco")])]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }