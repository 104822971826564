<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h2>{{ $t("leaderboard.overview.lastWeekWinners") }}</h2>
      </div>
      <div class="col-lg-6 mb-2 leaderboard-table left-col">
        <div>
          <LeaderboardTable
            leaderboardType="players"
            :year="lastWeekYear"
            :week="lastWeek"
            :take="3"
            :trophies="true"
          />
          <b-button
            class="pull-right"
            size="sm"
            variant="light"
            :to="
              `/leaderboard/players?weekly=true&year=${lastWeekYear}&week=${lastWeek}`
            "
            >{{ $t("leaderboard.overview.showMore")
            }}<i class="ml-2 fa fa-external-link"></i
          ></b-button>
        </div>
      </div>
      <div class="col-lg-6 mb-2 leaderboard-table">
        <div>
          <LeaderboardTable
            leaderboardType="guilds"
            :year="lastWeekYear"
            :week="lastWeek"
            :take="3"
            :trophies="true"
          />
          <small>{{ $t("leaderboard.overview.guildsHiddenByDefault") }}</small>
          <b-button
            class="pull-right"
            size="sm"
            variant="light"
            :to="
              `/leaderboard/guilds?weekly=true&year=${lastWeekYear}&week=${lastWeek}`
            "
            >{{ $t("leaderboard.overview.showMore")
            }}<i class="ml-2 fa fa-external-link"></i
          ></b-button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h2>{{ $t("leaderboard.overview.thisWeek") }}</h2>
        <p>{{ $t("leaderboard.overview.thisWeekEndsIn", { timeLeft: thisWeekTimeLeft })}}</p>
      </div>
      <div class="col-lg-6 mb-2 leaderboard-table left-col">
        <div>
          <LeaderboardTable
            leaderboardType="players"
            :year="thisWeekYear"
            :week="thisWeek"
            :take="10"
          />
          <b-button
            class="pull-right"
            size="sm"
            variant="light"
            :to="
              `/leaderboard/players?weekly=true&year=${thisWeekYear}&week=${thisWeek}`
            "
            >{{ $t("leaderboard.overview.showMore")
            }}<i class="ml-2 fa fa-external-link"></i
          ></b-button>
        </div>
      </div>
      <div class="col-lg-6 mb-2 leaderboard-table">
        <div>
          <LeaderboardTable
            leaderboardType="guilds"
            :year="thisWeekYear"
            :week="thisWeek"
            :take="10"
          />
          <small>{{ $t("leaderboard.overview.guildsHiddenByDefault") }}</small>
          <b-button
            class="pull-right"
            size="sm"
            variant="light"
            :to="
              `/leaderboard/guilds?weekly=true&year=${thisWeekYear}&week=${thisWeek}`
            "
            >{{ $t("leaderboard.overview.showMore")
            }}<i class="ml-2 fa fa-external-link"></i
          ></b-button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12"><h2>{{ $t("leaderboard.overview.allTime") }}</h2></div>
      <div class="col-lg-6 mb-2 leaderboard-table left-col">
        <div>
          <LeaderboardTable leaderboardType="players" :take="10" />
          <b-button
            class="pull-right"
            size="sm"
            variant="light"
            :to="`/leaderboard/players?weekly=false`"
            >{{ $t("leaderboard.overview.showMore")
            }}<i class="ml-2 fa fa-external-link"></i
          ></b-button>
        </div>
      </div>
      <div class="col-lg-6 mb-2 leaderboard-table">
        <div>
          <LeaderboardTable leaderboardType="guilds" :take="10" />
          <small>{{ $t("leaderboard.overview.guildsHiddenByDefault") }}</small>
          <b-button
            class="pull-right"
            size="sm"
            variant="light"
            :to="`/leaderboard/guilds?weekly=false`"
            >{{ $t("leaderboard.overview.showMore")
            }}<i class="ml-2 fa fa-external-link"></i
          ></b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeaderboardTable from "../components/LeaderboardTable.vue";
import moment from "moment";

export default {
  components: { LeaderboardTable },

  computed: {
    thisWeek() {
      return moment.utc().isoWeek();
    },
    thisWeekYear() {
      return moment.utc().isoWeekYear();
    },
    thisWeekEnds() {
      return moment
        .utc()
        .isoWeekday(7)
        .add(1, "d")
        .startOf("d")
        .format("D MMMM");
    },
    thisWeekTimeLeft() {
      return moment
        .utc()
        .isoWeekday(7)
        .add(1, "d")
        .startOf("d")
        .fromNow();
    },
    lastWeek() {
      return moment
        .utc()
        .subtract(1, "weeks")
        .isoWeek();
    },
    lastWeekYear() {
      return moment
        .utc()
        .subtract(1, "weeks")
        .isoWeekYear();
    }
  },
};
</script>
<style scoped>

.leaderboard-table {
  border-bottom: 2px solid silver;
}

.left-col {
  border-right: 2px solid #eeeeee;
}

</style>