<template>
  <div v-if="profile">
    <div class="row">
      <div class="col-12">
        <h3>{{ $t("userProfile.allTimeResults") }}</h3>
      </div>
    </div>
    <div class="row" v-if="allTimePointRanking">
      <div class="col-md-4 text-center">
        <strong>{{ $t("userProfile.rank") }}</strong>
        <div class="display-4">
          {{ allTimePointRanking.rankedPlayer.rank }}
        </div>
      </div>
      <div class="col-md-4 text-center">
        <strong>{{ $t("userProfile.points") }}</strong>
        <div class="display-4">
          {{ allTimePointRanking.rankedPlayer.points }}
        </div>
      </div>
      <div class="col-md-4 text-center">
        <strong>{{ $t("userProfile.rounds") }}</strong>
        <div class="display-4">
          {{ allTimePointRanking.rankedPlayer.rounds }}
        </div>
      </div>
    </div>
    <div v-else>{{ $t("userProfile.noResultsYet") }}</div>
    <div class="row">
      <div class="col-12">
        <h3>{{ $t("userProfile.trophiesWon") }}</h3>
      </div>
    </div>
    <div class="row" v-if="winnerRankings.length > 0">
      <div
        class="col-xs-6 col-sm-4 col-md-3 col-lg-2 text-center"
        v-for="(won, index) in winnerRankings"
        :key="index"
      >
        <i
          class="ml-1 fa fa-3x fa-trophy"
          style="color: orange"
          v-if="won.rankedPlayer.rank === 1"
        ></i>
        <i
          class="ml-1 fa fa-3x fa-trophy"
          style="color: silver"
          v-if="won.rankedPlayer.rank === 2"
        ></i>
        <i
          class="ml-1 fa fa-3x fa-trophy"
          style="color: brown"
          v-if="won.rankedPlayer.rank === 3"
        ></i>
        <div>
          <router-link
            :to="{
              path: '/leaderboard/players',
              query: { weekly: true, year: won.year, week: won.week },
            }"
          >
            {{ $t("userProfile.week") }} {{ won.week }}, {{ won.year }}
          </router-link>
        </div>
      </div>
    </div>
    <div v-else>{{ $t("userProfile.noTrophiesWon") }}</div>
  </div>
</template>

<script>

export default {
  components: {
  },

  props: ["profile"],

  data() {
    return {
      iconProps: {
        blankColor: "#777",
        width: 96,
        height: 96,
        class: "m1 bordered-img",
      },
      allTimePointRanking: null,
      allTimeSpeedRanking: null,
      winnerRankings: [],
    };
  },

  async mounted() {
    this.profile.rankings.forEach((ranking) => {
      if (ranking.week === null) {
        if (ranking.type === 0) this.allTimePointRanking = ranking;
        if (ranking.type === 1) this.allTimeSpeedRanking = ranking;
      } else {
        if (
          ranking.rankedPlayer.rank > 0 &&
          ranking.rankedPlayer.rank < 4 &&
          ranking.type === 0
        ) {
          this.winnerRankings.push(ranking);
        }
      }
    });
    this.winnerRankings.sort((a, b) => a.year - b.year || a.week - b.week);
  },
};
</script>

<style scoped>

.card-img-left {
  width: 128px;
  height: 128px;
}
</style>