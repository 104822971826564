<template>
  <div>
    <b-card no-body>
      <!-- <b-card-header header-tag="nav">
        <b-nav card-header pills>
          <b-nav-item to="/database/characters/browse" active-class="active">
            <i class="fa fa-list mr-2"></i>
            Browse
          </b-nav-item>

          <b-nav-form class="ml-auto" @submit="onSearchSubmit">
            <b-form-input
              size="sm"
              class="mr-sm-2"
              placeholder="Search"
              v-model="form.query"
            ></b-form-input>
            <b-button size="sm" class="my-2 my-sm-0" type="submit"
              ><i class="fa fa-search"></i
            ></b-button>
          </b-nav-form>
        </b-nav>
      </b-card-header> -->
      <b-card-body>
        <router-view></router-view>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      form: {
        query: null,
      },
    };
  },

  methods: {
    onSearchSubmit(event) {
      event.preventDefault();
      this.$router.push({
        name: "database-characters-browse",
        query: { title: this.form.query, page: 0 },
      });
    },
  },
};
</script>
