<template>
  <span
    ><b-badge
      v-if="captureType == 'IMAGE'"
      class="mr-1 pl-2 pr-2"
      v-b-tooltip
      title="Images"
      variant="success"
      ><i class="fa fa-fw fa-picture-o"></i
    ></b-badge>
    <b-badge
      v-if="captureType == 'VIDEO'"
      class="mr-1 pl-2 pr-2"
      v-b-tooltip
      title="Videos"
      variant="success"
      ><i class="fa fa-fw fa-video-camera"></i
    ></b-badge>
    <b-badge
      v-if="captureType == 'AUDIO'"
      class="mr-1 pl-2 pr-2"
      v-b-tooltip
      title="Audio"
      variant="success"
      ><i class="fa fa-fw fa-music"></i
    ></b-badge>
    <b-badge
      v-if="captureType == 'QUOTE'"
      class="mr-1 pl-2 pr-2"
      v-b-tooltip
      :title="`${count} quotes`"
      variant="success"
      ><i class="fa fa-fw fa-commenting"></i
      ><span class="count">{{ count }}</span></b-badge
    >
  </span>
</template>

<script>
export default {
  props: ["captureType", "count"],
  mounted() {},
};
</script>
<style scoped>
.count {
  margin-left: 0.25em;
}
</style>
