var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('h1',{staticClass:"display-5"},[_vm._v(_vm._s(_vm.$t("slashCommands.title")))]),_c('Markdown',{attrs:{"markdown":_vm.$t('slashCommands.description', { botName: _vm.$root.config.botName })}})],1)]),_c('hr'),_c('commands-card',{attrs:{"prefix":_vm.prefix,"category":"rounds","commands":_vm.commands.rounds}}),_c('hr'),_c('commands-card',{attrs:{"prefix":_vm.prefix,"category":"voiceRounds","commands":_vm.commands.voiceRounds}}),_c('hr'),_c('commands-card',{attrs:{"prefix":_vm.prefix,"category":"points","commands":_vm.commands.points}}),_c('hr'),_c('commands-card',{attrs:{"prefix":_vm.prefix,"category":"ctop","commands":_vm.commands.ctop}}),_c('hr'),_c('commands-card',{attrs:{"prefix":_vm.prefix,"category":"misc","commands":_vm.commands.misc}}),_c('hr'),_c('commands-card',{attrs:{"prefix":_vm.prefix,"category":"management","commands":_vm.commands.management}}),_c('hr'),_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-lg-12"},[_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 fa fa-fw fa-star",staticStyle:{"color":"orange"},attrs:{"title":_vm.$t('commands.onlyForPatrons.tooltip')}}),_c('Markdown',{attrs:{"markdown":_vm.$t('commands.onlyForPatrons.description', {
            patreonUrl: _vm.patreonUrl,
          })}}),_c('br'),_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 text-danger fa fa-fw fa-flask",attrs:{"title":_vm.$t('commands.isExperimental.tooltip')}}),_c('Markdown',{attrs:{"markdown":_vm.$t('commands.isExperimental.description', {
            supportServerUrl: _vm.supportServerUrl,
          })}}),_c('br'),_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 fa fa-fw fa-eye",staticStyle:{"color":"purple"},attrs:{"title":_vm.$t('slashCommands.ephemeral.tooltip')}}),_c('Markdown',{attrs:{"markdown":_vm.$t('slashCommands.ephemeral.description', {
            supportServerUrl: _vm.supportServerUrl,
          })}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }