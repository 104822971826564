<template>
  <b-modal ref="modal" size="lg" :title="reportAnimeTitle">
    <b-form>
      <p>Examples of things that can be reported using this form:
      </p>
      <ul>
        <li>
          Captures that are broken multiple times for the same anime.<br />(for example: scrambled images, audio fragments or video clips that don't play at all)
        </li>
        <li>
          Incorrect NSFW ratings
        </li>
        <li>
          Incorrect difficulty setting.
        </li>
      </ul>
      <p>
        Things you should <strong>not</strong> report and instead fix on the 3rd party websites from which they originate:
      </p>
      <ul>
        <li>
          Anime titles that are wrong or missing. These can be added in
          <a href="https://anidb.net" target="_blank">AniDB</a> (they are
          synchronized on a daily basis).
        </li>
        <li>
          Character names and synonyms for <code>!sgc</code>. These can be added in
          <a href="https://myanimelist.net" target="_blank">MyAnimeList</a>
          (they may take a while to be added and synchronized).
        </li>
        <li>
          Problems in anime songs for <code>!sga</code>. These can be reported in the
          corresponding song within the
          <a href="https://animesongs.org/" target="_blank">AnimeSongs</a>
          website.
        </li>
        <li>
          Missing anime themes in <code>!sgs</code> and <code>!sgsa</code>. These need
          to be added in
          <a href="https://animethemes.moe" target="_blank">AnimeThemes</a>
          (synchronized monthly, since it is a manual process at the moment).
        </li>
      </ul>
      <p>Please be available on the <a href="https://discord.com/invite/xJrbcsJ" target="_blank">RinBot support server</a> when reporting something.</p>
      <b-form-group label="Description">
        <b-form-textarea
          rows="6"
          :disabled="busy"
          placeholder="Please describe the problem"
          v-model="$v.form.message.$model"
        ></b-form-textarea>
        <b-form-invalid-feedback :state="$v.form.message.required">
          {{ $t("validation.required") }}
        </b-form-invalid-feedback>
        <b-form-invalid-feedback :state="$v.form.message.maxLength">
          {{
            $t("validation.maxLength", {
              length: $v.form.message.$model.length,
              max: $v.form.message.$params.maxLength.max,
            })
          }}
        </b-form-invalid-feedback>
      </b-form-group>
    </b-form>
    <template #modal-footer>
      <b-button size="md" variant="success" :disabled="busy" @click="submit()"
        ><i class="mr-2 fa fa-check"></i>Submit</b-button
      >
    </template>
  </b-modal>
</template>

<script>
import gql from "graphql-tag";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  props: ["anime"],
  data() {
    return {
      busy: false,
      form: {
        message: "",
        animeId: null,
      },
    };
  },
  computed: {
    reportAnimeTitle() {
      return `Report - ${this.anime.mainTitle}`;
    },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      message: {
        required,
        maxLength: maxLength(1000),
      },
    },
  },
  methods: {
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return;
      }
      this.busy = true;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation($databaseReport: CreateDatabaseReport!) {
              createDatabaseReport(databaseReport: $databaseReport) {
                isSuccess
              }
            }
          `,
          errorPolicy: "none",
          variables: {
            databaseReport: this.form,
          },
        })
        .then((response) => {
          if (response.data.createDatabaseReport.isSuccess) {
            this.$refs.modal.hide();
            this.$bvToast.toast(this.$t("reportAnime.submitted"), {
              title: this.$t("common.success"),
              autoHideDelay: 3000,
              variant: "success",
            });
          } else {
            this.$bvToast.toast(this.$t("reportAnime.couldNotSubmit"), {
              title: this.$t("common.error"),
              autoHideDelay: 3000,
              variant: "danger",
            });
          }
          this.busy = false;
        })
        .catch(() => {
          this.$bvToast.toast(this.$t("reportAnime.couldNotSubmit"), {
            title: this.$t("common.error"),
            autoHideDelay: 3000,
            variant: "danger",
          });
          this.busy = false;
        });
    },
    show() {
      this.$refs.modal.show();
    },
  },
  mounted() {
    this.form.animeId = this.anime.id;
  },
};
</script>
