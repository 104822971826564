<template>
  <b-table striped small responsive :fields="mainTitleFields" :items="model">
    <template #cell(actionsBefore)="data">
      <b-button @click="plusMainTitle(data)" size="sm" variant="primary"
        ><i class="fa fa-plus"></i
      ></b-button>
    </template>
    <template #cell(source)="data">
      <b-form-select
        v-model="data.item.source"
        :options="titleSources"
        size="sm"
      ></b-form-select>
    </template>
    <template #cell(type)="data">
      <b-form-select
        v-model="data.item.type"
        :options="getTitleTypes(data.item.source)"
        size="sm"
      ></b-form-select>
    </template>
    <template #cell(language)="data">
      <b-form-select
        v-model="data.item.language"
        :options="data.item.source === 1 ? titleLanguagesWithAny : anyOnly"
        size="sm"
      ></b-form-select>
    </template>
    <template #cell(actionsAfter)="data">
      <b-button-group class="mr-3">
        <b-button variant="info" size="sm" @click="moveMainTitleDown(data)"
          ><i class="fa fa-caret-down"></i
        ></b-button>
        <b-button variant="info" size="sm" @click="moveMainTitleUp(data)"
          ><i class="fa fa-caret-up"></i
        ></b-button>
      </b-button-group>
      <b-button @click="removeMainTitle(data)" variant="danger" size="sm"
        ><i class="fa fa-trash"></i
      ></b-button>
    </template>
  </b-table>
</template>

<script>
import "array.prototype.move";

export default {
  props: ["value", "titleTypes", "titleLanguages"],

  data() {
    return {
      model: this.value,
      titleSources: [
        {
          value: null,
          text: this.$t(
            "serverConfiguration.guessTheAnime.titlePreferences.titleSources.any"
          ),
        },
        { value: 1, text: "AniDB" },
        { value: 2, text: "MyAnimeList" },
      ],
      mainTitleFields: [
        { key: "actionsBefore", label: "", thStyle: "width: 30px" },
        {
          key: "source",
          label: this.$t(
            "serverConfiguration.guessTheAnime.titlePreferences.mainTitle.source"
          ),
          thStyle: "min-width: 120px",
        },
        {
          key: "type",
          label: this.$t(
            "serverConfiguration.guessTheAnime.titlePreferences.mainTitle.type"
          ),
          thStyle: "min-width: 160px",
        },
        {
          key: "language",
          label: this.$t(
            "serverConfiguration.guessTheAnime.titlePreferences.mainTitle.language"
          ),
          thStyle: "min-width: 200px",
        },
        { key: "actionsAfter", label: "", thStyle: "width: 120px" },
      ],
    };
  },

  computed: {
    anyOnly() {
      return [
        {
          value: null,
          text: this.$t(
            "serverConfiguration.guessTheAnime.titlePreferences.titleTypes.any"
          ),
        },
      ];
    },

    titleLanguagesWithAny() {
      let titleLanguages = this.titleLanguages;
      return this.anyOnly.concat(titleLanguages);
    },

    titleTypesWithAny() {
      return this.anyOnly.concat(this.titleTypes);
    },

    mainTitleTypeOnly() {
      return [
        {
          value: "main",
          text: this.$t(
            "serverConfiguration.guessTheAnime.titlePreferences.titleTypes.main"
          ),
        },
      ];
    },
  },

  methods: {
    removeMainTitle(data) {
      this.model.splice(data.index, 1);
      this.$emit("input", this.model);
    },

    plusMainTitle(data) {
      this.model.splice(data.index, 0, {
        source: null,
        type: null,
        language: null,
      });
      this.$emit("input", this.model);
    },

    moveMainTitleUp(data) {
      if (data.index === 0) return;
      this.model.move(data.index, data.index - 1);
      this.$emit("input", this.model);
    },

    moveMainTitleDown(data) {
      if (data.index === this.model.length) return;
      this.model.move(data.index, data.index + 1);
      this.$emit("input", this.model);
    },

    getTitleTypes(source) {
      if (source == 1) return this.titleTypesWithAny;
      else if (source == 2) return this.mainTitleTypeOnly;
      return this.anyOnly;
    },
  },

  watch: {
    value: {
      deep: true,
      handler(newValue) {
        this.model = newValue;
      },
    },
  },
};
</script>
