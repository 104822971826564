var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.profile)?_c('div',[_c('b-card',{staticClass:"mb-2",attrs:{"img-src":_vm.profile.avatarUrl,"img-alt":_vm.profile.username,"img-left":""}},[_c('div',{staticClass:"d-flex justify-content-left"},[_c('div',{staticClass:"d-inline"},[_c('h1',{staticClass:"d-inline"},[(_vm.profile.username)?_c('span',[_vm._v(" "+_vm._s(_vm.profile.username)+" ")]):_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t("leaderboardTable.hidden")))]),_c('a',{attrs:{"href":"https://www.patreon.com/rinbot?fan_landing=true","target":"_blank"}},[(_vm.profile.isPatron)?_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"fa fa-star",staticStyle:{"color":"orange"},attrs:{"title":_vm.$root.config.botName + ' supporter'}}):_vm._e()])]),_c('h5',[(_vm.profile.isPatron)?_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-2",staticStyle:{"background-color":"orange"},attrs:{"title":_vm.$t('common.botSupporter', _vm.$root.config)}},[_c('i',{staticClass:"fa fa-star mr-2"}),_vm._v(_vm._s(_vm.$t("common.botSupporter", _vm.$root.config)))]):_vm._e(),_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-2",attrs:{"variant":"success","title":_vm.$t('userProfile.roundPoints')}},[_c('i',{staticClass:"fa fa-star mr-2"}),_vm._v(_vm._s(_vm.profile.points))]),_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-2",attrs:{"variant":"info","title":_vm.$t('userProfile.achievementPoints')}},[_c('i',{staticClass:"fa fa-trophy mr-2"}),_vm._v(_vm._s(_vm.profile.achievementPoints))])],1)])])]),_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{attrs:{"header-tag":"nav"}},[_c('b-nav',{attrs:{"card-header":"","pills":""}},[_c('b-nav-item',{attrs:{"to":{
            name: 'profile-statistics',
            params: { id: _vm.id },
          },"active-class":"active"}},[_c('i',{staticClass:"fa fa-pie-chart mr-2"}),_vm._v(_vm._s(_vm.$t("userProfile.statistics")))]),_c('b-nav-item',{attrs:{"to":{
            name: 'profile-achievements',
            params: { id: _vm.id },
          },"active-class":"active"}},[_c('i',{staticClass:"fa fa-trophy mr-2"}),_vm._v(_vm._s(_vm.$t("userProfile.achievements")))])],1)],1),_c('b-card-body',[_c('router-view',{attrs:{"profile":_vm.profile}})],1)],1)],1):_c('Loading')
}
var staticRenderFns = []

export { render, staticRenderFns }